import * as types from "./types";
import { ResponseSuccess, ResponseError, Api } from "libraries";

export function fetchLanguages() {
  return dispatch => {
    try {
      return Api()
        .get("languages")
        .then(result => {
          const success = ResponseSuccess(result);
          const { data: { result: data = [] } = {} } = success;
          dispatch({
            type: types.STORE_LANGUAGES,
            data: {
              languages: data.map(lang => lang.id),
              language: data.find(lang => lang.is_default)?.id
            }
          });
          return success;
        })
        .catch(result => ResponseError(result));
    } catch (errors) {
      return new Promise(resolve => ({
        status: false,
        statusCode: 500,
        data: { message: errors.message }
      }));
    }
  };
}

export function fetchLevels() {
  return dispatch => {
    try {
      return Api()
        .get("levels")
        .then(result => {
          const success = ResponseSuccess(result);
          const { data: { result: data = [] } = {} } = success;
          return success;
        })
        .catch(result => ResponseError(result));
    } catch (errors) {
      return new Promise(resolve => ({
        status: false,
        statusCode: 500,
        data: { message: errors.message }
      }));
    }
  };
}

export function fetchLevelById(levelId) {
  return (dispatch, getState) => {
    try {
      const { Application } = getState();
      return Api()
        .post(`level/${levelId}`, {
          language_id: Application.language
        })
        .then(result => {
          const success = ResponseSuccess(result);
          const { data: { result: data = [] } = {} } = success;
          return success;
        })
        .catch(result => ResponseError(result));
    } catch (errors) {
      return new Promise(resolve => ({
        status: false,
        statusCode: 500,
        data: { message: errors.message }
      }));
    }
  };
}

export function setLoading(isLoading) {
  return dispatch =>
    dispatch({
      type: types.LOADING,
      data: isLoading
    });
}

export function setError(isError) {
  return dispatch =>
    dispatch({
      type: types.ERRORS,
      data: isError
    });
}
