import React from "react";
import board from 'assets/images/hijaiyah/hijaiyah-iqra-opt-paper-slide@3x.png'
import boardInfo from 'assets/images/hijaiyah/Rectangle 1415.png'
import helpOk2 from 'assets/images/hijaiyah/hijaiyah-play-opt-help-ok@3x@3x.png'
import ModalBelajar from '../belajar/modal'
import ModalBermain from '../bermain/modal'

class modal extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            popComponent:[
                {
                    id:1,
                    name:'Belajar',
                    component: ModalBelajar
                },
                {
                    id:2,
                    name:'Bermain',
                    component: ModalBermain
                }
            ],
            linkUrl: window.location.pathname.split('/')[1]
        }
    }
    render(){
        const {popComponent , linkUrl} = this.state
        const {images,state,func} = this.props
        return(
            <div className="popup-hijaiyah">
                {popComponent.map((data,indx)=>{
                    const { component : Component } = data
                    return(
                         linkUrl === data.name &&
                            <Component
                            key={indx}
                            imgBoard={{board:board,boardInfo:boardInfo,helpOk2:helpOk2}}
                            images={images}
                            func={func}
                            state={state}
                            />
                    )
                })}
              </div>
            )}
}

export default modal;
// export default withReducer('hijaiyah', reducer)(pages);
