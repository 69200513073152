export default{
    "1": [
      {
        "aya": "1",
        "en_sahih": "In the name of Allah, the Entirely Merciful, the Especially Merciful.\n",
        "id": "1",
        "id_indonesian": "Dengan menyebut nama Allah Yang Maha Pemurah lagi Maha Penyayang.\n",
        "br_brunei": "Dengan nama Allah yang Maha Pemurah lagi Maha Pengasih \n",
        "sura": "1"
      },
      {
        "aya": "2",
        "en_sahih": "[All] praise is [due] to Allah, Lord of the worlds -\n",
        "id": "2",
        "id_indonesian": "Segala puji bagi Allah, Tuhan semesta alam.\n",
        "br_brunei": "Segala pujian untuk Allah, Pencipta dan Pentadbir sekalian alam. \n",
        "sura": "1"
      },
      {
        "aya": "3",
        "en_sahih": "The Entirely Merciful, the Especially Merciful,\n",
        "id": "3",
        "id_indonesian": "Maha Pemurah lagi Maha Penyayang.\n",
        "br_brunei": "Yang Maha Pemurah lagi Maha Pengasih. \n",
        "sura": "1"
      },
      {
        "aya": "4",
        "en_sahih": "Sovereign of the Day of Recompense.\n",
        "id": "4",
        "id_indonesian": "Yang menguasai di Hari Pembalasan.\n",
        "br_brunei": "Yang menguasai hari Pembalasan (hari akhirat)\n",
        "sura": "1"
      },
      {
        "aya": "5",
        "en_sahih": "It is You we worship and You we ask for help.\n",
        "id": "5",
        "id_indonesian": "Hanya Engkaulah yang kami sembah, dan hanya kepada Engkaulah kami meminta pertolongan.\n",
        "br_brunei": "Hanya Engkaulah sahaja (ya Allah) yang kami sembah dan hanya kepada Engkaulah kami memohon pertolongan, \n",
        "sura": "1"
      },
      {
        "aya": "6",
        "en_sahih": "Guide us to the straight path -\n",
        "id": "6",
        "id_indonesian": "Tunjukilah kami jalan yang lurus,\n",
        "br_brunei": "Tunjukkanlah kami jalan yang lurus. \n",
        "sura": "1"
      },
      {
        "aya": "7",
        "en_sahih": "The path of those upon whom You have bestowed favor, not of those who have evoked [Your] anger or of those who are astray.\n",
        "id": "7",
        "id_indonesian": "(yaitu) Jalan orang-orang yang telah Engkau beri nikmat kepada mereka; bukan (jalan) mereka yang dimurkai dan bukan (pula jalan) mereka yang sesat.\n",
        "br_brunei": "Iaitu jalan orang-orang yang Engkau telah kurniakan ni’mat kepada mereka, bukan (jalan) orang-orang yang di murkai dan bukan pula (jalan) orang-orang sesat. \n",
        "sura": "1"
      }
    ],
    "94": [
      {
        "aya": "1",
        "en_sahih": "Did We not expand for you, [O Muhammad], your breast?\n",
        "id": "6091",
        "id_indonesian": "Bukankah Kami telah melapangkan untukmu dadamu?,\n",
        "br_brunei": "Bukankah Kami telah melapangkan dadamu (wahai Nabi Muhammad)\n",
        "sura": "94"
      },
      {
        "aya": "2",
        "en_sahih": "And We removed from you your burden\n",
        "id": "6092",
        "id_indonesian": "dan Kami telah menghilangkan daripadamu bebanmu,\n",
        "br_brunei": "Dan Kami telah menghilangkan bebananmu. \n",
        "sura": "94"
      },
      {
        "aya": "3",
        "en_sahih": "Which had weighed upon your back\n",
        "id": "6093",
        "id_indonesian": "yang memberatkan punggungmu?\n",
        "br_brunei": "Yang memberati dirimu. \n",
        "sura": "94"
      },
      {
        "aya": "4",
        "en_sahih": "And raised high for you your repute.\n",
        "id": "6094",
        "id_indonesian": "Dan Kami tinggikan bagimu sebutan (nama)mu,\n",
        "br_brunei": "Dan Kami telah meninggikan sebutan (nama) mu. \n",
        "sura": "94"
      },
      {
        "aya": "5",
        "en_sahih": "For indeed, with hardship [will be] ease.\n",
        "id": "6095",
        "id_indonesian": "Karena sesungguhnya sesudah kesulitan itu ada kemudahan,\n",
        "br_brunei": "Maka sesungguhnya bersama kesusahan ada kesenangan. \n",
        "sura": "94"
      },
      {
        "aya": "6",
        "en_sahih": "Indeed, with hardship [will be] ease.\n",
        "id": "6096",
        "id_indonesian": "sesungguhnya sesudah kesulitan itu ada kemudahan.\n",
        "br_brunei": "Sesungguhnya bersama kesusahan ada kesenangan. \n",
        "sura": "94"
      },
      {
        "aya": "7",
        "en_sahih": "So when you have finished [your duties], then stand up [for worship].\n",
        "id": "6097",
        "id_indonesian": "Maka apabila kamu telah selesai (dari sesuatu urusan), kerjakanlah dengan sungguh-sungguh (urusan) yang lain,\n",
        "br_brunei": "Apabila englau telah selesai (daripada urusan da’wah dan jihad) maka kerjakanlah bersungguh-sungguh (urusan ibadatmu). \n",
        "sura": "94"
      },
      {
        "aya": "8",
        "en_sahih": "And to your Lord direct [your] longing.\n",
        "id": "6098",
        "id_indonesian": "dan hanya kepada Tuhanmulah hendaknya kamu berharap.\n",
        "br_brunei": "Dan kepada Tuhanmu sahaja hendaknya engkau berharap. \n",
        "sura": "94"
      }
    ],
    "95": [
      {
        "aya": "1",
        "en_sahih": "By the fig and the olive\n",
        "id": "6099",
        "id_indonesian": "Demi (buah) Tin dan (buah) Zaitun,\n",
        "br_brunei": "Demi (buah) Tin dan (buah) Zaitun. \n",
        "sura": "95"
      },
      {
        "aya": "2",
        "en_sahih": "And [by] Mount Sinai\n",
        "id": "6100",
        "id_indonesian": "dan demi bukit Sinai,\n",
        "br_brunei": "Demi gunung Thursina. \n",
        "sura": "95"
      },
      {
        "aya": "3",
        "en_sahih": "And [by] this secure city [Makkah],\n",
        "id": "6101",
        "id_indonesian": "dan demi kota (Mekah) ini yang aman,\n",
        "br_brunei": "Demi negeri yang aman ini (Mekah)\n",
        "sura": "95"
      },
      {
        "aya": "4",
        "en_sahih": "We have certainly created man in the best of stature;\n",
        "id": "6102",
        "id_indonesian": "sesungguhnya Kami telah menciptakan manusia dalam bentuk yang sebaik-baiknya.\n",
        "br_brunei": "Sesungguhnya Kami telah menciptakan manusia dalam bentuk yang sebaik-baiknya. \n",
        "sura": "95"
      },
      {
        "aya": "5",
        "en_sahih": "Then We return him to the lowest of the low,\n",
        "id": "6103",
        "id_indonesian": "Kemudian Kami kembalikan dia ke tempat yang serendah-rendahnya (neraka),\n",
        "br_brunei": "Kemudian kami kembalikan dia ke tempat yang serendah-rendahnya. \n",
        "sura": "95"
      },
      {
        "aya": "6",
        "en_sahih": "Except for those who believe and do righteous deeds, for they will have a reward uninterrupted.\n",
        "id": "6104",
        "id_indonesian": "kecuali orang-orang yang beriman dan mengerjakan amal saleh; maka bagi mereka pahala yang tiada putus-putusnya.\n",
        "br_brunei": "Kecuali orang-orang yang beriman dan mengerjakan amalan-amalann salah, maka mereka mendapat ganjaran pahala yang tidak putus-putus. \n",
        "sura": "95"
      },
      {
        "aya": "7",
        "en_sahih": "So what yet causes you to deny the Recompense?\n",
        "id": "6105",
        "id_indonesian": "Maka apakah yang menyebabkan kamu mendustakan (hari) pembalasan sesudah (adanya keterangan-keterangan) itu?\n",
        "br_brunei": "Maka apakah yang menyebabkan kamu (wahai manusia) mendustakan hari Pembalasan setelah (adanya keterangan-keterangan) itu? \n",
        "sura": "95"
      },
      {
        "aya": "8",
        "en_sahih": "Is not Allah the most just of judges?\n",
        "id": "6106",
        "id_indonesian": "Bukankah Allah Hakim yang seadil-adilnya?\n",
        "br_brunei": "Bukankah Allah itu sebijak-bijak dan seadil-adil hakim. \n",
        "sura": "95"
      }
    ],
    "97": [
      {
        "aya": "1",
        "en_sahih": "Indeed, We sent the Qur'an down during the Night of Decree.\n",
        "id": "6126",
        "id_indonesian": "Sesungguhnya Kami telah menurunkannya (Al Quran) pada malam kemuliaan.\n",
        "br_brunei": "Sesungguhnya Kami telah menurunkan al-Qur’an itu pada malam Lailatulqadar. \n",
        "sura": "97"
      },
      {
        "aya": "2",
        "en_sahih": "And what can make you know what is the Night of Decree?\n",
        "id": "6127",
        "id_indonesian": "Dan tahukah kamu apakah malam kemuliaan itu?\n",
        "br_brunei": "Dan tahukah engkau apakah malam Lailatulqadar itu?\n",
        "sura": "97"
      },
      {
        "aya": "3",
        "en_sahih": "The Night of Decree is better than a thousand months.\n",
        "id": "6128",
        "id_indonesian": "Malam kemuliaan itu lebih baik dari seribu bulan.\n",
        "br_brunei": "Malam Lailatulqadar itu lebih baik daripada seribu bulan.\n",
        "sura": "97"
      },
      {
        "aya": "4",
        "en_sahih": "The angels and the Spirit descend therein by permission of their Lord for every matter.\n",
        "id": "6129",
        "id_indonesian": "Pada malam itu turun malaikat-malaikat dan malaikat Jibril dengan izin Tuhannya untuk mengatur segala urusan.\n",
        "br_brunei": "Pada malam itu turun malaikat-malaikat dan malaikat Jibril dengan izin Tuhan mereka untuk mengatur  segala urusan. \n",
        "sura": "97"
      },
      {
        "aya": "5",
        "en_sahih": "Peace it is until the emergence of dawn.\n",
        "id": "6130",
        "id_indonesian": "Malam itu (penuh) kesejahteraan sampai terbit fajar.\n",
        "br_brunei": "Malam itu (penuh) kesejahteraan hingga terbit fajar. \n",
        "sura": "97"
      }
    ],
    "102": [
      {
        "aya": "1",
        "en_sahih": "Competition in [worldly] increase diverts you\n",
        "id": "6169",
        "id_indonesian": "Bermegah-megahan telah melalaikan kamu,\n",
        "br_brunei": "Perbuatan memperbanyak (dan bermegah-megah dengan segala kesenangan dunia) telah melalaikan. \n",
        "sura": "102"
      },
      {
        "aya": "2",
        "en_sahih": "Until you visit the graveyards.\n",
        "id": "6170",
        "id_indonesian": "sampai kamu masuk ke dalam kubur.\n",
        "br_brunei": "Sehinggalah kamu masuk ke liang kubur. \n",
        "sura": "102"
      },
      {
        "aya": "3",
        "en_sahih": "No! You are going to know.\n",
        "id": "6171",
        "id_indonesian": "Janganlah begitu, kelak kamu akan mengetahui (akibat perbuatanmu itu),\n",
        "br_brunei": "Jangan sekali-kali (berbuat demikian), kamu kelak akan mengetahui (akibatnya). \n",
        "sura": "102"
      },
      {
        "aya": "4",
        "en_sahih": "Then no! You are going to know.\n",
        "id": "6172",
        "id_indonesian": "dan janganlah begitu, kelak kamu akan mengetahui.\n",
        "br_brunei": "Kemudian (diingatkan lagi)  jangan sekali-kali (berbuat demikian), kamu kelak akan mengetahui (akibatnya)\n",
        "sura": "102"
      },
      {
        "aya": "5",
        "en_sahih": "No! If you only knew with knowledge of certainty...\n",
        "id": "6173",
        "id_indonesian": "Janganlah begitu, jika kamu mengetahui dengan pengetahuan yang yakin,\n",
        "br_brunei": "Jangan sekali-kali (berbuat demikian) kerana kalau kamu mengetahui dengan pengetahuan yang yakin (tentulah kamu meninggalkan perbuatan itu). \n",
        "sura": "102"
      },
      {
        "aya": "6",
        "en_sahih": "You will surely see the Hellfire.\n",
        "id": "6174",
        "id_indonesian": "niscaya kamu benar-benar akan melihat neraka Jahiim,\n",
        "br_brunei": "Demi sesungguhnya kamu akan melihat neraka Jahim. \n",
        "sura": "102"
      },
      {
        "aya": "7",
        "en_sahih": "Then you will surely see it with the eye of certainty.\n",
        "id": "6175",
        "id_indonesian": "dan sesungguhnya kamu benar-benar akan melihatnya dengan 'ainul yaqin.\n",
        "br_brunei": "Kemudian demi sesungguhnya kamu akan melihatnya dengan pernglihatan yang yakin. \n",
        "sura": "102"
      },
      {
        "aya": "8",
        "en_sahih": "Then you will surely be asked that Day about pleasure.\n",
        "id": "6176",
        "id_indonesian": "kemudian kamu pasti akan ditanyai pada hari itu tentang kenikmatan (yang kamu megah-megahkan di dunia itu).\n",
        "br_brunei": "Kemudia demi sesungguhnya kamu akan ditanya pada hari itu tentang segala kenikmatan (yang kamu megah-megahkan di dunia). \n",
        "sura": "102"
      }
    ],
    "103": [
      {
        "aya": "1",
        "en_sahih": "By time,\n",
        "id": "6177",
        "id_indonesian": "Demi masa.\n",
        "br_brunei": "Demi masa\n",
        "sura": "103"
      },
      {
        "aya": "2",
        "en_sahih": "Indeed, mankind is in loss,\n",
        "id": "6178",
        "id_indonesian": "Sesungguhnya manusia itu benar-benar dalam kerugian,\n",
        "br_brunei": "Sesungguhnya manusia itu benar-benar dalam kerugian. \n",
        "sura": "103"
      },
      {
        "aya": "3",
        "en_sahih": "Except for those who have believed and done righteous deeds and advised each other to truth and advised each other to patience.\n",
        "id": "6179",
        "id_indonesian": "kecuali orang-orang yang beriman dan mengerjakan amal saleh dan nasehat menasehati supaya mentaati kebenaran dan nasehat menasehati supaya menetapi kesabaran.\n",
        "br_brunei": "Kecuali orang-orang yang beriman dan mengerjakan amalan-amalan salih, dan saling berpesan untuk mengikut yang haq serta saling\n",
        "sura": "103"
      }
    ],
    "105": [
      {
        "aya": "1",
        "en_sahih": "Have you not considered, [O Muhammad], how your Lord dealt with the companions of the elephant?\n",
        "id": "6189",
        "id_indonesian": "Apakah kamu tidak memperhatikan bagaimana Tuhanmu telah bertindak terhadap tentara bergajah?\n",
        "br_brunei": "Tidakkah engkau memerhatikan bagaimana Tuhanmu telah lakukan kepada pasukan bergajah? \n",
        "sura": "105"
      },
      {
        "aya": "2",
        "en_sahih": "Did He not make their plan into misguidance?\n",
        "id": "6190",
        "id_indonesian": "Bukankah Dia telah menjadikan tipu daya mereka (untuk menghancurkan Ka'bah) itu sia-sia?\n",
        "br_brunei": "Bukankah Dia telah menjadikan tipu daya mereka (hendak meruntuhkan Ka’bah) itu sia-sia. \n",
        "sura": "105"
      },
      {
        "aya": "3",
        "en_sahih": "And He sent against them birds in flocks,\n",
        "id": "6191",
        "id_indonesian": "dan Dia mengirimkan kapada mereka burung yang berbondong-bondong,\n",
        "br_brunei": "Dan Dia telah menghantarkan kepada mereka pasukan burung yang berkumpul-kumpulan. \n",
        "sura": "105"
      },
      {
        "aya": "4",
        "en_sahih": "Striking them with stones of hard clay,\n",
        "id": "6192",
        "id_indonesian": "yang melempari mereka dengan batu (berasal) dari tanah yang terbakar,\n",
        "br_brunei": "Yang melontar mereka dengan batu-batu daripada tanah yang dibakar. \n",
        "sura": "105"
      },
      {
        "aya": "5",
        "en_sahih": "And He made them like eaten straw.\n",
        "id": "6193",
        "id_indonesian": "lalu Dia menjadikan mereka seperti daun-daun yang dimakan (ulat).\n",
        "br_brunei": "lalu Dia menjadikan mereka seperti daun-daun yang dimakan (ulat).\n",
        "sura": "105"
      }
    ],
    "106": [
      {
        "aya": "1",
        "en_sahih": "For the accustomed security of the Quraysh -\n",
        "id": "6194",
        "id_indonesian": "Karena kebiasaan orang-orang Quraisy,\n",
        "br_brunei": "Oleh sebab kebiasaan kaum Quraisy.\n",
        "sura": "106"
      },
      {
        "aya": "2",
        "en_sahih": "Their accustomed security [in] the caravan of winter and summer -\n",
        "id": "6195",
        "id_indonesian": "(yaitu) kebiasaan mereka bepergian pada musim dingin dan musim panas.\n",
        "br_brunei": "(Iaitu) kebiasaan perjalanan mereka (menjalankan perniagaan dengan aman) pada musim sejuk (ke Yaman) dan pada musim panas (ke Syam)\n",
        "sura": "106"
      },
      {
        "aya": "3",
        "en_sahih": "Let them worship the Lord of this House,\n",
        "id": "6196",
        "id_indonesian": "Maka hendaklah mereka menyembah Tuhan Pemilik rumah ini (Ka'bah).\n",
        "br_brunei": "Maka hendaklah mereka menyembah Tuhan Pemilik rumah ini (Ka’bah)\n",
        "sura": "106"
      },
      {
        "aya": "4",
        "en_sahih": "Who has fed them, [saving them] from hunger and made them safe, [saving them] from fear.\n",
        "id": "6197",
        "id_indonesian": "Yang telah memberi makanan kepada mereka untuk menghilangkan lapar dan mengamankan mereka dari ketakutan.\n",
        "br_brunei": "Tuhan yang memberi mereka makan untuk menghilangkan lapar dan mengamankan mereka daripada ketakutan. \n",
        "sura": "106"
      }
    ],
    "107": [
      {
        "aya": "1",
        "en_sahih": "Have you seen the one who denies the Recompense?\n",
        "id": "6198",
        "id_indonesian": "Tahukah kamu (orang) yang mendustakan agama?\n",
        "br_brunei": "Tahukah engkau orang yang mendustakan agama? \n",
        "sura": "107"
      },
      {
        "aya": "2",
        "en_sahih": "For that is the one who drives away the orphan\n",
        "id": "6199",
        "id_indonesian": "Itulah orang yang menghardik anak yatim,\n",
        "br_brunei": "Maka ia itulah orang yang berlaku zalim kepada anak yatim. \n",
        "sura": "107"
      },
      {
        "aya": "3",
        "en_sahih": "And does not encourage the feeding of the poor.\n",
        "id": "6200",
        "id_indonesian": "dan tidak menganjurkan memberi makan orang miskin.\n",
        "br_brunei": "Dan dia tidak menggalakkan memberi makan orang miskin \n",
        "sura": "107"
      },
      {
        "aya": "4",
        "en_sahih": "So woe to those who pray\n",
        "id": "6201",
        "id_indonesian": "Maka kecelakaanlah bagi orang-orang yang shalat,\n",
        "br_brunei": "Maka celakalah orang-orang bersembahyang. \n",
        "sura": "107"
      },
      {
        "aya": "5",
        "en_sahih": "[But] who are heedless of their prayer -\n",
        "id": "6202",
        "id_indonesian": "(yaitu) orang-orang yang lalai dari shalatnya,\n",
        "br_brunei": "(iaitu) mereka yang lalai terhadap sembahyang mereka. \n",
        "sura": "107"
      },
      {
        "aya": "6",
        "en_sahih": "Those who make show [of their deeds]\n",
        "id": "6203",
        "id_indonesian": "orang-orang yang berbuat riya,\n",
        "br_brunei": "(Juga) mereka yang berkeadaan riya’\n",
        "sura": "107"
      },
      {
        "aya": "7",
        "en_sahih": "And withhold [simple] assistance.\n",
        "id": "6204",
        "id_indonesian": "dan enggan (menolong dengan) barang berguna.\n",
        "br_brunei": "Dan mereka yang enggan memberi pertolongan.\n",
        "sura": "107"
      }
    ],
    "108": [
      {
        "aya": "1",
        "en_sahih": "Indeed, We have granted you, [O Muhammad], al-Kawthar.\n",
        "id": "6205",
        "id_indonesian": "Sesungguhnya Kami telah memberikan kepadamu nikmat yang banyak.\n",
        "br_brunei": "Sesungguhnya kami telah mengurniakan kepadamu (wahai Nabi Muhammad) ni’mat yang banyak (antaranya sungai al-Kautsar di syurga)\n",
        "sura": "108"
      },
      {
        "aya": "2",
        "en_sahih": "So pray to your Lord and sacrifice [to Him alone].\n",
        "id": "6206",
        "id_indonesian": "Maka dirikanlah shalat karena Tuhanmu; dan berkorbanlah.\n",
        "br_brunei": "Maka dirikanlah sembahyang kerana Tyhanmu dan sembelihlah binatang korban (kerana mensyukuri ni’mat-ni’matNya)\n",
        "sura": "108"
      },
      {
        "aya": "3",
        "en_sahih": "Indeed, your enemy is the one cut off.\n",
        "id": "6207",
        "id_indonesian": "Sesungguhnya orang-orang yang membenci kamu dialah yang terputus.\n",
        "br_brunei": "Sesungguhnya orang yang membenci engkau, dialah terputus (daripada mendapat kebaikan dunia dan akhirat)\n",
        "sura": "108"
      }
    ],
    "109": [
      {
        "aya": "1",
        "en_sahih": "Say, O disbelievers,\n",
        "id": "6208",
        "id_indonesian": "Katakanlah: Hai orang-orang kafir,\n",
        "br_brunei": "Katakanlah (wahai Nabi Muhammad): “Wahai orang-orang kafir!”\n",
        "sura": "109"
      },
      {
        "aya": "2",
        "en_sahih": "I do not worship what you worship.\n",
        "id": "6209",
        "id_indonesian": "Aku tidak akan menyembah apa yang kamu sembah.\n",
        "br_brunei": "Aku bukan penyembah apa yang kamu sembah.\n",
        "sura": "109"
      },
      {
        "aya": "3",
        "en_sahih": "Nor are you worshippers of what I worship.\n",
        "id": "6210",
        "id_indonesian": "Dan kamu bukan penyembah Tuhan yang aku sembah.\n",
        "br_brunei": "Dan kamu bukan penyembah apa (Allah) yang aku sembah.”\n",
        "sura": "109"
      },
      {
        "aya": "4",
        "en_sahih": "Nor will I be a worshipper of what you worship.\n",
        "id": "6211",
        "id_indonesian": "Dan aku tidak pernah menjadi penyembah apa yang kamu sembah,\n",
        "br_brunei": "Dan aku tidak pernah menjadi penyembah apa (Allah) yang aku sembah.”\n",
        "sura": "109"
      },
      {
        "aya": "5",
        "en_sahih": "Nor will you be worshippers of what I worship.\n",
        "id": "6212",
        "id_indonesian": "dan kamu tidak pernah (pula) menjadi penyembah Tuhan yang aku sembah.\n",
        "br_brunei": "“Dan kamu pula tidak pernah menjadi penyembah apa (allah) yang aku sembah.” \n",
        "sura": "109"
      },
      {
        "aya": "6",
        "en_sahih": "For you is your religion, and for me is my religion.\n",
        "id": "6213",
        "id_indonesian": "Untukmu agamamu, dan untukkulah, agamaku.\n",
        "br_brunei": "Bagi kamu agama kamu dan bagiku agamaku.”\n",
        "sura": "109"
      }
    ],
    "110": [
      {
        "aya": "1",
        "en_sahih": "When the victory of Allah has come and the conquest,\n",
        "id": "6214",
        "id_indonesian": "Apabila telah datang pertolongan Allah dan kemenangan,\n",
        "br_brunei": "Apabila datang pertolongan Allah dan kemenangan (menakluki Mekah)\n",
        "sura": "110"
      },
      {
        "aya": "2",
        "en_sahih": "And you see the people entering into the religion of Allah in multitudes,\n",
        "id": "6215",
        "id_indonesian": "dan kamu lihat manusia masuk agama Allah dengan berbondong-bondong,\n",
        "br_brunei": "Dan engkau (wahai Nabi Muhammad) melihat manusia masuk dalam agama Allah beramai-ramai. \n",
        "sura": "110"
      },
      {
        "aya": "3",
        "en_sahih": "Then exalt [Him] with praise of your Lord and ask forgiveness of Him. Indeed, He is ever Accepting of repentance.\n",
        "id": "6216",
        "id_indonesian": "maka bertasbihlah dengan memuji Tuhanmu dan mohonlah ampun kepada-Nya. Sesungguhnya Dia adalah Maha Penerima taubat.\n",
        "br_brunei": "Maka bertasbihlah dengan memuji Tuhanmu dan mohonlah ampun kepadaNya. Sesungguhnya Dia adalah Maha Penerima Taubat. \n",
        "sura": "110"
      }
    ],
    "111": [
      {
        "aya": "1",
        "en_sahih": "May the hands of Abu Lahab be ruined, and ruined is he.\n",
        "id": "6217",
        "id_indonesian": "Binasalah kedua tangan Abu Lahab dan sesungguhnya dia akan binasa.\n",
        "br_brunei": "Binasalah kedua-dua tangan Abu Lahab dan binasalah dia. \n",
        "sura": "111"
      },
      {
        "aya": "2",
        "en_sahih": "His wealth will not avail him or that which he gained.\n",
        "id": "6218",
        "id_indonesian": "Tidaklah berfaedah kepadanya harta bendanya dan apa yang ia usahakan.\n",
        "br_brunei": "Hartanya dana pa yang diusahakannya tidak dapat menolongnya. \n",
        "sura": "111"
      },
      {
        "aya": "3",
        "en_sahih": "He will [enter to] burn in a Fire of [blazing] flame\n",
        "id": "6219",
        "id_indonesian": "Kelak dia akan masuk ke dalam api yang bergejolak.\n",
        "br_brunei": "Dia akan memasuki neraka yang apinya menyala-nyala.\n",
        "sura": "111"
      },
      {
        "aya": "4",
        "en_sahih": "And his wife [as well] - the carrier of firewood.\n",
        "id": "6220",
        "id_indonesian": "Dan (begitu pula) istrinya, pembawa kayu bakar.\n",
        "br_brunei": "Dan isterinya (pula) pembawa kayu api.\n",
        "sura": "111"
      },
      {
        "aya": "5",
        "en_sahih": "Around her neck is a rope of [twisted] fiber.\n",
        "id": "6221",
        "id_indonesian": "Yang di lehernya ada tali dari sabut.\n",
        "br_brunei": "Di lehernya ada tali yang dipintal. \n",
        "sura": "111"
      }
    ],
    "112": [
      {
        "aya": "1",
        "en_sahih": "Say, He is Allah, [who is] One,\n",
        "id": "6222",
        "id_indonesian": "Katakanlah: Dialah Allah, Yang Maha Esa.\n",
        "br_brunei": "Katankanlah (wahai Nabi Muhammad): “Dialah Allah yang Maha Esa.”\n",
        "sura": "112"
      },
      {
        "aya": "2",
        "en_sahih": "Allah, the Eternal Refuge.\n",
        "id": "6223",
        "id_indonesian": "Allah adalah Tuhan yang bergantung kepada-Nya segala sesuatu.\n",
        "br_brunei": "“Allah yang menjadi tumpuan sekalian mahluk.” \n",
        "sura": "112"
      },
      {
        "aya": "3",
        "en_sahih": "He neither begets nor is born,\n",
        "id": "6224",
        "id_indonesian": "Dia tiada beranak dan tidak pula diperanakkan,\n",
        "br_brunei": "“Dia tidak beranak dan tidak pula diperanakkan.”\n",
        "sura": "112"
      },
      {
        "aya": "4",
        "en_sahih": "Nor is there to Him any equivalent.\n",
        "id": "6225",
        "id_indonesian": "dan tidak ada seorangpun yang setara dengan Dia.\n",
        "br_brunei": "Dan tidak ada sesiapa pun setara denganNya.”\n",
        "sura": "112"
      }
    ],
    "113": [
      {
        "aya": "1",
        "en_sahih": "Say, I seek refuge in the Lord of daybreak\n",
        "id": "6226",
        "id_indonesian": "Katakanlah: Aku berlindung kepada Tuhan Yang Menguasai subuh,\n",
        "br_brunei": "Katakanlah (wahai Nabi Muhammad): “Aku berlindung pada Tuhan yang menguasai subuh.”\n",
        "sura": "113"
      },
      {
        "aya": "2",
        "en_sahih": "From the evil of that which He created\n",
        "id": "6227",
        "id_indonesian": "dari kejahatan makhluk-Nya,\n",
        "br_brunei": "“Daripada kejahatan makhluk-makhluk yang Dia ciptakan.”\n",
        "sura": "113"
      },
      {
        "aya": "3",
        "en_sahih": "And from the evil of darkness when it settles\n",
        "id": "6228",
        "id_indonesian": "dan dari kejahatan malam apabila telah gelap gulita,\n",
        "br_brunei": "Dan daripada kejahatan malam apabila telah gelap gelita.”\n",
        "sura": "113"
      },
      {
        "aya": "4",
        "en_sahih": "And from the evil of the blowers in knots\n",
        "id": "6229",
        "id_indonesian": "dan dari kejahatan wanita-wanita tukang sihir yang menghembus pada buhul-buhul,\n",
        "br_brunei": "Dan daripada kejahatan tukang-tukang sihir yang menghembus-hembus pada simpulan-simpulan. \n",
        "sura": "113"
      },
      {
        "aya": "5",
        "en_sahih": "And from the evil of an envier when he envies.\n",
        "id": "6230",
        "id_indonesian": "dan dari kejahatan pendengki bila ia dengki.\n",
        "br_brunei": "“Dan daripada kejahatan orang yang dengki apabila dia dengki.”\n",
        "sura": "113"
      }
    ],
    "114": [
      {
        "aya": "1",
        "en_sahih": "Say, I seek refuge in the Lord of mankind,\n",
        "id": "6231",
        "id_indonesian": "Katakanlah: Aku berlindung kepada Tuhan (yang memelihara dan menguasai) manusia.\n",
        "br_brunei": "Katakanlah (wahai Nabi Muhammad): “Aku berlindung pada Tukan Pencipta dan Pemelihara sekalian manusia.”\n",
        "sura": "114"
      },
      {
        "aya": "2",
        "en_sahih": "The Sovereign of mankind.\n",
        "id": "6232",
        "id_indonesian": "Raja manusia.\n",
        "br_brunei": "“Yang Menguasai sekalian manusia”\n",
        "sura": "114"
      },
      {
        "aya": "3",
        "en_sahih": "The God of mankind,\n",
        "id": "6233",
        "id_indonesian": "Sembahan manusia.\n",
        "br_brunei": "“Tuhan (yang disembah oleh) sekalian manusia. \n",
        "sura": "114"
      },
      {
        "aya": "4",
        "en_sahih": "From the evil of the retreating whisperer -\n",
        "id": "6234",
        "id_indonesian": "Dari kejahatan (bisikan) syaitan yang biasa bersembunyi,\n",
        "br_brunei": "Daripada kejahatan (biskikan) syaitan yang bersembunyi. \n",
        "sura": "114"
      },
      {
        "aya": "5",
        "en_sahih": "Who whispers [evil] into the breasts of mankind -\n",
        "id": "6235",
        "id_indonesian": "yang membisikkan (kejahatan) ke dalam dada manusia,\n",
        "br_brunei": "Yang mebisikkan kejahatan ke dalam hati manusia. \n",
        "sura": "114"
      },
      {
        "aya": "6",
        "en_sahih": "From among the jinn and mankind.",
        "id": "6236",
        "id_indonesian": "dari (golongan) jin dan manusia.",
        "br_brunei": "(Iaitu) daripada jin dan manusia. \n",
        "sura": "114"
      }
    ]
  }