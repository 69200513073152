
// OPT Tanda
import harakat1 from 'assets/images/hijaiyah/hijaiyah-opt-harakat-dammah@3x.png'
import harakat1Active from 'assets/images/hijaiyah/hijaiyah-opt-harakat-dammah-active@3x.png'
import harakat3 from 'assets/images/hijaiyah/hijaiyah-opt-harakat-kasrah@3x.png'
import harakat3Active from 'assets/images/hijaiyah/hijaiyah-opt-harakat-kasrah-active@3x.png'
import harakat2 from 'assets/images/hijaiyah/hijaiyah-opt-harakat-fathah@3x.png'
import harakat2xActive from 'assets/images/hijaiyah/hijaiyah-opt-harakat-fathah-active@3x@3x.png'

// OPT Tanwin
import harakatTanwin1 from 'assets/images/hijaiyah/hijaiyah-opt-tanwin-dammah@3x.png'
import harakatTanwin12xActive from 'assets/images/hijaiyah/hijaiyah-opt-tanwin-dammah-active@3x@3x.png'
import harakatTanwin2 from 'assets/images/hijaiyah/hijaiyah-opt-tanwin-fathah@3x.png'
import harakatTanwin22xActive from 'assets/images/hijaiyah/hijaiyah-opt-tanwin-fathah-active@3x@3x.png'
import harakatTanwin3 from 'assets/images/hijaiyah/hijaiyah-opt-tanwin-kasrah@3x.png'
import harakatTanwin32xActive from 'assets/images/hijaiyah/hijaiyah-opt-tanwin-kasrah-active@3x@3x.png'

//blockButton
import blockTanda from 'assets/images/hijaiyah/bermain/hijaiyah-opt-paper-harokat-dammah@3x@3x.png'
import blockTanda2 from 'assets/images/hijaiyah/bermain/hijaiyah-opt-paper-harokat-kasrah@3x@3x.png'
import blockTanda3 from 'assets/images/hijaiyah/bermain/hijaiyah-opt-paper-harokat-fatah@3x@3x.png'
import blockTanwin from 'assets/images/hijaiyah/bermain/hijaiyah-opt-paper-tanwin-dammah@3x@3x.png'
import blockTanwin2 from 'assets/images/hijaiyah/bermain/hijaiyah-opt-paper-tanwin-kasrah@3x@3x.png'
import blockTanwin3 from 'assets/images/hijaiyah/bermain/hijaiyah-opt-paper-tanwin-fatah@3x@3x.png'


// Header
import Huruf1 from 'assets/images/hijaiyah/hijaiyah-iqra-heading-hijaiyah1@3x.png'
import Huruf1x from 'assets/images/hijaiyah/hijaiyah-iqra-heading-hijaiyah1@3x@3x.png'
import Huruf2 from 'assets/images/hijaiyah/hijaiyah-iqra-heading-hijaiyah2@3x.png'
import Huruf2x from 'assets/images/hijaiyah/hijaiyah-iqra-heading-hijaiyah2@3x@3x.png'
import Tanda1 from 'assets/images/hijaiyah/hijaiyah-iqra-heading-harakat1@3x.png'
import Tanda1x from 'assets/images/hijaiyah/hijaiyah-iqra-heading-harakat1@3x@3x.png'
import Tanda2 from 'assets/images/hijaiyah/hijaiyah-iqra-heading-harakat2@3x.png'
import Tanda2x from 'assets/images/hijaiyah/hijaiyah-iqra-heading-harakat2@3x@3x.png'
import Tanwin1 from 'assets/images/hijaiyah/hijaiyah-iqra-heading-tanwin1@3x.png'
import Tanwin1x from 'assets/images/hijaiyah/hijaiyah-iqra-heading-tanwin1@3x@3x.png'
import Tanwin2 from 'assets/images/hijaiyah/hijaiyah-iqra-heading-tanwin2@3x.png'
import Tanwin2x from 'assets/images/hijaiyah/hijaiyah-iqra-heading-tanwin2@3x@3x.png'
import TekaHuruf from 'assets/images/hijaiyah/bermain/header/hijaiyah-play-topboard-guesshijaiyah1@3x@3x.png'
import TekaHuruf2 from 'assets/images/hijaiyah/bermain/header/hijaiyah-play-topboard-guesshijaiyah2@3x@3x.png'
import TekaTanwin from 'assets/images/hijaiyah/bermain/header/hijaiyah-play-topboard-guesstanwin@3x@3x.png'
import TekaTanwin2 from 'assets/images/hijaiyah/bermain/header/hijaiyah-play-topboard-matchtanwin@3x@3x.png'
import TekaTanda from 'assets/images/hijaiyah/bermain/header/hijaiyah-play-topboard-guessharokat@3x.png'
import TekaTanda2 from 'assets/images/hijaiyah/bermain/header/hijaiyah-play-topboard-matchharokat@3x@3x.png'

// Help
import iqraHuruf from 'assets/images/hijaiyah/hijaiyah-iqra-help-hijaiyah@3x@2x.png'
import iqraTanda from 'assets/images/hijaiyah/hijaiyah-iqra-help-harakat@3x@2x.png'
import iqraTanwin from 'assets/images/hijaiyah/hijaiyah-iqra-help-tanwin@3x@2x.png'

// menu Bermain
import tekaHuruf from 'assets/images/hijaiyah/bermain/iconMenu/hijaiyah-play-opt-guesshijaiyah1@3x.png'
import tekaHuruf2x from 'assets/images/hijaiyah/bermain/iconMenu/hijaiyah-play-opt-guesshijaiyah1@3x@3x.png'
import tekaHuruf2 from 'assets/images/hijaiyah/bermain/iconMenu/hijaiyah-play-opt-guesshijaiyah2@3x.png'
import tekaHuruf22x from 'assets/images/hijaiyah/bermain/iconMenu/hijaiyah-play-opt-guesshijaiyah2@3x@3x.png'
import tekaTanda from 'assets/images/hijaiyah/bermain/iconMenu/hijaiyah-play-opt-guessharokat@3x.png'
import tekaTanda2x from 'assets/images/hijaiyah/bermain/iconMenu/hijaiyah-play-opt-guessharokat@3x@3x.png'
import tekaTanda2 from 'assets/images/hijaiyah/bermain/iconMenu/hijaiyah-play-opt-matchharokat@3x.png'
import tekaTanda22x from 'assets/images/hijaiyah/bermain/iconMenu/hijaiyah-play-opt-matchharokat@3x@3x.png'
import tekaTanwin from 'assets/images/hijaiyah/bermain/iconMenu/hijaiyah-play-opt-guesstanwin@3x.png'
import tekaTanwin2x from 'assets/images/hijaiyah/bermain/iconMenu/hijaiyah-play-opt-guesstanwin@3x@3x.png'
import tekaTanwin2 from 'assets/images/hijaiyah/bermain/iconMenu/hijaiyah-play-opt-matchtanwin@3x.png'
import tekaTanwin22x from 'assets/images/hijaiyah/bermain/iconMenu/hijaiyah-play-opt-matchtanwin@3x@3x.png'

// Menu belajar
import huruf from 'assets/images/hijaiyah/belajar/hijaiyah-iqra-opt-hijaiyah1@3x.png'
import huruf2x from 'assets/images/hijaiyah/belajar/hijaiyah-iqra-opt-hijaiyah1@3x@3x.png'
import huruf2 from 'assets/images/hijaiyah/belajar/hijaiyah-iqra-opt-hijaiyah2@3x.png'
import huruf22x from 'assets/images/hijaiyah/belajar/hijaiyah-iqra-opt-hijaiyah2@3x@3x.png'
import tanda from 'assets/images/hijaiyah/belajar/hijaiyah-iqra-opt-harokat1@3x.png'
import tanda2x from 'assets/images/hijaiyah/belajar/hijaiyah-iqra-opt-harokat1@3x@3x.png'
import tanda2 from 'assets/images/hijaiyah/belajar/hijaiyah-iqra-opt-harokat2@3x.png'
import tanda22x from 'assets/images/hijaiyah/belajar/hijaiyah-iqra-opt-harokat2@3x@3x.png'
import tanwin from 'assets/images/hijaiyah/belajar/hijaiyah-iqra-opt-tanwin1@3x.png'
import tanwin2x from 'assets/images/hijaiyah/belajar/hijaiyah-iqra-opt-tanwin1@3x@3x.png'
import tanwin2 from 'assets/images/hijaiyah/belajar/hijaiyah-iqra-opt-tanwin2@3x.png'
import tanwin22x from 'assets/images/hijaiyah/belajar/hijaiyah-iqra-opt-tanwin2@3x@3x.png'

// infoBermain
import tekaHurufInfo1 from 'assets/images/hijaiyah/bermain/help/hijaiyah-play-help-guesshijaiyah1@3x.png'
import tekaHurufInfo2 from 'assets/images/hijaiyah/bermain/help/hijaiyah-play-help-guesshijaiyah2@3x.png'
import tekaTandaInfo1 from 'assets/images/hijaiyah/bermain/help/hijaiyah-play-help-guessharokat@3x.png'
import tekaTandaInfo2 from 'assets/images/hijaiyah/bermain/help/hijaiyah-play-help-matchharokat@3x.png'
import tekaTanwinInfo1 from 'assets/images/hijaiyah/bermain/help/hijaiyah-play-help-guesstanwin@3x@3x.png'
import tekaTanwinInfo2 from 'assets/images/hijaiyah/bermain/help/hijaiyah-play-help-matchtanwin@3x.png'

// laporan
import reHuruf1 from 'assets/images/hijaiyah/bermain/laporan/hijaiyah-play-report-thumb-ghjy1@3x@3x.png'
import reHuruf2 from 'assets/images/hijaiyah/bermain/laporan/hijaiyah-play-report-thumb-ghjy2@3x@3x.png'
import reTanda1 from 'assets/images/hijaiyah/bermain/laporan/hijaiyah-play-report-thumb-ghrk@3x@3x.png'
import reTanda2 from 'assets/images/hijaiyah/bermain/laporan/hijaiyah-play-report-thumb-mhrk@3x@3x.png'
import reTanwin1 from 'assets/images/hijaiyah/bermain/laporan/hijaiyah-play-report-thumb-gtwn@3x@3x.png'
import reTanwin2 from 'assets/images/hijaiyah/bermain/laporan/hijaiyah-play-report-thumb-mtwn@3x@3x.png'

export default {
    gameImages:{
        Huruf:[tekaHurufInfo1,tekaHurufInfo2],
        Tanda:[tekaTandaInfo1,tekaTandaInfo2],
        Tanwin:[tekaTanwinInfo1,tekaTanwinInfo2]
    },
    menuHijaiyah:{
        bermain:[
            {
                id:1,
                name: "Huruf-1",
                img: tekaHuruf,
                img2x: tekaHuruf2x
            },
            {
                id:2,
                name: "Huruf-2",
                img: tekaHuruf2,
                img2x: tekaHuruf22x
            },
            {
                id:3,
                name: "Tanda-1",
                img: tekaTanda,
                img2x: tekaTanda2x
            },
            {
                id:4,
                name: "Tanda-2",
                img: tekaTanda2,
                img2x: tekaTanda22x
            },
            {
                id:5,
                name: "Tanwin-1",
                img: tekaTanwin,
                img2x: tekaTanwin2x
            },
            {
                id:6,
                name: "Tanwin-2",
                img: tekaTanwin2,
                img2x: tekaTanwin22x
            }
        ],
        belajar:[
            {
                id:1,
                name: "Huruf-1",
                img: huruf,
                img2x: huruf2x
            },
            {
                id:2,
                name: "Huruf-2",
                img: huruf2,
                img2x: huruf22x
            },
            {
                id:3,
                name: "Tanda-1",
                img: tanda,
                img2x: tanda2x
            },
            {
                id:4,
                name: "Tanda-2",
                img: tanda2,
                img2x: tanda22x
            },
            {
                id:5,
                name: "Tanwin-1",
                img: tanwin,
                img2x: tanwin2x
            },
            {
                id:6,
                name: "Tanwin-2",
                img: tanwin2,
                img2x: tanwin22x
            }
        ]
    },
    helpImage:{
        Huruf : iqraHuruf,
        Tanda0: iqraTanda,
        Tanda1: harakat2xActive,
        Tanda2: harakat3Active,
        Tanda3: harakat1Active,
        Tanwin0: iqraTanwin,
        Tanwin1: harakatTanwin22xActive,
        Tanwin2: harakatTanwin32xActive,
        Tanwin3: harakatTanwin12xActive,
    },
  judul:[
      {
          id:1,
          nama:'Huruf-1',
          img : Huruf1,
          img2 : Huruf1x,
          teka : TekaHuruf
      },
      {
          id:2,
          nama:'Huruf-2',
          img : Huruf2,
          img2 : Huruf2x,
          teka : TekaHuruf2
      },
      {
          id:3,
          nama:'Tanda-1',
          img : Tanda1,
          img2 : Tanda1x,
          teka : TekaTanda
      },
      {
          id:4,
          nama:'Tanda-2',
          img : Tanda2,
          img2 : Tanda2x,
          teka : TekaTanda2
      },
      {
          id:5,
          nama:'Tanwin-1',
          img : Tanwin1,
          img2 : Tanwin1x,
          teka : TekaTanwin
      },
      {
          id:6,
          nama:'Tanwin-2',
          img : Tanwin2,
          img2 : Tanwin2x,
          teka : TekaTanwin2
      }
  ],
  harakat : {
      button:[
        {
            id:1,
            optName : 'dhammah',
            color : '#47a334',
            imgBtn : harakat1,
            imgBtnActv:harakat1Active,
            optType : ["Tanda-1","Tanda-2"]
        },
        {
            id:2,
            optName : 'dhammahtain',
            color : '#47a334',
            imgBtn : harakatTanwin1,
            imgBtnActv: harakatTanwin12xActive,
            optType : ["Tanwin-1","Tanwin-2"]
        },
        {
            id:3,
            optName : 'kasrah',
            color: '#f5c056',
            imgBtn : harakat3,
            imgBtnActv:harakat3Active,
            optType : ["Tanda-1","Tanda-2"]
        },
        {
            id:4,
            optName : 'kasrahtain',
            color: '#f5c056',
            imgBtn : harakatTanwin3,
            imgBtnActv: harakatTanwin32xActive,
            optType : ["Tanwin-1","Tanwin-2"]
        },
        {
            id:5,
            optName : 'fathah',
            color: '#e65727',
            imgBtn : harakat2,
            imgBtnActv: harakat2xActive,
            optType : ["Tanda-1","Tanda-2"]
        },
        {
            id:6,
            optName : 'fathahtain',
            color: '#e65727',
            imgBtn : harakatTanwin2,
            imgBtnActv: harakatTanwin22xActive,
            optType : ["Tanwin-1","Tanwin-2"]
        }
    ],
      block: [
        {
            id:1,
            optName : 'dhammah',
            color : '#47a334',
            imgBtn : blockTanda,
            optType : ["Tanda-1","Tanda-2"]
        },
        {
            id:2,
            optName : 'dhammahtain',
            color : '#47a334',
            imgBtn : blockTanwin,
            optType : ["Tanwin-1","Tanwin-2"]
        },
        {
            id:3,
            optName : 'kasrah',
            color: '#f5c056',
            imgBtn : blockTanda2,
            optType : ["Tanda-1","Tanda-2"]
        },
        {
            id:4,
            optName : 'kasrahtain',
            color: '#f5c056',
            imgBtn : blockTanwin2,
            optType : ["Tanwin-1","Tanwin-2"]
        },
        {
            id:5,
            optName : 'fathah',
            color: '#e65727',
            imgBtn : blockTanda3,
            optType : ["Tanda-1","Tanda-2"]
        },
        {
            id:6,
            optName : 'fathahtain',
            color: '#e65727',
            imgBtn : blockTanwin3,
            optType : ["Tanwin-1","Tanwin-2"]
        }
    ]
  },
  laporan:[
      {
          id:1,
          type: 'Huruf-1',
          img: reHuruf1,
          score: localStorage.Huruf1 ? localStorage.Huruf1 : "-"
      },
      {
        id:2,
        type: 'Huruf-2',
        img: reHuruf2,
        score: localStorage.Huruf2 ? localStorage.Huruf2 : '-'
    },
    {
        id:3,
        type: 'Tanda-1',
        img: reTanda1,
        score: localStorage.Tanda1 ? localStorage.Tanda1 : "-"
    },
    {
        id:4,
        type: 'Tanda-2',
        img: reTanda2,
        score: localStorage.Tanda2 ? localStorage.Tanda2 : "-"
    },
    {
        id:5,
        type: 'Tanwin-1',
        img: reTanwin1,
        score: localStorage.Tanwin1 ? localStorage.Tanwin1 : "-"
    },
    {
        id:6,
        type: 'Tanwin-2',
        img: reTanwin2,
        score: localStorage.Tanwin2 ? localStorage.Tanwin2 : "-"
    }
    
  ]
}