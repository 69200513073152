import * as types from "./types";
import { createReducer } from "@kempeb";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const persistConfig = {
  key: "islamicMind:Application",
  storage,
  stateReconciler: autoMergeLevel2,
  // whitelist: ["language", "isLoading"]
  blacklist: ["levels", "level", "subjects", "subject", "topics", "isError"]
};

const initialState = {
  isLoading: true,
  isError: false,
  language: null,
  languages: [],
  quizes: [],
  historys: []
};

const reducer = createReducer(initialState, {
  [types.CHANGE_LANGUAGE](state, payload) {
    return {
      ...state,
      language: payload.data
    };
  },
  [types.POP_QUIZES](state, payload) {
    return {
      ...state,
      quizes: payload.data
    };
  },
  [types.STORE_HISTORY_WATCH](state, payload) {
    return {
      ...state,
      quizes: payload.data
    };
  },
  [types.STORE_LANGUAGES](state, payload) {
    return {
      ...state,
      ...payload.data
    };
  },
  [types.LOADING](state, payload) {
    return {
      ...state,
      isLoading: payload.data
    };
  },
  [types.ERRORS](state, payload) {
    return {
      ...state,
      isError: payload.data
    };
  },
});

export const Application = persistReducer(persistConfig, reducer);
