import React from "react";
import { Modal, Container } from "reactstrap";
import { Header } from "../components";
import islamicPlus from "assets/images/icon-logo-islamicmindplus-rounded.png";
import islamicPlus2x from "assets/images/icon-logo-islamicmindplus-rounded@2x.png";
import optInfo from "assets/images/icon-opt-info.png";
import optInfo2x from "assets/images/icon-opt-info@2x.png";

const MainLayout = React.memo(({ children, hadDarjah, isHome, hadFooter }) => {
  const [modalShow, setShow] = React.useState(false);
  React.useEffect(() => {
    const auth = true;
    const bodyTag = document.getElementsByTagName("body");
    if (!bodyTag.length) return;
    if (!auth) {
      bodyTag[0].className = "bg-login";
      return;
    }
    if(window.location.pathname !== '/hijaiyah'){
      bodyTag[0].className = "";
    }
  });

  const toggleModal = React.useCallback(() => setShow(!modalShow), [modalShow]);
  return (
    <React.Fragment>
      <Header hadDarjah={hadDarjah} isHome={isHome} />
      {children}
      {isHome && !hadDarjah && hadFooter && (
        <React.Fragment>
          <footer className="main-menu">
            <div className="icon-help">
              <button onClick={toggleModal}>
                <img
                  src={optInfo}
                  srcSet={`${optInfo}, ${optInfo2x}`}
                  className="width-icon-help img-responsive"
                />
              </button>
            </div>
            <Modal
              id="help-info"
              isOpen={modalShow}
              backdrop="static"
              centered
              contentClassName="bg-modal"
            >
              <button
                type="button"
                className="close btn-anchor"
                onClick={toggleModal}
                aria-label="Close"
              >
                <span aria-hidden="true" className="close-btn">
                  &times;
                </span>
              </button>
              <Container>
                <div className="content-scroll">
                  <div className="content-body">
                    <div className="header-modal text-center">
                      <img
                        src={islamicPlus}
                        srcSet={`${islamicPlus}, ${islamicPlus2x}`}
                        className="icon-modal-help img-responsive text-center"
                      />
                    </div>
                    <div className="button-modal text-center">
                      <button className="btn-modal-yellow">
                        About Islamic Mind
                      </button>
                    </div>
                    <div className="button-modal text-center">
                      <button className="btn-modal-yellow">Developer</button>
                    </div>
                    <div className="button-modal text-center">
                      <button className="btn-modal-yellow">Supporters</button>
                    </div>
                    <div className="button-modal text-center">
                      <button className="btn-modal-green">Support Us</button>
                    </div>
                  </div>
                </div>
              </Container>
            </Modal>
          </footer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
});

MainLayout.defaultProps = {
  hadFooter: true
}

export default MainLayout;
