import React from "react";
import { Route, Redirect } from "react-router-dom";
import { fetchProfile } from "modules/auth/actions";
import { setLoading, setError } from "modules/application/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const RouteAuthenticated = React.memo(
  ({
    component: Component,
    render,
    Auth,
    fetchProfile,
    setLoading,
    setError,
    ...res
  }) => {
    const { isAuthenticated, token } = Auth;

    if (!isAuthenticated || !token) {
      return <Redirect from="/:any" to="/" />;
    }

    async function getProfile() {
      const { status: xhrStatus, data: { status } = {} } = await fetchProfile();
      if (!xhrStatus || !status) {
        setError(true);
      }
    }

    React.useEffect(() => {
      getProfile();
      return () => false;
    }, []);

    return (
      <Route
        {...res}
        render={render ? render : props => <Component {...props} />}
      />
    );
  }
);

const RouteAnonymous = React.memo(
  ({ component: Component, render, Auth, ...res }) => {
    const { isAuthenticated, token } = Auth;

    if (isAuthenticated && token) {
      return <Redirect from="/:any" to="/home" />;
    }
    return (
      <Route
        {...res}
        render={render ? render : props => <Component {...props} />}
      />
    );
  }
);

const mapStateToProps = state => ({
  Auth: state.Auth
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchProfile, setLoading, setError }, dispatch);
}

const anonymousWithReducer = connect(mapStateToProps, null)(RouteAnonymous);

export default connect(mapStateToProps, mapDispatchToProps)(RouteAuthenticated);
export { anonymousWithReducer as RouteAnonymous };
