import React from "react";
import people from 'assets/images/hijaiyah/bermain/people/hijaiyah-play-match-jboy@3x.png'
import peopleCorrect from 'assets/images/hijaiyah/bermain/people/hijaiyah-play-match-jboy-answertrue@3x.png'
import peopleWrong from 'assets/images/hijaiyah/bermain/people/hijaiyah-play-match-jboy-answerfalse@3x.png'
import banner from 'assets/images/hijaiyah/bermain/Union 10@3x.png'
import bubbleHijaiyah from 'assets/images/hijaiyah/bermain/hijaiyah-play-bubble@3x@3x.png'
import trueIcon from 'assets/images/hijaiyah/bermain/hijaiyah-play-icon-true@3x@3x.png'
import falseIcon from 'assets/images/hijaiyah/bermain/hijaiyah-opt-popup-close@3x@3x.png'
import board2 from 'assets/images/hijaiyah/hijaiyah-play-opt-paper@-27@3x.png'
import images from '../../../images'
import iconSound from 'assets/images/hijaiyah/bermain/hijaiyah-play-opt-btn-sound@3x@3x.png'


class body extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            optionsButton: images.harakat.block,
            tanda:'',
            color:'',
            id:''
        }
    }
    eksekusi(tanda,color){
        const {id} = this.state
        this.setState({tanda,color})
        this.props.checkHijaiyah(id,tanda)
        setTimeout(() => {this.setState({tanda:'',color:''})}, 1000);
    }
    sound(){this.refs.audioBermain.play()}
    render(){
        const {optionsButton,tanda,color} = this.state
        const {state,board,checkHijaiyah,firstState,url,same} = this.props
        
        return(
                        <div className="page-games">
                            <audio ref='audioBermain' src={state.selecTanda === '' ? '' : state.hijaiyahSelect.audio.find(e=>e[`audio${state.selecTanda}`])[`audio${state.selecTanda}`]} autoPlay muted={state.seconds === 0 ? true : false}></audio>
                        {state.url1.includes(firstState.url) ?
                        <div className="board-select">
                        <div className="corection" style={{ display: state.display ? 'block' : 'none' }}>
                            <img src={state.corect ? trueIcon : falseIcon } />
                        </div>
                                <img src={board2} /> 
                                        <div className="huruf-hijaiyah">
                                            <div style={{color:state.hijaiyahSelect.color}} >
                                                {state.hijaiyahSelect.huruf}
                                            </div> 
                                        <span style={{color:state.color,display:state.corect ?'':'none'}}>
                                            {state.hijaiyahSelect.huruf}{state.hijaiyahSelect[state.selecTanda]}
                                        </span>
                                        </div>
                        </div>
                        :
                        <div className="select2">
                           <div className="optionsButton" style={{opacity: state.activeButton ? '1':'0'}}>
                           {state.hijaiyahRandom.map(e=>{
                                        return(
                                            <div key={e.id} onClick={_=>{
                                                same(e)
                                                this.setState({
                                                    id:e.id
                                                })
                                                }}>
                                                <img src={board.board2} />
                                                <div style={{color:e.color}}>
                                                   <div>{e.huruf}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                           </div>
                            <div className="peopleBlock">
                                <img  src={state.display ? state.corect ? peopleCorrect : peopleWrong : people } />
                                <div className="huruf-hijaiyah">
                                            <div style={{color:state.sameHijaiyah.color}} >
                                                {state.sameHijaiyah.huruf}
                                            </div> 
                                        <span style={{color:color}}>
                                            {state.sameHijaiyah.huruf}{state.sameHijaiyah[tanda]}
                                        </span>
                                        </div>
                            </div>
                           <div className="optionsButton" style={{opacity: state.activeButton && '0', pointerEvents: state.activeButton && 'none'}}>
                               {(optionsButton.filter(e=>e.optType.includes(url) === true)).map(e=>{
                                   return(
                                       <div key={e.id} onClick={_=>this.eksekusi(e.optName,e.color)}>
                                           <img src={e.imgBtn}/>
                                       </div>
                                   )
                                   
                               })}
                           </div>
                        </div>
                        }
                        
                         {state.url1.includes(firstState.url) &&
                             <div className="opt">
                        {(firstState.optButton.filter(e=>e.optType.includes(url) === true)).map(e=>{
                            return(
                                <div key={e.id} onClick={_=>checkHijaiyah(e.id,e.optName,e.color)}>
                                     <img src={e.imgBtn} style={{opacity:state.id === null ? '1' : state.id === e.id ? '1' : '0'}} />
                                 </div>
                            )
                        })} 
                      </div>}

                      <div className="button-sound" onClick={_=>this.sound()}>
                                        <img src={iconSound} />
                    </div>

                            </div> 
                       
        )
    }
}

export default body;
