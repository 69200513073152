import React from "react";
import aya from "../quran";
import ayaTab from './ayahTab';
import ayaWord from './ayahWord';
import * as languages from "languages";
import Main from "../../../layouts/main";
import { withRouter } from "react-router";
import translations from '../translations';
import _navs from "views/components/Header/navs";
import { NavLink as Link } from "react-router-dom";
import { Content, Section } from "../../../components";
import { Container, Nav, NavItem, Form } from "reactstrap";
import back from "assets/images/quran/buttonSound/icon-back@3x@3x.png";
import play from 'assets/images/quran/buttonSound/Mask Group 266@3x.png';
import pause from 'assets/images/quran/buttonSound/Mask Group 267@3x.png';
import next from 'assets/images/quran/buttonSound/icon-forward@3x@3x.png';
import prev from 'assets/images/quran/buttonSound/icon-backward@3x@3x.png';
import Alert from '../../hijaiyah/pages/modal/modal_back'

function getUrl(select,nomor,type,idx) {
  let tab = `http://api.projectmushaf.com/audio/Husary_64kbps/00100${select}.mp3`
  let tab2 = `${process.env.REACT_APP_BASE_URL}sounds/surah/long/${nomor}/${nomor}${select}.mp3`
  let word1 = `http://api.projectmushaf.com/audio/wbw/001_00${select}_00${idx + 1}.mp3`
  let word2 = `${process.env.REACT_APP_BASE_URL}sounds/surah/short/${nomor}/${nomor}_00${select}_00${idx + 1}.mp3`
  if (nomor === "001") {
    let check = type ? tab : word1
    return check
  }else{
    let check = type ? tab2 : word2
    return check
  }
}

function stopAudip(){
  let soundActive = document.getElementById("sound")
  soundActive.pause()
  soundActive.currentTime = 0;
}

class readQuran extends React.Component{
    constructor(props){
        super(props)

        let data = aya[parseFloat((window.location.pathname.split('/')[2]).split('_')[0])].reduce((g, i) => {
          g[i.aya - 1] = g[i.aya - 1] || []
          g[i.aya - 1].push(i)
          return g
        }, [])

        this.state = {
          data:data,
          tabs:[
            {
              id:1,
              name: 'Surah Penuh',
              status: true
            },
            {
              id:2,
              name: 'Dengan Kata',
              status: false
            }
          ],
          listenPage:[
            {
              id:1,
              name:'ayaTab',
              type: true,
              component: ayaTab
            },
            {
              id:2,
              name:'ayaWord',
              type: false,
              component: ayaWord
            }
          ],
          play: false,
          srcAudio: '',
          selectAya: 1,
          selectWord:0,
          popup: false,
          selectAyaId:1,
          selectIndxWord:0,
          listenQuran:true,
          idxSelect: 0,
          player: "stopped",
          nameSurah: (window.location.pathname.split('/')[2]).split('_')[2],
          totalAyah: (window.location.pathname.split('/')[2]).split('_')[1],
          nomorAyah: (window.location.pathname.split('/')[2]).split('_')[0]
        }
        this.changeSelect = this.changeSelect.bind(this)
        this.plays = this.plays.bind(this)
        this.next = this.next.bind(this)
        this.klikSound = this.klikSound.bind(this)
        this.actionButton = this.actionButton.bind(this)
        this.back = this.back.bind(this)
        this.backToMenu = this.backToMenu.bind(this)
    }

    changeSelect(data){
      const {nomorAyah,listenQuran} = this.state
      let srcAudio = getUrl(data + 1, nomorAyah,listenQuran)
      stopAudip()
      this.setState({
        selectAya: data + 1,
        selectWord: data,
        player: 'stopped',
        srcAudio
      })
    }

    async componentDidMount(){
      const {nomorAyah,selectAya, listenQuran} = this.state
      let bodyTag = document.getElementsByClassName('main')
      bodyTag[0].classList.add("margin0")
      let srcAudio = getUrl(selectAya,nomorAyah,listenQuran)
      this.setState({srcAudio,})
    }

    changeTab(status){
      const {nomorAyah ,selectAyaId, listenQuran,data} = this.state
      let audioWord = 0
      if (listenQuran === false) {audioWord = (data[selectAyaId - 1]).length - 1}
      let srcAudio = getUrl(1,nomorAyah,status,audioWord)
      stopAudip()
      this.setState({listenQuran: status,player : 'stopped',srcAudio,selectAya: 1, selectAyaId: 1, selectWord: 0,selectIndxWord: 0})
    }

    plays(status){
      const { selectAya,totalAyah,nomorAyah ,selectWord,listenQuran,data,selectIndxWord} = this.state
      let soundActive = document.getElementById("sound")
      if (status === 'stop'){
        stopAudip()
        this.setState({player: 'stopped'})
        soundActive.removeEventListener("ended",this.next)
        return
      }
      if (selectAya > totalAyah - 1){
        let srcAudio = getUrl(1,nomorAyah,listenQuran,selectIndxWord)
        this.setState({
          player : 'stopped',
          selectWord : 0,
          selectAya : 1,
          srcAudio
        })
        listenQuran && document.getElementById(`au-${0}`).scrollIntoView({ behavior: "smooth" })
          return
      }
      let srcAudio = getUrl(selectAya,nomorAyah,listenQuran,listenQuran ?  0 : selectIndxWord )
      
      this.setState({player : 'played',srcAudio})
      listenQuran && document.getElementById(`au-${selectWord}`).scrollIntoView({ behavior: "smooth" })
      soundActive.play();
      soundActive.addEventListener('ended',this.next)
    }

    next(){this.nextAudio()}

    nextAudio(){
        const { selectAya,selectWord,totalAyah,selectAyaId, data, selectIndxWord , listenQuran} = this.state
        if (listenQuran === false) {
          let audioWord = (data[selectAyaId - 1]).length 
          let index = selectIndxWord + 1 === audioWord
          this.setState({ 
            selectAya: index ? selectAya + 1 : selectAya, 
            selectAyaId: index ? totalAyah - 1 === selectAyaId ? 1 : selectAyaId + 1 : selectAyaId, 
            selectIndxWord : index ? 0 : selectIndxWord + 1,
          })
        } else {
          this.setState({selectAya: selectAya + 1,selectWord : selectWord + 1})
        }
        this.plays()
    }

    actionButton(type,idx,prev){
      const {listenQuran,data, selectAya,nomorAyah,idxSelect,selectAyaId,selectIndxWord ,selectWord} = this.state
      if (type === 'next'|| type === "prev") {
        let check = type === 'next' ? selectAya + 1 : selectAya - 1
        let srcAudio = getUrl(check,nomorAyah,listenQuran,selectIndxWord)
        this.setState({
          selectWord : type === 'next' ? selectWord + 1 : selectWord - 1,
          selectAyaId : type === 'next' ? selectAyaId + 1 : selectAyaId - 1,
          selectIndxWord: 0,
          player : 'stopped',
          selectAya : check,
          srcAudio 
        })
        return
      }else{
        let totalWord = data[selectAya - 1].length
        let nextAya = selectAya
        let nextWord
        if (type === "nextWord") {
          nextWord = selectIndxWord + 1
        } else if (type === "prevWord") {
          nextWord = selectIndxWord  - 1
        } 
        if (nextWord < 0) {
          nextAya = selectAya - 1
          nextWord = 0
          nextWord = data[nextAya - 1] ? data[nextAya - 1].length - 1 : 0
        } else if (nextWord >= totalWord) {
          nextAya = selectAya + 1
          nextWord = 0
        }
        let srcAudio = getUrl(nextAya,nomorAyah,listenQuran,nextWord)
        this.setState({
              selectAya: nextAya,
              selectIndxWord: nextWord,
              selectAyaId : nextAya,
              srcAudio
            })
      }
    }

    async klikSound(id){
      const {nomorAyah,selectAya, listenQuran} = this.state
      let srcAudio = getUrl(selectAya,nomorAyah,listenQuran,id)
      this.setState({selectIndxWord : id,srcAudio})
      let sound = document.getElementById('sound')
      sound.removeEventListener("ended",this.next)
      setTimeout(() => {
       sound.play()
      }, 1000);
      return
    }

    back(){
      let tag = document.getElementById('sound')
      if (!tag.ended && tag.currentTime > 0) {
        this.setState({
          popup: !this.state.popup
        })
        return
      }
      setTimeout(() => {window.location.href="/quran"}, 1000);
    }

    backToMenu(){
      setTimeout(() => {window.location.href="/quran"}, 1000);
    }

    render(){
      const {listenQuran, listenPage,totalAyah, selectAyaId, tabs, nameSurah ,selectWord, player, popup} = this.state
      let prevNext = listenQuran ? selectWord : selectAyaId - 1
        return(
            <Main hadDarjah={false} isHome={true}>
              {popup && 
                    <Alert func={this.back} back={this.backToMenu}/>
                    }
                <audio id="sound" autoPlay={this.state.play} ref="quranAudio" src={this.state.srcAudio}/>
                <div className="content-container">
                     <div className="main">
                       <div className="back-button">
                         <div>
                           <img src={back} onClick={_=>this.back('back')} />
                         </div>
                         <div> {nameSurah} </div>
                       </div>
                       <div className="pageQuranRead">
                         <div className="navTab">
                           {tabs.map((e,indx)=>{
                             return(
                             <div key={indx} className={`tab ${e.status === listenQuran ? 'selectTab pointer-none' : 'nonSelectTab' }`} onClick={_=>this.changeTab(e.status)}>
                               {e.name}
                             </div>
                             )
                           })}
                         </div>
                        <div className="quranReadContent gray">
                       {listenPage.map((e,indx)=>{
                          const {component: Component , type} = e
                          return(
                              listenQuran === type &&
                              <Component
                                  key={indx}
                                  translations={translations}
                                  state={this.state}
                                  func={{
                                    changeSelect:this.changeSelect,
                                    klikSound:this.klikSound,
                                    actionButton:this.actionButton
                                   }}
                              />
                          )
                       })}
                       </div>
                        </div>
                     </div>
                       <div className="button-play">
                         <div className="background-button">
                           <div className={`${prevNext === 0 &&  'event-none'}`}>
                              <img src={prev} onClick={_=>this.actionButton('prev')}/>
                           </div>
                           <div>
                           <img src={player === 'stopped' ? play : pause} onClick={_=>player === 'played' ? this.plays('stop') : this.plays() } />
                           </div>
                           <div className={`${prevNext === totalAyah - 2  && 'event-none'}`}>
                             <img src={next} onClick={_=>{this.actionButton('next')}} />
                           </div>
                         </div>
                       </div>
                 </div>
            </Main>
        )
    }
}

export default withRouter(readQuran);
