import React from "react";
import { withRouter } from "react-router";
import Main from "../../../layouts/main";
import quran from '../ayahQuran'
import * as languages from "languages";
import { Content, Section } from "../../../components";
import { Container, Nav, NavItem, Form } from "reactstrap";
import _navs from "views/components/Header/navs";
import { NavLink as Link } from "react-router-dom";
import buttonSearch from 'assets/images/quran/icon-search@3x@3x.png'
class main extends React.Component{
constructor(props){
  super(props)
  this.state = {
    listQuran: quran,
    inSearch : ""
  }
}

search(data){ 
  const {inSearch} = this.state
  if (data === 'search') {
    var quranList = quran.filter((obj) => {
      var flag = false;
      [obj.nameAyah].forEach((val) => {
        if(String(val).toLowerCase().indexOf(inSearch.toLowerCase()) > -1) {
          flag = true;
          return;
        }    
      })
      if(flag) return obj;
    });
    this.setState({listQuran : inSearch === '' ? quran : quranList})
    return
  }
this.setState({inSearch: data})
}

            render(){
              const {listQuran} = this.state
                return(
                    <Main hadDarjah={false} isHome={true}>
               <div className="content-container">
                    <div className="sidebar">
                      <div className="fixed-sidebar bg-color-secondary">
                        <Nav className="ml-auto center-item sidebar-content" navbar>
                          {_navs.map((nav, index) => (
                            <NavItem key={index} className="sidebar-item">
                              <Link to={nav.url} location={this.props.location}>
                                <img
                                  src={nav.home.normal.src}
                                  className="icon-card img-responsive"
                                />
                              </Link>
                            </NavItem>
                          ))}
                        </Nav>
                      </div>
                    </div>

                    <div className="main">
                       <div className="quran-content" >
                            <div className="search-quran">
                              <div className="textSeacrh">Search Surah</div>
                              <div className="tabSearch">
                                <img src={buttonSearch} onClick={_=>this.search("search")}/>
                               <input type="text" onChange={e=>this.search(e.target.value)}  />
                              </div>
                            </div>
                            <div className="list-quran">
                                {listQuran.length > 0 ? listQuran.map((e,indx)=>{
                                    return(
                                        <div className="card-list" key={indx} onClick={_=>window.location.href=`/quranRead/${e.id}_${e.ayah + 1}_${e.nameAyah}`}>
                                            <div className="name-surah">{e.nameAyah}</div>
                                            <div className="ayat-surah">{e.jenisAyah} | {e.ayah} ayah</div>
                                        </div>
                                    )
                                }) : 
                                <div className="no-list-quran">
                                  Data Empty
                                </div>}
                            </div>
                       </div>
                    </div>
                </div>
            </Main>
        )
    }
}

export default withRouter(main);
