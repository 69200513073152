import React from "react";
import {
  Container,
  Nav,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { Loadable } from "@kempeb";
import Logo from "assets/images/icon-logo-islamicmindplus@2x.png";
import defaultPic from "assets/images/icon-user@2x.png";
import backFromBelajar from "assets/images/icon-backhome-belajar@2x.png";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "modules/auth/actions";

const DefaultNavbar = Loadable(() => import("./navbar"));

const Header = React.memo(
  ({
    isHome,
    logout,
    Auth
  }) => {
    const [collapsed, setCollapsed] = React.useState(false);
    const history = useHistory();
    const { user = {} } = Auth;

    const toggleNavbar = () => setCollapsed(!collapsed);
    return (
      <Nav className="fixed-top bg-header">
        <Container fluid className="pl-pd-0 pull-right">
          <Row>
            <Col md={12}>
              <Navbar dark expand="lg">
                <NavbarBrand
                  tag="button"
                  className="btn-anchor"
                  onClick={_ => isHome ? history.replace('/home') : history.goBack()}
                >
                  <img
                    className="width-logo-main"
                    src={isHome ? Logo : backFromBelajar}
                    alt="Nav Btn"
                  />
                </NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} />
                <Collapse isOpen={collapsed} navbar id="navbar">
                  <Nav
                    className="w-100 center-item d-flex flex-row py-3 py-md-0"
                    navbar
                  >
                    {!isHome && <DefaultNavbar />}
                    <NavItem className="mr-15 ml-auto d-flex flex-row align-items-center">
                      <p className="text-white mb-0 mr-3" id="user-placeholder">
                        {user?.username ?? ""}
                      </p>
                      <UncontrolledDropdown className="user-account-btn">
                        <DropdownToggle
                          tag="button"
                          title="My Account"
                          className="user-profile btn-anchor clearfix"
                        >
                          <img
                            src={
                              user?.profile_img
                                ? process.env.REACT_APP_BASE_URL +
                                  user.profile_img
                                : defaultPic
                            }
                            className="rounded-circle user-pic anim"
                            alt="Profile Picture"
                            width="60"
                            height="60"
                          />
                          <i className="glyph-icon icon-angle-down" />
                        </DropdownToggle>
                        <DropdownMenu className="shadow">
                          <div className="box-sm">
                            <div className="login-box clearfix">
                              <div className="text-center">
                                <img
                                  src={
                                    user?.profile_img
                                      ? process.env.REACT_APP_BASE_URL +
                                        user.profile_img
                                      : defaultPic
                                  }
                                  alt="icon-user"
                                  className="user-pic rounded-circle width-user-modal"
                                />
                              </div>
                              <div className="text-center user-info">
                                <span className="text-green" id="user-name">
                                  {user?.username ?? ""}
                                </span>
                              </div>
                            </div>
                            <div className="pad5A button-pane button-pane-alt text-center">
                              <a
                                className="btn display-block font-normal"
                                id="logout-action"
                                onClick={logout}
                              >
                                <i className="glyph-icon icon-power-off" />
                                Logout
                              </a>
                            </div>
                          </div>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
            </Col>
          </Row>
        </Container>
      </Nav>
    );
  }
);

Header.defaultProps = {
  isHome: false
};

Header.propTypes = {
  isHome: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { logout },
    dispatch
  );
}

const mapStateToProps = state => ({
  Auth: state.Auth,
  Application: state.Application
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
