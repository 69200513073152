import React from "react";
import images from '../../images'
import board from 'assets/images/hijaiyah/Rectangle 1415@3x.png'
import title from 'assets/images/hijaiyah/bermain/laporan@3x.png'
import close from 'assets/images/hijaiyah/bermain/hijaiyah-opt-popup-close@3x@3x.png'
class modal extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            linkUrl: window.location.pathname.split('/')[1],
            labelScore : images.laporan
        }
    }
    render(){
        const {labelScore} = this.state
        const {closeFunc} = this.props
        return(
            <div className="popup-hijaiyah">    
                    <div className="board">
                        <div className="close-laporan" onClick={_=>closeFunc()}>
                            <img src={close} />
                        </div>
                        <img src={board} className="board-laporan" />
                        <div className="title">
                            <img src={title} />
                        </div>
                    </div>
                <div className="body-laporan">
                    <div className="laporan">
                        {labelScore.map((e,indx)=>{
                            return(
                                <div className="img-laporan" key={indx}>
                                    <img src={e.img}/>
                                    <p>{e.score}</p>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
              </div>
            )}
}

export default modal;
