import React from "react";
import people from 'assets/images/hijaiyah/bermain/people/hijaiyah-play-jboy@2x@3x.png'
import peopleCorrect from 'assets/images/hijaiyah/bermain/people/hijaiyah-play-jboy-answertrue@2x@3x.png'
import peopleWrong from 'assets/images/hijaiyah/bermain/people/hijaiyah-play-jboy-answerfalse@2x@3x.png'
import banner from 'assets/images/hijaiyah/bermain/Union 10@3x.png'
import bubbleHijaiyah from 'assets/images/hijaiyah/bermain/hijaiyah-play-bubble@3x@3x.png'
import trueIcon from 'assets/images/hijaiyah/bermain/hijaiyah-play-icon-true@3x@3x.png'
import falseIcon from 'assets/images/hijaiyah/bermain/hijaiyah-opt-popup-close@3x@3x.png'
import board2 from 'assets/images/hijaiyah/hijaiyah-play-opt-paper@-27@3x.png'
import iconSound from 'assets/images/hijaiyah/bermain/hijaiyah-play-opt-btn-sound@3x@3x.png'

class body extends React.Component{
    sound(){this.refs.audioBermain.play()}
    render(){
        const {state,checkHijaiyah,url} = this.props
        return(
            <div className="page-games">
            <audio ref='audioBermain' src={state.hijaiyahSelect.hijaiyah} autoPlay muted={state.seconds === 0 ? true : false}></audio>
                                <div className="bubble" style={{ display: url === "Huruf-2" && "none" }} >
                                    <img src={bubbleHijaiyah}/>
                                    <p onClick={_=>this.sound()} style={{ color:state.hijaiyahSelect.color }}>{state.hijaiyahSelect.huruf}</p>
                                </div>
                              {url === "Huruf-1" ? 
                            <div className="board" style={{pointerEvents: state.corect ? 'none' : ''}} >
                                <div className="corection" style={{ display: state.display ? 'block' : 'none' }}>
                                    <img src={state.corect ? trueIcon : falseIcon } />
                                </div>
                                <div>
                                    <img src={banner} />
                                    <div>
                                    {state.hijaiyahRandom.map((e,indx)=>{
                                        return(
                                            <p key={indx} style={{ color:e.color , opacity:state.id === null ? '1' : state.id === e.id ? '1' : '0.5' }} onClick={_=>checkHijaiyah(e.id)} >{e.huruf}</p>
                                            )
                                    })}
                                    </div>
                                </div>
                                <img src={state.display ? state.corect ? peopleCorrect : peopleWrong : people } />
                             </div>
                             :
                              <div className="options">
                                  {state.hijaiyahRandom.map((e,indx)=>{
                                        return(
                                            <div className="board-options" key={indx} onClick={_=>checkHijaiyah(e.id)} style={{opacity:state.id === null ? '1' : state.id === e.id ? '1' : '0'}}>
                                            <p style={{ color:e.color }}>{e.huruf}</p>
                                            <div className="corection" style={{ display: state.display ? 'block' : 'none' }}>
                                                 <img src={state.corect ? trueIcon : falseIcon } />
                                            </div>
                                            <img src={board2} />
                                         </div>
                                            )
                                        })}
                                 <div className="button-sound" onClick={_=>this.sound()}>
                                        <img src={iconSound} />
                                 </div>
                              </div>
                        }
                            </div> 
                       
                            )
    }
}

export default body;
