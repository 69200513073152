import React from "react";
import langIdn from 'languages/id'
import gamelist from 'assets/images/hijaiyah/bermain/button/hijaiyah-play-results-gamelist@3x@3x.png'
import restart from 'assets/images/hijaiyah/bermain/button/hijaiyah-play-results-restart@3x@3x.png'
import report from 'assets/images/hijaiyah/bermain/button/hijaiyah-play-results-report@3x@3x.png'
import logoPeople from 'assets/images/hijaiyah/bermain/hijaiyah-play-results-jboy@3x.png'
import bannerScore from 'assets/images/hijaiyah/bermain/Rectangle 1500@3x.png'
import start from 'assets/images/hijaiyah/bermain/hijaiyah-play-star-empty@3x@3x.png'
import startActive from 'assets/images/hijaiyah/bermain/hijaiyah-play-star@3x@3x.png'
import keputusan from 'assets/images/hijaiyah/bermain/hijaiyah-play-topboard-results@3x@3x.png'
class modalBelajar extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            scoreDetail:0
        }
    }
    componentDidMount(number = 0){
        if (this.props.state.popupType !== 'info') {
            const { score }  = this.props.state
            if (number === 3) return
            if (score >= 10 || score >= 160) {
                setTimeout(() => {
                    this.setState({
                        scoreDetail: number + 1
                    })
                    this.componentDidMount(number + 1)
                }, 1000)
            }
            
        }
    }

    reloade(){
        this.props.func.eventClick()
        window.location.reload()
    }
    render(){
        const {images,state,func,imgBoard} = this.props
        const { scoreDetail } = this.state
        const url = state.url.split('-')
        const style = {
            maxWidth: "227px",
            position: "relative",
            top: "-616px",
            margin: "0px auto",
        }
        return(
            <div style={{ top:'9%' }}>
            {state.popupType === 'info' ?
                     <div className="hg-0">
                         <img src={imgBoard.boardInfo} />
                         <div className="content-info" style={{ fontSize:'27px' }}>
                                 <div className="help">
                                    <img src={images.gameImages[`${url[0]}`].find((e,indx)=>indx === url[1]-1)} width="285" />
                                    {Array.isArray(langIdn.games.help[url[0]]) ?
                                    <p style={{ lineHeight:'2.2rem' }}>{langIdn.games.help[`${url[0]}`].find((e,indx)=>indx === url[1]-1)}</p>
                                    :
                                    <p style={{ lineHeight:'2.2rem' }}>{langIdn.games.help[url[0]]}</p>
                                    }
                                 </div> 
                         </div>
                         <div className="button-ok">
                             <img onClick={_=>func.popupInfo('', 'quiz' )} src={imgBoard.helpOk2} />
                         </div>
                     </div>
                     :
                     <div className="hg-0">
                     <img src={imgBoard.boardInfo} />
                     <div className="content-info" style={{ fontSize:'27px' }}>
                             <div className="help" style={{ width:'100%' }}>
                                <div className="keputusan">
                                    <img src={keputusan} />
                                </div>
                                <img src={logoPeople} width="285" />
                                <div className="help-game">
                                    <img src={bannerScore} />
                                <p>Markah : <span>{state.score}</span></p>
                                    <div className="start">
                                        <div>
                                            <img src={start} />
                                            {scoreDetail >= 1  && state.score >= 100 && <img src={startActive} />}
                                            
                                        </div>
                                        <div>
                                            <img src={start} />
                                            {scoreDetail >= 2 && state.score >= 160 && <img src={startActive} />}
                                        </div>
                                        <div>
                                            <img src={start} />
                                            {scoreDetail == 3 && state.score >= 200 && <img src={startActive} />}
                                        </div>
                                    </div>
                                </div>
                             </div> 
                     </div>
                     <div className="button-ok" style={style} >
                         <div>
                         <img onClick={_=>{this.reloade()}} src={restart} />
                         </div>
                         <div>
                         <img onClick={_=>func.backMenu()} src={gamelist} />
                         </div>
                         <div>
                         <img onClick={_=>console.log('see Score')} src={report} />  
                         </div>
                     </div>
                 </div>}
                 </div>
                
            )}
}

export default modalBelajar;
// export default withReducer('hijaiyah', reducer)(pages);
