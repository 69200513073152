import React from "react";
import { withRouter } from "react-router";
import Main from "../../../layouts/main";
import Back2x from 'assets/images/icon-back@2x.png'
import Belajar2x from 'assets/images/hijaiyah/hijaiyah-home-opt-iqra@2x@3x.png'
import Bermain2x from 'assets/images/hijaiyah/hijaiyah-home-opt-play@2x@3x.png'
import ScoreModal from '../pages/modal/modal_score'
import logo from 'assets/images/hijaiyah/hijaiyah-home-heading@3x@3x.png'
import scoreButton from 'assets/images/hijaiyah/bermain/button/hijaiyah-play-results-report@3x@3x.png'

// people
import people2 from 'assets/images/hijaiyah/hijaiyah-home-jboy@3x@3x.png'
import people4 from 'assets/images/hijaiyah/hijaiyah-home-jgirl@3x@3x.png'
import Loading from '../pages/loading'
import images from '../images'

import * as languages from "languages";
import { Content, Section } from "../../../components";
import { Container, Nav, NavItem, Form } from "reactstrap";
import _navs from "views/components/Header/navs";
import { NavLink as Link } from "react-router-dom";

class hijaiyah extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            menu:[
                {
                    id:1,
                    name: "Belajar",
                    img : Belajar2x

                },
                {
                    id:2,
                    name: "Bermain",
                    img : Bermain2x
                }
            ],
            iconHijaiyah:[],
            TabActive:1,
            loading: false,
            popupScore : false,
            pageSelect: false
        }
        this.closeScrore = this.closeScrore.bind(this)
    }

    componentDidMount(){
        this.setState({iconHijaiyah: images.menuHijaiyah.belajar})
    }
    
    closeScrore(){
        this.setState({
            popupScore: !this.state.popupScore
        })
    }

    goTo(e){
        const { menuHijaiyah } = images
        this.setState({
            pageSelect: true,
            TabActive: e,
            iconHijaiyah: e === 1 ? menuHijaiyah.belajar : menuHijaiyah.bermain
        })
    }
            
            render(){
                const { menu,loading,popupScore, pageSelect , TabActive , iconHijaiyah} = this.state
                
                return(
                    <Main hadDarjah={false} isHome={true}>
               {loading && <Loading />}
               {popupScore &&<ScoreModal closeFunc={this.closeScrore} />}
               
               <div className="content-container">
                    <div className="sidebar">
                      <div className="fixed-sidebar bg-color-secondary">
                        <Nav className="ml-auto center-item sidebar-content" navbar>
                          {_navs.map((nav, index) => (
                            <NavItem key={index} className="sidebar-item">
                              <Link to={nav.url} location={this.props.location}>
                                <img
                                  src={nav.home.normal.src}
                                //   srcSet={nav.home.normal.srcSet}
                                  className="icon-card img-responsive"
                                />
                              </Link>
                            </NavItem>
                          ))}
                        </Nav>
                      </div>
                    </div>

                    <div className="main">
                    {pageSelect && 
                        <div className="button-backMenu">
                        <img src={Back2x} onClick={_=>this.setState({pageSelect:false, TabActive:1})} />
                        </div>
                    }
                        <div className="hijaiyah">
                            {pageSelect !== true && 
                            <div className="menu">
                                <div className="title-hijaiyah">
                                    <img src={logo} />
                                </div>
                                 <div className="in-menu">
                                    {true && menu.map((e)=>{
                                        return(
                                            <div key={e.id} onClick={_=>this.goTo(e.id)} >
                                                <img src={e.img} />
                                            </div>
                                        )
                                    })}
                                 </div> 
                                </div>
                            }
        
                            {pageSelect === true &&
                                <div className="content">
                                     {iconHijaiyah.map(e=>{
                                         return(
                                             <div key={e.id} onClick={_=>{
                                                 setTimeout(() => {window.location.href=`/${TabActive === 1 ? "Belajar" : "Bermain"}/${e.name}`}, 1000);
                                                }}>
                                                <img src={e.img2x}/>
                                            </div>
                                        )    
                                    })
                                }
                                </div>
                            }
                        </div>
        
                    </div>
                </div>
              
                {/* Score Button */}
                { TabActive === 2 && <div className="info-score">
                    <img src={scoreButton} onClick={_=>this.closeScrore()} />
                </div>}

                {/* people */}
                <div className="people">
                     <img src={people2} className="boy" />
                     <img src={people4} className="girl" /> 
                </div>
               
            </Main>
        )
    }
}

export default withRouter(hijaiyah);
