import React from "react";

class loading extends React.Component{
    render(){
        return(
            <div className="loading-page">
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="1511.745 -22573.381 1197.765 975.18">
                    <g id="Group_4512" data-name="Group 4512" transform="translate(-1386 1992)">
                      <text id="Loading" className="cls-1" transform="translate(3231.004 -23621.201)"><tspan x="0" y="0">Loading...</tspan></text>
                      <path id="Path_14299" data-name="Path 14299" className="cls-2" d="M499.285,341.1C392.571,310.81,334.417,301.226,301.476,443.05S167.358,546.136,167.358,546.136s-85.71-15.272-83.626,68.991,59.331,102.32,59.331,102.32,104.846,57.237,46.746,143.821S238.7,1057.21,397.752,1004.69s267.307-300.8,267.307-300.8S606,371.382,499.285,341.1Z" transform="translate(2814.05 -24846.928)"/>
                      <circle id="Ellipse_1380" data-name="Ellipse 1380" className="cls-2" cx="27.631" cy="27.631" r="27.631" transform="translate(2916.011 -24068.553)"/>
                      <path id="Path_14300" data-name="Path 14300" className="cls-2" d="M443.675,324.76s146.772-22.137,151.487,157.134S714.609,677.355,714.609,677.355s137.887,71.2,66.508,224.371-223.58,90.1-223.58,90.1-68.165-32.307-133.556,0S182.715,832.692,182.715,832.692L248.654,420.8C265.139,317.825,443.675,324.76,443.675,324.76Z" transform="translate(3262.233 -24840.666)"/>
                      <circle id="Ellipse_1382" data-name="Ellipse 1382" className="cls-2" cx="27.631" cy="27.631" r="27.631" transform="translate(3888.617 -24471.965)"/>
                      <circle id="Ellipse_1383" data-name="Ellipse 1383" className="cls-2" cx="16.579" cy="16.579" r="16.579" transform="translate(3827.829 -24560.383)"/>
                      <ellipse id="Ellipse_1379" data-name="Ellipse 1379" className="cls-2" cx="49.736" cy="60.788" rx="49.736" ry="60.788" transform="translate(3026.263 -24499.949) rotate(31)"/>
                      <ellipse id="Ellipse_1381" data-name="Ellipse 1381" className="cls-2" cx="74.603" cy="91.182" rx="74.603" ry="91.182" transform="translate(4095.51 -24253.586) rotate(160)"/>
                      <g id="Group_3622" data-name="Group 3622" transform="translate(3208.898 -24565.381)">
                        <path id="Path_13396" data-name="Path 13396" className="cls-3" d="M-492.517,4336.171c-76.6-25.148-98.2-59.8-103.113-83.807,4.915-24,26.518-58.656,103.113-83.8,128.981-42.353,102.029-204.06,102.029-204.06H-914.111s-26.951,161.707,102.03,204.06c76.595,25.148,98.2,59.807,103.113,83.8-4.915,24-26.519,58.658-103.113,83.807-128.981,42.353-102.03,204.063-102.03,204.063h523.623S-363.536,4378.523-492.517,4336.171Z" transform="translate(942.987 -3875.947)"/>
                        <path id="Path_13397" data-name="Path 13397" className="cls-4" d="M-457.008,4022.71s-124.337-31.762-215.205-28.875v.425c-90.867-2.887-215.206,28.874-215.206,28.874-20.212,54.862,57.75,86.628,57.75,86.628,112.611,37.536,121.274,87.792,121.274,87.792h36.182v-.424h36.182s8.663-50.255,121.275-87.792C-514.755,4109.335-436.795,4077.577-457.008,4022.71Z" transform="translate(966.751 -3848.978)"/>
                        <rect id="Rectangle_1276" data-name="Rectangle 1276" className="cls-5" width="581.377" height="88.554" transform="translate(3.85 0)"/>
                        <rect id="Rectangle_1277" data-name="Rectangle 1277" className="cls-5" width="581.377" height="88.554" transform="translate(0 664.285)"/>
                        <path id="Path_13398" data-name="Path 13398" className="cls-4" d="M-818.879,4072.923s84.017,36.768,57.065,264.389h50.052s-25.317-227.474,52.594-264.392" transform="translate(1033.251 -3775.648)"/>
                        <g id="Group_3388" data-name="Group 3388" transform="translate(75.987 506.422)">
                          <path id="Path_13399" data-name="Path 13399" className="cls-6" d="M-890.764,4292.045s68.395-104.577,218.551-110.479v110.479" transform="translate(890.764 -4181.565)"/>
                          <path id="Path_13400" data-name="Path 13400" className="cls-6" d="M-560.684,4292.045s-68.394-104.577-218.551-110.479v110.479" transform="translate(993.938 -4181.565)"/>
                        </g>
                        <ellipse id="Ellipse_1252" data-name="Ellipse 1252" className="cls-4" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(175.637 557.812)"/>
                        <ellipse id="Ellipse_1253" data-name="Ellipse 1253" className="cls-4" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(247.372 534.711)"/>
                        <ellipse id="Ellipse_1254" data-name="Ellipse 1254" className="cls-4" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(277.856 576.401)"/>
                        <ellipse id="Ellipse_1255" data-name="Ellipse 1255" className="cls-4" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(329.271 542.333)"/>
                        <ellipse id="Ellipse_1256" data-name="Ellipse 1256" className="cls-4" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(351.221 584.023)"/>
                        <ellipse id="Ellipse_1257" data-name="Ellipse 1257" className="cls-4" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(399.964 561.662)"/>
                        <ellipse id="Ellipse_1258" data-name="Ellipse 1258" className="cls-4" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(206.751 584.023)"/>
                        <ellipse id="Ellipse_1259" data-name="Ellipse 1259" className="cls-6" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(174.754 198.461)"/>
                        <ellipse id="Ellipse_1260" data-name="Ellipse 1260" className="cls-6" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(246.488 175.36)"/>
                        <ellipse id="Ellipse_1261" data-name="Ellipse 1261" className="cls-6" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(276.972 217.05)"/>
                        <ellipse id="Ellipse_1262" data-name="Ellipse 1262" className="cls-6" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(328.387 182.981)"/>
                        <ellipse id="Ellipse_1263" data-name="Ellipse 1263" className="cls-6" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(350.337 224.671)"/>
                        <ellipse id="Ellipse_1264" data-name="Ellipse 1264" className="cls-6" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(399.08 202.311)"/>
                        <ellipse id="Ellipse_1265" data-name="Ellipse 1265" className="cls-6" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(205.867 224.671)"/>
                        <ellipse id="Ellipse_1266" data-name="Ellipse 1266" className="cls-6" cx="7.621" cy="7.621" rx="7.621" ry="7.621" transform="translate(277.856 259.322)"/>
                        <path id="Path_13401" data-name="Path 13401" className="cls-6" d="M-801.737,4072.921s54.224,70.419,37.219,226.35h21.5s-9.308-121.28,22.3-190.58c0,0-20.691,17.455-26.148,43.378,0,0-5.619-35.68-14.36-58.78l-3.288,40.427S-773.827,4102.61-801.737,4072.921Z" transform="translate(1049.109 -3775.648)"/>
                        <path id="Path_13402" data-name="Path 13402" className="cls-6" d="M-766.236,4115.709s3.477-49.412,53.716-54.543C-712.52,4061.167-732.832,4062.289-766.236,4115.709Z" transform="translate(1081.951 -3786.522)"/>
                        <path id="Path_13403" data-name="Path 13403" className="cls-6" d="M-768.324,4095.991s0-23.1,16.685-32.086C-751.639,4063.9-777.278,4059.7-768.324,4095.991Z" transform="translate(1078.263 -3784.128)"/>
                        <path id="Path_13404" data-name="Path 13404" className="cls-6" d="M-761.388,4091.643s-8.095-37.627-57.951-36.422C-819.337,4055.221-788.339,4062.694-761.388,4091.643Z" transform="translate(1032.825 -3792.048)"/>
                      </g>
                    </g>
                  </svg>
                </div>
            </div>
            )
        }
}
export default loading;
