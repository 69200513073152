import React, {Fragment} from "react";
import { withRouter } from "react-router";
import Back2x from 'assets/images/icon-back@2x.png'
import left2x from 'assets/images/hijaiyah/hijaiyah-opt-arrow-left@3x@3x.png'
import right2x from 'assets/images/hijaiyah/hijaiyah-opt-arrow-right@3x@3x.png'
import board from 'assets/images/hijaiyah/hijaiyah-iqra-opt-paper-slide@3x.png'
import board2 from 'assets/images/hijaiyah/hijaiyah-play-opt-paper@-27@3x.png'
import info from 'assets/images/hijaiyah/hijaiyah-opt-btn-help@3x.png'
import Body from './content'

class pages extends React.Component{
    render(){
        const {state,func} = this.props
        return(
            <Fragment>
                    {/* Header */}
                    <div className="header-page">
                        <div className="icon-back">
                            <img src={`${Back2x}`} onClick={_=> func.backMenu()} />
                        </div>
                        <div className="judul">
                            <img src={(func.findImage(state.url)).img2}/>
                        </div>
                        <div className="detail-score">{''} <img src={info} onClick={_=>func.popupInfo('info')} ></img></div>
                    </div>

                    {/* Body pelajaran 1/2 */}
                    <Body
                        func={func}
                        state={state}  
                        board={{board:board, board2:board2}} 
                        arrow={{right2x:right2x,left2x:left2x}} 
                    />

                    {/* OPT Audio */}
                    { state.typeUrl.includes(state.url) && 
                     <div className="opt" style={{ top:state.typeUrl2.includes(state.url) ? '32%' : '11%' }}>
                        {(state.optButton.filter(e=>e.optType.includes(state.url) === true)).map(e=>{
                            return(
                                <div key={e.id}>
                                     <img src={state.otpType === e.optName ?e.imgBtnActv : e.imgBtn} onClick={_=>func.optAction(e.optName,e.color)}/>
                                 </div>
                            )
                        })} 
                      </div>
                    }

                    {/* Info */}
                    <div className="info">
                        <div>
                            <img src={info} onClick={_=>func.popupInfo('info')} ></img>
                        </div>
                    </div>
                    </Fragment>

        )
    }
}

export default withRouter(pages);
// export default withReducer('hijaiyah', reducer)(pages);
