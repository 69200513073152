import React from "react"
import { Loadable } from "@kempeb"
import hijaiyah from './sections/hijaiyah/index'
import quran from './sections/quran/index'

const Login = Loadable(() => import('./sections'), undefined, {
    render(loaded, props) {
        let Component = loaded.Login
        return <Component {...props}/>
    }
})

const Register = Loadable(() => import('./sections'), undefined, {
    render(loaded, props) {
        let Component = loaded.Register
        return <Component {...props}/>
    }
})

const ForgotAccount = Loadable(() => import('./sections'), undefined, {
    render(loaded, props) {
        let Component = loaded.ForgotAccount
        return <Component {...props}/>
    }
})

const Home = Loadable(() => import("./sections"), undefined, {
    render(loaded, props) {
        let Component = loaded.Home
        return <Component {...props}/>
    }
})

const Belajar = Loadable(() => import("./sections"), undefined, {
    render(loaded, props) {
        let Component = loaded.Belajar
        return <Component {...props}/>
    }
})

const Kuiz = Loadable(() => import("./sections"), undefined, {
    render(loaded, props) {
        let Component = loaded.Kuiz
        return <Component {...props}/>
    }
})

const DetailBelajar = Loadable(() => import("./sections"), undefined, {
    render(loaded, props) {
        let Component = loaded.DetailBelajar
        return <Component {...props}/>
    }
})

const DetailKuiz = Loadable(() => import("./sections"), undefined, {
    render(loaded, props) {
        let Component = loaded.DetailKuiz
        return <Component {...props}/>
    }
})

export default [
    {
        path: "/",
        exact: true,
        isAuthenticated: false,
        component: Login
    },
    {
        path: "/register",
        exact: true,
        isAuthenticated: false,
        component: Register
    },
    {
        path: "/forgot",
        exact: true,
        isAuthenticated: false,
        component: ForgotAccount
    },
    {
        path: "/home",
        exact: true,
        isAuthenticated: true,
        component: Home
    },
    {
        path: "/learn",
        exact: true,
        isAuthenticated: true,
        component: Belajar
    },
    {
        path: "/learn/:id",
        exact: true,
        isAuthenticated: true,
        component: DetailBelajar
    },
    {
        path: "/quiz",
        exact: true,
        isAuthenticated: true,
        component: Kuiz
    },
    {
        path: "/quiz/:id",
        exact: true,
        isAuthenticated: true,
        component: DetailKuiz
    },
    {
        path: "/hijaiyah",
        exact: true,
        isAuthenticated: true,
        component: hijaiyah
    },
    {
        path: "/belajar/:id",
        exact: true,
        isAuthenticated: true,
        component: hijaiyah
    },
    {
        path: "/bermain/:id",
        exact: true,
        isAuthenticated: true,
        component: hijaiyah
    },
    {
        path: "/quran",
        exact: true,
        isAuthenticated: true,
        component: quran
    },
    {
        path: "/quranRead/:id",
        exact: true,
        isAuthenticated: true,
        component: quran
    },
]