import React from "react";
import langIdn from 'languages/id'

class modalBelajar extends React.Component{
    render(){
        const {images,state,func,imgBoard} = this.props
        return(
                state.popupType === 'hijaiyah' ?
                 <div style={{ top:'31%' }}>
                     <img src={imgBoard.board} />
                     <div className="hg-0">
                         <div className="hijaiyah-detail">
                             <div style={{color: state.huruf.color}}>{state.huruf.huruf}</div>
                             <span style={{color:state.color,display:func.filterUrl('','none')}} >
                                 {state.huruf.huruf}{state.huruf.harakat}
                             </span>
                         </div>
                         <p>{state.huruf.name}</p>
                     </div>
                 </div> 
                 :
                 <div style={{ top:'9%' }}>
                     <div className="hg-0">
                         <img src={imgBoard.boardInfo} />
                         <div className="content-info">
                         {Array.isArray(langIdn.iqra.help[state.url.split('-')[0]]) ? 
                                 langIdn.iqra.help[state.url.split('-')[0]].map((e,indx)=>{
                                     return (
                                     <div className="help">
                                                <img src={images.helpImage[`${state.url.split('-')[0]}${indx}`]} width={indx === 0 ? "240" : "87"} />
                                                <p style={{ width: indx > 0 ? '145px' : '' }} >{e}</p>
                                            </div>
                                     )
                                 })
                                 :
                                 <div className="help">
                                            <img src={images.helpImage[`${state.url.split('-')[0]}`]} width="214" />
                                            <p>{langIdn.iqra.help[state.url.split('-')[0]]}</p>
                                        </div> 
                         }
                         </div>
                         <div className="button-ok">
                             <img onClick={_=>func.popupInfo('')} src={imgBoard.helpOk2} />
                         </div>
                     </div>
                 </div>
            )}
}

export default modalBelajar
