export default {
    "1": [
      {
        "sura": 1,
        "aya": 1,
        "word": 1,
        "ar": "بِسْمِ",
        "en": "In (the) name",
        "id": "dengan nama",
        "ms": "dengan nama",
        "ms_jawi": "dengan nama",
        "trans": "bismi"
      },
      {
        "sura": 1,
        "aya": 1,
        "word": 2,
        "ar": "اللَّهِ",
        "en": "(of) Allah,",
        "id": "Allah",
        "ms": "Allah",
        "ms_jawi": "Allah",
        "trans": "al-lahi"
      },
      {
        "sura": 1,
        "aya": 1,
        "word": 3,
        "ar": "الرَّحْمٰنِ",
        "en": "the Most Gracious,",
        "id": "Maha Pengasih",
        "ms": "Maha Pengasih",
        "ms_jawi": "Maha Pengasih",
        "trans": "ar-rahmaani"
      },
      {
        "sura": 1,
        "aya": 1,
        "word": 4,
        "ar": "الرَّحِيمِ",
        "en": "the Most Merciful.",
        "id": "Maha Penyayang",
        "ms": "Maha Penyayang",
        "ms_jawi": "Maha Penyayang",
        "trans": "ar-rahiymi"
      },
      {
        "sura": 1,
        "aya": 2,
        "word": 1,
        "ar": "الْحَمْدُ",
        "en": "All praises and thanks",
        "id": "pujian",
        "ms": "pujian",
        "ms_jawi": "pujian",
        "trans": "alhamdu"
      },
      {
        "sura": 1,
        "aya": 2,
        "word": 2,
        "ar": "لِلَّهِ",
        "en": "(be) to Allah,",
        "id": "bagi Allah",
        "ms": "bagi Allah",
        "ms_jawi": "bagi Allah",
        "trans": "lillahi"
      },
      {
        "sura": 1,
        "aya": 2,
        "word": 3,
        "ar": "رَبِّ",
        "en": "the Lord",
        "id": "Tuhan",
        "ms": "Tuhan",
        "ms_jawi": "Tuhan",
        "trans": "rabbi"
      },
      {
        "sura": 1,
        "aya": 2,
        "word": 4,
        "ar": "الْعٰلَمِينَ",
        "en": "of the universe",
        "id": "alam semesta",
        "ms": "alam semesta",
        "ms_jawi": "alam semesta",
        "trans": "alʿaalamiyna"
      },
      {
        "sura": 1,
        "aya": 3,
        "word": 1,
        "ar": "الرَّحْمٰنِ",
        "en": "The Most Gracious,",
        "id": "Maha Pengasih",
        "ms": "Maha Pengasih",
        "ms_jawi": "Maha Pengasih",
        "trans": "ar-rahmaani"
      },
      {
        "sura": 1,
        "aya": 3,
        "word": 2,
        "ar": "الرَّحِيمِ",
        "en": "the Most Merciful.",
        "id": "Maha Penyayang",
        "ms": "Maha Penyayang",
        "ms_jawi": "Maha Penyayang",
        "trans": "ar-rahiymi"
      },
      {
        "sura": 1,
        "aya": 4,
        "word": 1,
        "ar": "مٰلِكِ",
        "en": "(The) Master",
        "id": "Penguasa",
        "ms": "Penguasa",
        "ms_jawi": "Penguasa",
        "trans": "maaliki"
      },
      {
        "sura": 1,
        "aya": 4,
        "word": 2,
        "ar": "يَوْمِ",
        "en": "(of the) Day",
        "id": "hari",
        "ms": "hari",
        "ms_jawi": "hari",
        "trans": "yawmi"
      },
      {
        "sura": 1,
        "aya": 4,
        "word": 3,
        "ar": "الدِّينِ",
        "en": "(of the) Judgment.",
        "id": "pembalasan",
        "ms": "pembalasan",
        "ms_jawi": "pembalasan",
        "trans": "ad-diyni"
      },
      {
        "sura": 1,
        "aya": 5,
        "word": 1,
        "ar": "إِيَّاكَ",
        "en": "You Alone",
        "id": "hanya kepadaMu",
        "ms": "hanya kepadaMu",
        "ms_jawi": "hanya kepadaMu",
        "trans": "iyyaāka"
      },
      {
        "sura": 1,
        "aya": 5,
        "word": 2,
        "ar": "نَعْبُدُ",
        "en": "we worship,",
        "id": "kami menyembah",
        "ms": "kami menyembah",
        "ms_jawi": "kami menyembah",
        "trans": "naʿbudu"
      },
      {
        "sura": 1,
        "aya": 5,
        "word": 3,
        "ar": "وَإِيَّاكَ",
        "en": "and You Alone",
        "id": "dan hanya kepadaMu",
        "ms": "dan hanya kepadaMu",
        "ms_jawi": "dan hanya kepadaMu",
        "trans": "waiyyaāka"
      },
      {
        "sura": 1,
        "aya": 5,
        "word": 4,
        "ar": "نَسْتَعِينُ",
        "en": "we ask for help.",
        "id": "kami mohon pertolongan",
        "ms": "kami mohon pertolongan",
        "ms_jawi": "kami mohon pertolongan",
        "trans": "nastaʿiynu"
      },
      {
        "sura": 1,
        "aya": 6,
        "word": 1,
        "ar": "اهْدِنَا",
        "en": "Guide us",
        "id": "tunjukkan kami",
        "ms": "tunjukkan kami",
        "ms_jawi": "tunjukkan kami",
        "trans": "ahdinaā"
      },
      {
        "sura": 1,
        "aya": 6,
        "word": 2,
        "ar": "الصِّرٰطَ",
        "en": "(to) the path,",
        "id": "jalan",
        "ms": "jalan",
        "ms_jawi": "jalan",
        "trans": "aṣ-ṣiraaṭa"
      },
      {
        "sura": 1,
        "aya": 6,
        "word": 3,
        "ar": "الْمُسْتَقِيمَ",
        "en": "the straight.",
        "id": "lurus",
        "ms": "lurus",
        "ms_jawi": "lurus",
        "trans": "almustaqiyma"
      },
      {
        "sura": 1,
        "aya": 7,
        "word": 1,
        "ar": "صِرٰطَ",
        "en": "(The) path",
        "id": "jalan",
        "ms": "jalan",
        "ms_jawi": "jalan",
        "trans": "ṣiraaṭa"
      },
      {
        "sura": 1,
        "aya": 7,
        "word": 2,
        "ar": "الَّذِينَ",
        "en": "(of) those",
        "id": "orang-orang yang",
        "ms": "orang-orang yang",
        "ms_jawi": "orang-orang yang",
        "trans": "alladhiyna"
      },
      {
        "sura": 1,
        "aya": 7,
        "word": 3,
        "ar": "أَنْعَمْتَ",
        "en": "You have bestowed (Your) Favors",
        "id": "diberi nikmat",
        "ms": "diberi nikmat",
        "ms_jawi": "diberi nikmat",
        "trans": "anʿamta"
      },
      {
        "sura": 1,
        "aya": 7,
        "word": 4,
        "ar": "عَلَيْهِمْ",
        "en": "on them,",
        "id": "kepadanya",
        "ms": "kepadanya",
        "ms_jawi": "kepadanya",
        "trans": "ʿalayhim"
      },
      {
        "sura": 1,
        "aya": 7,
        "word": 5,
        "ar": "غَيْرِ",
        "en": "not (of)",
        "id": "bukan",
        "ms": "bukan",
        "ms_jawi": "bukan",
        "trans": "ghayri"
      },
      {
        "sura": 1,
        "aya": 7,
        "word": 6,
        "ar": "الْمَغْضُوبِ",
        "en": "those who earned (Your) wrath",
        "id": "orang yang dimurkai",
        "ms": "orang yang dimurkai",
        "ms_jawi": "orang yang dimurkai",
        "trans": "almaghḍuwbi"
      },
      {
        "sura": 1,
        "aya": 7,
        "word": 7,
        "ar": "عَلَيْهِمْ",
        "en": "on them",
        "id": "kepadanya",
        "ms": "kepadanya",
        "ms_jawi": "kepadanya",
        "trans": "ʿalayhim"
      },
      {
        "sura": 1,
        "aya": 7,
        "word": 8,
        "ar": "وَلَا",
        "en": "and not",
        "id": "dan tidak",
        "ms": "dan tidak",
        "ms_jawi": "dan tidak",
        "trans": "walaā"
      },
      {
        "sura": 1,
        "aya": 7,
        "word": 9,
        "ar": "الضَّآلِّينَ",
        "en": "(of) those who go astray.",
        "id": "orang-orang yang sesat",
        "ms": "orang-orang yang sesat",
        "ms_jawi": "orang-orang yang sesat",
        "trans": "aḍ-ḍaālliyna"
      }
    ],
    "94": [
      {
        "sura": 94,
        "aya": 1,
        "word": 1,
        "ar": "أَلَمْ نَشْرَحْ",
        "en": "Did We not expand",
        "id": "Bukankah Kami telah melapangkan",
        "ms": "Bukankah Kami telah melapangkan",
        "ms_jawi": "Bukankah Kami telah melapangkan",
        "trans": "alam nashrah"
      },
      {
        "sura": 94,
        "aya": 1,
        "word": 2,
        "ar": "لَكَ صَدْرَكَ",
        "en": "For you, your breast?",
        "id": "untukmu dadamu?",
        "ms": "untukmu dadamu?",
        "ms_jawi": "untukmu dadamu?",
        "trans": "laka ṣadraka"
      },
      {
        "sura": 94,
        "aya": 2,
        "word": 1,
        "ar": "وَوَضَعْنَا",
        "en": "And We removed",
        "id": "dan Kami letakkan/turunkan",
        "ms": "dan Kami letakkan/turunkan",
        "ms_jawi": "dan Kami letakkan/turunkan",
        "trans": "wawaḍaʿnaā"
      },
      {
        "sura": 94,
        "aya": 2,
        "word": 2,
        "ar": "عَنكَ وِزْرَكَ",
        "en": "from you your burden",
        "id": "dari kamu bebanmu",
        "ms": "dari kamu bebanmu",
        "ms_jawi": "dari kamu bebanmu",
        "trans": "ʿanka wizraka"
      },
      {
        "sura": 94,
        "aya": 3,
        "word": 1,
        "ar": "الَّذِىٓ",
        "en": "Which",
        "id": "yang",
        "ms": "yang",
        "ms_jawi": "yang",
        "trans": "alladhiā"
      },
      {
        "sura": 94,
        "aya": 3,
        "word": 2,
        "ar": "أَنقَضَ ظَهْرَكَ",
        "en": "weighed upon your back",
        "id": "memberatkan punggungmu",
        "ms": "memberatkan punggungmu",
        "ms_jawi": "memberatkan punggungmu",
        "trans": "anqaḍa ẓahraka"
      },
      {
        "sura": 94,
        "aya": 4,
        "word": 1,
        "ar": "وَرَفَعْنَا",
        "en": "And We raised high",
        "id": "dan Kami tinggikan",
        "ms": "dan Kami tinggikan",
        "ms_jawi": "dan Kami tinggikan",
        "trans": "warafaʿnaā"
      },
      {
        "sura": 94,
        "aya": 4,
        "word": 2,
        "ar": " لَكَ ذِكْرَكَ",
        "en": "for you your reputation",
        "id": "bagimu sebutanmu",
        "ms": "bagimu sebutanmu",
        "ms_jawi": "bagimu sebutanmu",
        "trans": "laka dhikraka"
      },
      {
        "sura": 94,
        "aya": 5,
        "word": 1,
        "ar": "فَإِنَّ",
        "en": "So indeed,",
        "id": "maka sesungguhnya",
        "ms": "maka sesungguhnya",
        "ms_jawi": "maka sesungguhnya",
        "trans": "fainna"
      },
      {
        "sura": 94,
        "aya": 5,
        "word": 2,
        "ar": "مَعَ الْعُسْرِ",
        "en": "with the hardship",
        "id": "beserta kesulitan",
        "ms": "beserta kesulitan",
        "ms_jawi": "beserta kesulitan",
        "trans": "maʿalʿusri"
      },
      {
        "sura": 94,
        "aya": 5,
        "word": 3,
        "ar": "يُسْرًا",
        "en": "(is) ease.",
        "id": "ada kemudahan",
        "ms": "ada kemudahan",
        "ms_jawi": "ada kemudahan",
        "trans": "yusranā"
      },
      {
        "sura": 94,
        "aya": 6,
        "word": 1,
        "ar": "إِنَّ",
        "en": "Indeed,",
        "id": "sesungguhnya",
        "ms": "sesungguhnya",
        "ms_jawi": "sesungguhnya",
        "trans": "inna"
      },
      {
        "sura": 94,
        "aya": 6,
        "word": 2,
        "ar": "مَعَ الْعُسْرِ",
        "en": "with the hardship",
        "id": "beserta kesulitan",
        "ms": "beserta kesulitan",
        "ms_jawi": "beserta kesulitan",
        "trans": "maʿalʿusri"
      },
      {
        "sura": 94,
        "aya": 6,
        "word": 3,
        "ar": "يُسْرًا",
        "en": "(is) ease.",
        "id": "ada kemudahan",
        "ms": "ada kemudahan",
        "ms_jawi": "ada kemudahan",
        "trans": "yusranā"
      },
      {
        "sura": 94,
        "aya": 7,
        "word": 1,
        "ar": "فَإِذَا",
        "en": "So when",
        "id": "maka apabila",
        "ms": "maka apabila",
        "ms_jawi": "maka apabila",
        "trans": "faidhaā"
      },
      {
        "sura": 94,
        "aya": 7,
        "word": 2,
        "ar": "فَرَغْتَ فَانصَبْ",
        "en": "you have finished, then labor hard.",
        "id": "kamu telah selesai, maka kerja keraslah kamu",
        "ms": "kamu telah selesai, maka kerja keraslah kamu",
        "ms_jawi": "kamu telah selesai, maka kerja keraslah kamu",
        "trans": "faraghta faanṣab"
      },
      {
        "sura": 94,
        "aya": 8,
        "word": 1,
        "ar": "وَإِلٰى رَ",
        "en": "And to",
        "id": "dan hanya",
        "ms": "dan hanya",
        "ms_jawi": "dan hanya",
        "trans": "wailaāa ra"
      },
      {
        "sura": 94,
        "aya": 8,
        "word": 2,
        "ar": "بِّكَ",
        "en": "your Lord",
        "id": "Tuhanmu",
        "ms": "Tuhanmu",
        "ms_jawi": "Tuhanmu",
        "trans": "bika"
      },
      {
        "sura": 94,
        "aya": 8,
        "word": 3,
        "ar": "فَارْغَب",
        "en": "turn your attention.",
        "id": "maka kamu berharap",
        "ms": "maka kamu berharap",
        "ms_jawi": "maka kamu berharap",
        "trans": "faarghab"
      }
    ],
    "95": [
      {
        "sura": 95,
        "aya": 1,
        "word": 1,
        "ar": "وَالتِّينِ",
        "en": "By the fig,",
        "id": "demi tin",
        "ms": "demi tin",
        "ms_jawi": "demi tin",
        "trans": "waat-tiyni"
      },
      {
        "sura": 95,
        "aya": 1,
        "word": 2,
        "ar": "وَالزَّيْتُونِ",
        "en": "and the olive,",
        "id": "dan zaitun",
        "ms": "dan zaitun",
        "ms_jawi": "dan zaitun",
        "trans": "waaz-zaytuwni"
      },
      {
        "sura": 95,
        "aya": 2,
        "word": 1,
        "ar": "وَطُورِ سِينِينَ",
        "en": "And (the) Mount Sinai,",
        "id": "dan bukit Sinai",
        "ms": "dan bukit Sinai",
        "ms_jawi": "dan bukit Sinai",
        "trans": "waṭuwri siyniyna"
      },
      {
        "sura": 95,
        "aya": 3,
        "word": 1,
        "ar": "وَهٰذَا",
        "en": "And this",
        "id": "dan ini",
        "ms": "dan ini",
        "ms_jawi": "dan ini",
        "trans": "wahaadhaā"
      },
      {
        "sura": 95,
        "aya": 3,
        "word": 2,
        "ar": "الْبَلَدِ الْأَمِينِ",
        "en": "secure city [Makkah],",
        "id": "kota (Mekah) ini yang aman,",
        "ms": "kota (Mekah) ini yang aman,",
        "ms_jawi": "kota (Mekah) ini yang aman,",
        "trans": "albaladi alamiyni"
      },
      {
        "sura": 95,
        "aya": 4,
        "word": 1,
        "ar": "لَقَدْ خَلَقْنَا الْإِنسٰنَ",
        "en": "We have certainly created man",
        "id": "sesungguhnya kami telah menciptakan manusia",
        "ms": "sesungguhnya kami telah menciptakan manusia",
        "ms_jawi": "sesungguhnya kami telah menciptakan manusia",
        "trans": "laqad khalaqnaāl insaana"
      },
      {
        "sura": 95,
        "aya": 4,
        "word": 2,
        "ar": "فِىٓ أَحْسَنِ تَقْوِيمٍ",
        "en": "In the best of stature.",
        "id": "Dalam bentuk sebaik-baiknya.",
        "ms": "Dalam bentuk sebaik-baiknya.",
        "ms_jawi": "Dalam bentuk sebaik-baiknya.",
        "trans": "fiā ahsani taqwiymin"
      },
      {
        "sura": 95,
        "aya": 5,
        "word": 1,
        "ar": "ثُمَّ رَدَدْنٰهُ",
        "en": "Then We returned him",
        "id": "kemudian Kami kembalikan",
        "ms": "kemudian Kami kembalikan",
        "ms_jawi": "kemudian Kami kembalikan",
        "trans": "thumma radadnaahu"
      },
      {
        "sura": 95,
        "aya": 5,
        "word": 2,
        "ar": "أَسْفَلَ سٰفِلِينَ",
        "en": "to the lowest of the low,",
        "id": "ke tempat yang serendah-rendahnya",
        "ms": "ke tempat yang serendah-rendahnya",
        "ms_jawi": "ke tempat yang serendah-rendahnya",
        "trans": "asfala saafiliyna"
      },
      {
        "sura": 95,
        "aya": 6,
        "word": 1,
        "ar": "إِلَّا الَّذِينَ",
        "en": "Except those who",
        "id": "kecuali orang-orang yang",
        "ms": "kecuali orang-orang yang",
        "ms_jawi": "kecuali orang-orang yang",
        "trans": "illaā alladhiyna"
      },
      {
        "sura": 95,
        "aya": 6,
        "word": 2,
        "ar": "ءَامَنُوا وَعَمِلُوا الصّٰلِحٰتِ",
        "en": "believe and do righteous deeds,",
        "id": "beriman dan mereka berbuat/beramal kebajikan/saleh",
        "ms": "beriman dan mereka berbuat/beramal kebajikan/saleh",
        "ms_jawi": "beriman dan mereka berbuat/beramal kebajikan/saleh",
        "trans": "aāmanuwā waʿamiluwā aṣ-ṣaalihaati"
      },
      {
        "sura": 95,
        "aya": 6,
        "word": 3,
        "ar": "فَلَهُمْ أَجْرٌ",
        "en": "then for them (is a) reward",
        "id": "maka bagi mereka pahala",
        "ms": "maka bagi mereka pahala",
        "ms_jawi": "maka bagi mereka pahala",
        "trans": "falahum ajrun"
      },
      {
        "sura": 95,
        "aya": 6,
        "word": 4,
        "ar": "غَيْرُ مَمْنُونٍ",
        "en": "never ending",
        "id": "bukan/tidak terputus",
        "ms": "bukan/tidak terputus",
        "ms_jawi": "bukan/tidak terputus",
        "trans": "ghayru mamnuwnin"
      },
      {
        "sura": 95,
        "aya": 7,
        "word": 1,
        "ar": "فَمَا يُكَذِّبُكَ",
        "en": "Then what causes you to deny",
        "id": "maka apakah mendustakanmu",
        "ms": "maka apakah mendustakanmu",
        "ms_jawi": "maka apakah mendustakanmu",
        "trans": "famaā yukadhhibuka"
      },
      {
        "sura": 95,
        "aya": 7,
        "word": 2,
        "ar": "بَعْدُ بِالدِّينِ",
        "en": "after (this) the judgment?",
        "id": "sesudah hari pembalasan/agam",
        "ms": "sesudah hari pembalasan/agam",
        "ms_jawi": "sesudah hari pembalasan/agam",
        "trans": "baʿdu biad-diyni"
      },
      {
        "sura": 95,
        "aya": 8,
        "word": 1,
        "ar": "أَلَيْسَ اللَّهُ",
        "en": "Is not Allah",
        "id": "bukankah Allah",
        "ms": "bukankah Allah",
        "ms_jawi": "bukankah Allah",
        "trans": "alaysa al-lahu"
      },
      {
        "sura": 95,
        "aya": 8,
        "word": 2,
        "ar": "بِأَحْكَمِ الْحٰكِمِينَ",
        "en": "(the) Most Just of Judges?",
        "id": "Hakim yang seadil-adilnya?",
        "ms": "Hakim yang seadil-adilnya?",
        "ms_jawi": "Hakim yang seadil-adilnya?",
        "trans": "biahkami alhaakimiyna"
      }
    ],
    "97": [
      {
        "sura": 97,
        "aya": 1,
        "word": 1,
        "ar": "إِنَّآ أَنزَلْنٰهُ",
        "en": "Indeed, We revealed it",
        "id": "sesungguhnya Kami telah menurunkannya",
        "ms": "sesungguhnya Kami telah menurunkannya",
        "ms_jawi": "sesungguhnya Kami telah menurunkannya",
        "trans": "innaā anzalnaahu"
      },
      {
        "sura": 97,
        "aya": 1,
        "word": 2,
        "ar": "فِى لَيْلَةِ الْقَدْرِ",
        "en": "in the Night of Power.",
        "id": "pada malam kemuliaan",
        "ms": "pada malam kemuliaan",
        "ms_jawi": "pada malam kemuliaan",
        "trans": "fiā laylatil qadri"
      },
      {
        "sura": 97,
        "aya": 2,
        "word": 1,
        "ar": "وَمَآ أَدْرٰىكَ",
        "en": "And what can make you know",
        "id": "dan apakah kamu tahu",
        "ms": "dan apakah kamu tahu",
        "ms_jawi": "dan apakah kamu tahu",
        "trans": "wamaā adraāaka"
      },
      {
        "sura": 97,
        "aya": 2,
        "word": 2,
        "ar": "مَا لَيْلَةُ الْقَدْرِ",
        "en": "what the Night of Power is?",
        "id": "apa malam kemuliaan",
        "ms": "apa malam kemuliaan",
        "ms_jawi": "apa malam kemuliaan",
        "trans": "maā laylatul qadri"
      },
      {
        "sura": 97,
        "aya": 3,
        "word": 1,
        "ar": "لَيْلَةُ الْقَدْرِ",
        "en": "The Night of Power",
        "id": "malam kemuliaan",
        "ms": "malam kemuliaan",
        "ms_jawi": "malam kemuliaan",
        "trans": "laylatul qadri"
      },
      {
        "sura": 97,
        "aya": 3,
        "word": 2,
        "ar": "خَيْرٌ مِّنْ أَلْفِ شَهْرٍ",
        "en": "(is) better than a thousand month(s).",
        "id": "lebih baik dari seribu bulan",
        "ms": "lebih baik dari seribu bulan",
        "ms_jawi": "lebih baik dari seribu bulan",
        "trans": "khayrunm min alfi shahrin"
      },
      {
        "sura": 97,
        "aya": 4,
        "word": 1,
        "ar": "تَنَزَّلُ الْمَلٰٓئِكَةُ",
        "en": "Descend the Angels",
        "id": "para malaikat turun",
        "ms": "para malaikat turun",
        "ms_jawi": "para malaikat turun",
        "trans": "tanazzalu almalaaikatu"
      },
      {
        "sura": 97,
        "aya": 4,
        "word": 2,
        "ar": "وَالرُّوحُ فِيهَا",
        "en": "and the Spirit therein,",
        "id": "dan Roh/Jibril padanya/pada malam",
        "ms": "dan Roh/Jibril padanya/pada malam",
        "ms_jawi": "dan Roh/Jibril padanya/pada malam",
        "trans": "waar-ruwhu fiyhaā"
      },
      {
        "sura": 97,
        "aya": 4,
        "word": 3,
        "ar": "بِإِذْنِ رَبِّهِم مِّن كُلِّ أَمْرٍ",
        "en": "by the permission of their Lord, for every affair,",
        "id": "dengan izin Tuhannya dari segala urusan",
        "ms": "dengan izin Tuhannya dari segala urusan",
        "ms_jawi": "dengan izin Tuhannya dari segala urusan",
        "trans": "biidhni rabbihim mmin kulli amrin"
      },
      {
        "sura": 97,
        "aya": 5,
        "word": 1,
        "ar": "سَلٰمٌ هِىَ حَتّٰى",
        "en": "Peace it is until",
        "id": "Sejahteralah (malam itu) sampai ",
        "ms": "Sejahteralah (malam itu) sampai ",
        "ms_jawi": "Sejahteralah (malam itu) sampai ",
        "trans": "salaamun hiāa hattaāa"
      },
      {
        "sura": 97,
        "aya": 5,
        "word": 2,
        "ar": "مَطْلَعِ الْفَجْرِ",
        "en": "the emergence of dawn.",
        "id": "terbit fajar.",
        "ms": "terbit fajar.",
        "ms_jawi": "terbit fajar.",
        "trans": "maṭlaʿil fajri"
      }
    ],
    "102": [
      {
        "sura": 102,
        "aya": 1,
        "word": 1,
        "ar": "أَلْهٰىكُمُ التَّكَاثُرُ",
        "en": "Competition in [worldly] increase diverts you",
        "id": "Bermegah-megahan telah melalaikan kamu,",
        "ms": "Bermegah-megahan telah melalaikan kamu,",
        "ms_jawi": "Bermegah-megahan telah melalaikan kamu,",
        "trans": "alhaāakumut-takaāthuru"
      },
      {
        "sura": 102,
        "aya": 2,
        "word": 1,
        "ar": "حَتّٰى زُرْتُمُ الْمَقَابِرَ",
        "en": "Until you visit the graveyards.",
        "id": "sampai kamu masuk ke dalam kubur.",
        "ms": "sampai kamu masuk ke dalam kubur.",
        "ms_jawi": "sampai kamu masuk ke dalam kubur.",
        "trans": "hattaāa zurtumul maqaābira"
      },
      {
        "sura": 102,
        "aya": 3,
        "word": 1,
        "ar": "كَلَّا سَوْفَ",
        "en": "No! Soon",
        "id": "sekali-kali tidak kelak",
        "ms": "sekali-kali tidak kelak",
        "ms_jawi": "sekali-kali tidak kelak",
        "trans": "kallaā sawfa"
      },
      {
        "sura": 102,
        "aya": 3,
        "word": 2,
        "ar": "تَعْلَمُونَ",
        "en": "you will know.",
        "id": "(kalian) mengetahui",
        "ms": "(kalian) mengetahui",
        "ms_jawi": "(kalian) mengetahui",
        "trans": "taʿlamuwna"
      },
      {
        "sura": 102,
        "aya": 4,
        "word": 1,
        "ar": "ثُمَّ كَلَّا",
        "en": "Then, no!",
        "id": "kemudian sekali-kali tidak!",
        "ms": "kemudian sekali-kali tidak!",
        "ms_jawi": "kemudian sekali-kali tidak!",
        "trans": "thumma kallaā"
      },
      {
        "sura": 102,
        "aya": 4,
        "word": 2,
        "ar": "سَوْفَ تَعْلَمُونَ",
        "en": "Soon you will know.",
        "id": "kelak (kalian) akan mengetahui",
        "ms": "kelak (kalian) akan mengetahui",
        "ms_jawi": "kelak (kalian) akan mengetahui",
        "trans": "sawfa taʿlamuwna"
      },
      {
        "sura": 102,
        "aya": 5,
        "word": 1,
        "ar": "كَلَّا لَوْ",
        "en": "No! If",
        "id": "sekali-kali tidak! Sekiranya",
        "ms": "sekali-kali tidak! Sekiranya",
        "ms_jawi": "sekali-kali tidak! Sekiranya",
        "trans": "kallaā law"
      },
      {
        "sura": 102,
        "aya": 5,
        "word": 2,
        "ar": "تَعْلَمُونَ عِلْمَ الْيَقِينِ",
        "en": "you know with knowledge of certainty",
        "id": "(kalian) mengetahui dengan pasti",
        "ms": "(kalian) mengetahui dengan pasti",
        "ms_jawi": "(kalian) mengetahui dengan pasti",
        "trans": "taʿlamuwna ʿilma alyaqiyni"
      },
      {
        "sura": 102,
        "aya": 6,
        "word": 1,
        "ar": "لَتَرَوُنَّ الْجَحِيمَ",
        "en": "Surely you will see the Hellfire.",
        "id": "sungguh kamu akan melihat neraka Jahim",
        "ms": "sungguh kamu akan melihat neraka Jahim",
        "ms_jawi": "sungguh kamu akan melihat neraka Jahim",
        "trans": "latarawunnal jahiyma"
      },
      {
        "sura": 102,
        "aya": 7,
        "word": 1,
        "ar": "ثُمَّ لَتَرَوُنَّهَا",
        "en": "Then you will surely see it ",
        "id": "kemudian kamu benar-benar akan melihatnya",
        "ms": "kemudian kamu benar-benar akan melihatnya",
        "ms_jawi": "kemudian kamu benar-benar akan melihatnya",
        "trans": "thumma latarawunnahaā"
      },
      {
        "sura": 102,
        "aya": 7,
        "word": 2,
        "ar": "عَيْنَ الْيَقِينِ",
        "en": "with the eye of certainty.",
        "id": "dengan mata kepala sendiri",
        "ms": "dengan mata kepala sendiri",
        "ms_jawi": "dengan mata kepala sendiri",
        "trans": "ʿayna alyaqiyni"
      },
      {
        "sura": 102,
        "aya": 8,
        "word": 1,
        "ar": "ثُمَّ لَتُسْئَلُنَّ",
        "en": "Then you will surely be asked",
        "id": "kemudian kamu benar-benar akan ditanya",
        "ms": "kemudian kamu benar-benar akan ditanya",
        "ms_jawi": "kemudian kamu benar-benar akan ditanya",
        "trans": "thumma latusalunna"
      },
      {
        "sura": 102,
        "aya": 8,
        "word": 2,
        "ar": "يَوْمَئِذٍ عَنِ النَّعِيمِ",
        "en": "that Day about pleasure.",
        "id": "pada hari itu tentang kenikmatan (yang megah di dunia itu).",
        "ms": "pada hari itu tentang kenikmatan (yang megah di dunia itu).",
        "ms_jawi": "pada hari itu tentang kenikmatan (yang megah di dunia itu).",
        "trans": "yawmaidhin ʿanin naʿiymi"
      }
    ],
    "103": [
      {
        "sura": 103,
        "aya": 1,
        "word": 1,
        "ar": "وَالْعَصْرِ",
        "en": "By the time,",
        "id": "demi waktu",
        "ms": "demi waktu",
        "ms_jawi": "demi waktu",
        "trans": "waalʿaṣri"
      },
      {
        "sura": 103,
        "aya": 2,
        "word": 1,
        "ar": "إِنَّ الْإِنسٰنَ",
        "en": "Indeed, mankind",
        "id": "sungguh, manusia",
        "ms": "sungguh, manusia",
        "ms_jawi": "sungguh, manusia",
        "trans": "innalinsaana"
      },
      {
        "sura": 103,
        "aya": 2,
        "word": 2,
        "ar": "لَفِى خُسْرٍ",
        "en": "is in loss,",
        "id": "berada dalam kerugian,",
        "ms": "berada dalam kerugian,",
        "ms_jawi": "berada dalam kerugian,",
        "trans": "lafiā khusrin"
      },
      {
        "sura": 103,
        "aya": 3,
        "word": 1,
        "ar": "إِلَّا الَّذِينَ",
        "en": "Except for those who",
        "id": "kecuali orang-orang yang",
        "ms": "kecuali orang-orang yang",
        "ms_jawi": "kecuali orang-orang yang",
        "trans": "illaālladhiyna"
      },
      {
        "sura": 103,
        "aya": 3,
        "word": 2,
        "ar": "ءَامَنُوا وَعَمِلُوا الصّٰلِحٰتِ",
        "en": "believe and done righteous deeds",
        "id": "beriman dan mereka berbuat/beramal kebajikan/sholeh",
        "ms": "beriman dan mereka berbuat/beramal kebajikan/sholeh",
        "ms_jawi": "beriman dan mereka berbuat/beramal kebajikan/sholeh",
        "trans": "aāmanuwā waʿamiluwās ṣaalihaati"
      },
      {
        "sura": 103,
        "aya": 3,
        "word": 3,
        "ar": "وَتَوَاصَوْا بِالْحَقِّ",
        "en": "and advised each other to truth",
        "id": "dan mereka saling berwasiat/menasehati dengan kebenaran",
        "ms": "dan mereka saling berwasiat/menasehati dengan kebenaran",
        "ms_jawi": "dan mereka saling berwasiat/menasehati dengan kebenaran",
        "trans": "watawaāṣawā bialhaqqi"
      },
      {
        "sura": 103,
        "aya": 3,
        "word": 4,
        "ar": "وَتَوَاصَوْا بِالصَّبْرِ",
        "en": "and advised each other to patience.",
        "id": "dan saling menasihati untuk kesabaran.",
        "ms": "dan saling menasihati untuk kesabaran.",
        "ms_jawi": "dan saling menasihati untuk kesabaran.",
        "trans": "watawaāṣawā biaṣ-ṣabri"
      }
    ],
    "105": [
      {
        "sura": 105,
        "aya": 1,
        "word": 1,
        "ar": "أَلَمْ تَرَ كَيْفَ",
        "en": "Have you not considered, [O Muhammad], how ",
        "id": "Tidakkah engkau (Muhammad) perhatikan bagaimana",
        "ms": "Tidakkah engkau (Muhammad) perhatikan bagaimana",
        "ms_jawi": "Tidakkah engkau (Muhammad) perhatikan bagaimana",
        "trans": "alam tara kayfa"
      },
      {
        "sura": 105,
        "aya": 1,
        "word": 2,
        "ar": "فَعَلَ رَبُّكَ بِأَصْحٰبِ الْفِيلِ",
        "en": "your Lord dealt with the companions of the elephant?",
        "id": "Tuhanmu telah bertindak terhadap pasukan bergajah?",
        "ms": "Tuhanmu telah bertindak terhadap pasukan bergajah?",
        "ms_jawi": "Tuhanmu telah bertindak terhadap pasukan bergajah?",
        "trans": "faʿala rabbuka biaṣhaabi alfiyli"
      },
      {
        "sura": 105,
        "aya": 2,
        "word": 1,
        "ar": "أَلَمْ يَجْعَلْ",
        "en": "Did He not make",
        "id": "apakah tidak Dia jadikan",
        "ms": "apakah tidak Dia jadikan",
        "ms_jawi": "apakah tidak Dia jadikan",
        "trans": "alam yajʿal"
      },
      {
        "sura": 105,
        "aya": 2,
        "word": 2,
        "ar": "كَيْدَهُمْ فِى تَضْلِيلٍ",
        "en": "their plan into misguidance?",
        "id": "tipu daya mereka itu sia-sia?",
        "ms": "tipu daya mereka itu sia-sia?",
        "ms_jawi": "tipu daya mereka itu sia-sia?",
        "trans": "kaydahum fiā taḍliylin"
      },
      {
        "sura": 105,
        "aya": 3,
        "word": 1,
        "ar": "وَأَرْسَلَ عَلَيْهِمْ",
        "en": "And He sent against them",
        "id": "dan Dia mengirimkan kepada mereka",
        "ms": "dan Dia mengirimkan kepada mereka",
        "ms_jawi": "dan Dia mengirimkan kepada mereka",
        "trans": "waarsala ʿalayhim"
      },
      {
        "sura": 105,
        "aya": 3,
        "word": 2,
        "ar": "طَيْرًا أَبَابِيلَ",
        "en": "birds in flocks,",
        "id": "burung yang berbondong-bondong,",
        "ms": "burung yang berbondong-bondong,",
        "ms_jawi": "burung yang berbondong-bondong,",
        "trans": "ṭayranā abaābiyla"
      },
      {
        "sura": 105,
        "aya": 4,
        "word": 1,
        "ar": "تَرْمِيهِم بِحِجَارَةٍ مِّن سِجِّيلٍ",
        "en": "Striking them with stones of hard clay,",
        "id": "yang melempari mereka dengan batu dari tanah liat yang dibakar,",
        "ms": "yang melempari mereka dengan batu dari tanah liat yang dibakar,",
        "ms_jawi": "yang melempari mereka dengan batu dari tanah liat yang dibakar,",
        "trans": "tarmiyhim bihijaāratin mmin sijjiylin"
      },
      {
        "sura": 105,
        "aya": 5,
        "word": 1,
        "ar": "فَجَعَلَهُمْ",
        "en": "Then He made them",
        "id": "lalu Dia menjadikan mereka",
        "ms": "lalu Dia menjadikan mereka",
        "ms_jawi": "lalu Dia menjadikan mereka",
        "trans": "fajaʿalahum"
      },
      {
        "sura": 105,
        "aya": 5,
        "word": 2,
        "ar": "كَعَصْفٍ مَّأْكُولٍۢ",
        "en": "like eaten straw.",
        "id": "seperti daun-daun yang dimakan (ulat).",
        "ms": "seperti daun-daun yang dimakan (ulat).",
        "ms_jawi": "seperti daun-daun yang dimakan (ulat).",
        "trans": "kaʿaṣfinm makuwlin"
      }
    ],
    "106": [
      {
        "sura": 106,
        "aya": 1,
        "word": 1,
        "ar": "لِإِيلٰفِ",
        "en": "For (the) familiarity",
        "id": "karena kebiasaan",
        "ms": "karena kebiasaan",
        "ms_jawi": "karena kebiasaan",
        "trans": "liiylaafi"
      },
      {
        "sura": 106,
        "aya": 1,
        "word": 2,
        "ar": "قُرَيْشٍ",
        "en": "(of the) Quraish,",
        "id": "orang-orang Qurais",
        "ms": "orang-orang Qurais",
        "ms_jawi": "orang-orang Qurais",
        "trans": "qurayshin"
      },
      {
        "sura": 106,
        "aya": 2,
        "word": 1,
        "ar": "إِۦلٰفِهِمْ",
        "en": "Their familiarity",
        "id": "kebiasaan mereka",
        "ms": "kebiasaan mereka",
        "ms_jawi": "kebiasaan mereka",
        "trans": "ilaafihim"
      },
      {
        "sura": 106,
        "aya": 2,
        "word": 2,
        "ar": "رِحْلَةَ الشِّتَآءِ وَالصَّيْفِ",
        "en": "[in] the caravan of winter and summer ",
        "id": "bepergian pada musim dingin dan musim panas.",
        "ms": "bepergian pada musim dingin dan musim panas.",
        "ms_jawi": "bepergian pada musim dingin dan musim panas.",
        "trans": "rihlata alshhitaāi waaṣ-ṣayfi"
      },
      {
        "sura": 106,
        "aya": 3,
        "word": 1,
        "ar": "فَلْيَعْبُدُوا",
        "en": "So let them worship",
        "id": "maka hendaklah mereka menyembah",
        "ms": "maka hendaklah mereka menyembah",
        "ms_jawi": "maka hendaklah mereka menyembah",
        "trans": "falyaʿbuduwā" 
      },
      {
        "sura": 106,
        "aya": 3,
        "word": 2,
        "ar": "رَبَّ هٰذَا الْبَيْتِ",
        "en": "the Lord of this House,",
        "id": "Tuhan (pemilik) rumah ini (Ka‘bah),",
        "ms": "Tuhan (pemilik) rumah ini (Ka‘bah),",
        "ms_jawi": "Tuhan (pemilik) rumah ini (Ka‘bah),",
        "trans": ""
      },
      {
        "sura": 106,
        "aya": 4,
        "word": 1,
        "ar": "الَّذِىٓ أَطْعَمَهُم مِّن جُوعٍ",
        "en": "Who has fed them, [saving them] from hunger",
        "id": "yang telah memberi makanan kepada mereka untuk menghilangkan lapar",
        "ms": "yang telah memberi makanan kepada mereka untuk menghilangkan lapar",
        "ms_jawi": "yang telah memberi makanan kepada mereka untuk menghilangkan lapar",
        "trans": "alladhiā aṭʿamahum min juwʿin"
      },
      {
        "sura": 106,
        "aya": 4,
        "word": 2,
        "ar": "وَءَامَنَهُم مِّنْ خَوْفٍۢ",
        "en": "and made them safe, [saving them] from fear.",
        "id": "dan mengamankan mereka dari rasa ketakutan.",
        "ms": "dan mengamankan mereka dari rasa ketakutan.",
        "ms_jawi": "dan mengamankan mereka dari rasa ketakutan.",
        "trans": "waaāmanahum min khawfin"
      }
    ],
    "107": [
      {
        "sura": 107,
        "aya": 1,
        "word": 1,
        "ar": "أَرَءَيْتَ الَّذِى",
        "en": "Have you seen the one who",
        "id": "Tahukah kamu (orang) yang",
        "ms": "Tahukah kamu (orang) yang",
        "ms_jawi": "Tahukah kamu (orang) yang",
        "trans": "araaytal ladhiā"
      },
      {
        "sura": 107,
        "aya": 1,
        "word": 2,
        "ar": "يُكَذِّبُ بِالدِّينِ",
        "en": "denies the Recompense?",
        "id": "mendustakan agama?",
        "ms": "mendustakan agama?",
        "ms_jawi": "mendustakan agama?",
        "trans": "yukadhhibu biad-diyni"
      },
      {
        "sura": 107,
        "aya": 2,
        "word": 1,
        "ar": "فَذٰلِكَ الَّذِى",
        "en": "For that is the one who",
        "id": "Maka itulah orang yang",
        "ms": "Maka itulah orang yang",
        "ms_jawi": "Maka itulah orang yang",
        "trans": "fadhaalikal ladhiā"
      },
      {
        "sura": 107,
        "aya": 2,
        "word": 2,
        "ar": "يَدُعُّ الْيَتِيمَ",
        "en": "drives away the orphan",
        "id": "menghardik anak yatim,",
        "ms": "menghardik anak yatim,",
        "ms_jawi": "menghardik anak yatim,",
        "trans": "yaduʿʿul yatiyma"
      },
      {
        "sura": 107,
        "aya": 3,
        "word": 1,
        "ar": "وَلَا يَحُضُّ",
        "en": "And does not encourage",
        "id": "dan tidak mendorong",
        "ms": "dan tidak mendorong",
        "ms_jawi": "dan tidak mendorong",
        "trans": "walaā yahuḍḍu"
      },
      {
        "sura": 107,
        "aya": 3,
        "word": 2,
        "ar": "عَلٰى طَعَامِ الْمِسْكِينِ",
        "en": "the feeding of the poor.",
        "id": "memberi makan orang miskin.",
        "ms": "memberi makan orang miskin.",
        "ms_jawi": "memberi makan orang miskin.",
        "trans": "ʿalaāa ṭaʿaāmil miskiyni"
      },
      {
        "sura": 107,
        "aya": 4,
        "word": 1,
        "ar": "فَوَيْلٌ لِّلْمُصَلِّينَ",
        "en": "So woe to those who pray",
        "id": "Maka celakalah orang yang salat,",
        "ms": "Maka celakalah orang yang salat,",
        "ms_jawi": "Maka celakalah orang yang salat,",
        "trans": "fawaylun llilmuṣalliyna"
      },
      {
        "sura": 107,
        "aya": 5,
        "word": 1,
        "ar": "الَّذِينَ هُمْ",
        "en": "Those who",
        "id": "orang-orang yang",
        "ms": "orang-orang yang",
        "ms_jawi": "orang-orang yang",
        "trans": "alladhiyna hum"
      },
      {
        "sura": 107,
        "aya": 5,
        "word": 2,
        "ar": "عَن صَلَاتِهِمْ سَاهُونَ",
        "en": "heedless of their prayer ",
        "id": "lalai terhadap salatnya,",
        "ms": "lalai terhadap salatnya,",
        "ms_jawi": "lalai terhadap salatnya,",
        "trans": "ʿan ṣalaātihim saāhuwna"
      },
      {
        "sura": 107,
        "aya": 6,
        "word": 1,
        "ar": "الَّذِينَ هُمْ",
        "en": "Those who",
        "id": "orang-orang yang",
        "ms": "orang-orang yang",
        "ms_jawi": "orang-orang yang",
        "trans": "alladhiyna hum"
      },
      {
        "sura": 107,
        "aya": 6,
        "word": 2,
        "ar": "يُرَآءُونَ",
        "en": "make show [of their deeds]",
        "id": "berbuat ria",
        "ms": "berbuat ria",
        "ms_jawi": "berbuat ria",
        "trans": "yuraāuwna"
      },
      {
        "sura": 107,
        "aya": 7,
        "word": 1,
        "ar": "وَيَمْنَعُونَ الْمَاعُونَ",
        "en": "And withhold [simple] assistance.",
        "id": "dan enggan (memberikan) bantuan.",
        "ms": "dan enggan (memberikan) bantuan.",
        "ms_jawi": "dan enggan (memberikan) bantuan.",
        "trans": "wayamnaʿuwnal maāʿuwna"
      }
    ],
    "108": [
      {
        "sura": 108,
        "aya": 1,
        "word": 1,
        "ar": "إِنَّآ",
        "en": "Indeed,",
        "id": "sesungguhnya",
        "ms": "sesungguhnya",
        "ms_jawi": "sesungguhnya",
        "trans": "innaā"
      },
      {
        "sura": 108,
        "aya": 1,
        "word": 2,
        "ar": "أَعْطَيْنٰكَ الْكَوْثَرَ",
        "en": "We have granted you, [O Muhammad], al-Kawthar.",
        "id": "Kami telah memberimu (Muhammad) nikmat yang banyak.",
        "ms": "Kami telah memberimu (Muhammad) nikmat yang banyak.",
        "ms_jawi": "Kami telah memberimu (Muhammad) nikmat yang banyak.",
        "trans": "aʿṭaynaaka alkawthara"
      },
      {
        "sura": 108,
        "aya": 2,
        "word": 1,
        "ar": "فَصَلِّ لِرَبِّكَ وَانْحَرْ",
        "en": "So pray to your Lord and sacrifice [to Him alone].",
        "id": "Maka laksanakanlah salat karena Tuhanmu, dan berkurbanlah",
        "ms": "Maka laksanakanlah salat karena Tuhanmu, dan berkurbanlah",
        "ms_jawi": "Maka laksanakanlah salat karena Tuhanmu, dan berkurbanlah",
        "trans": "faṣalli lirabbika waanhar"
      },
      {
        "sura": 108,
        "aya": 3,
        "word": 1,
        "ar": "إِنَّ شَانِئَكَ",
        "en": "Indeed, your enemy",
        "id": "Sungguh, orang-orang yang membencimu",
        "ms": "Sungguh, orang-orang yang membencimu",
        "ms_jawi": "Sungguh, orang-orang yang membencimu",
        "trans": "inna shaāniaka"
      },
      {
        "sura": 108,
        "aya": 3,
        "word": 2,
        "ar": "هُوَ الْأَبْتَرُ",
        "en": "he (is) the one cut off.",
        "id": "dialah yang terputus (dari rahmat Allah).",
        "ms": "dialah yang terputus (dari rahmat Allah).",
        "ms_jawi": "dialah yang terputus (dari rahmat Allah).",
        "trans": "huwal abtaru"
      }
    ],
    "109": [
      {
        "sura": 109,
        "aya": 1,
        "word": 1,
        "ar": "قُلْ يٰٓ",
        "en": "Say, \"O",
        "id": "Katakanlah (Muhammad), \"Wahai",
        "ms": "Katakanlah (Muhammad), \"Wahai",
        "ms_jawi": "Katakanlah (Muhammad), \"Wahai",
        "trans": "qul yaa"
      },
      {
        "sura": 109,
        "aya": 1,
        "word": 2,
        "ar": "أَ يُّهَا الْكٰفِرُونَ",
        "en": "disbelievers,",
        "id": "orang-orang kafir!",
        "ms": "orang-orang kafir!",
        "ms_jawi": "orang-orang kafir!",
        "trans": "ayyuhaāl kaafiruwna"
      },
      {
        "sura": 109,
        "aya": 2,
        "word": 1,
        "ar": "لَآ أَعْبُدُ",
        "en": "I do not worship",
        "id": "aku tidak akan menyembah",
        "ms": "aku tidak akan menyembah",
        "ms_jawi": "aku tidak akan menyembah",
        "trans": "laā aʿbudu"
      },
      {
        "sura": 109,
        "aya": 2,
        "word": 2,
        "ar": "مَا تَعْبُدُونَ",
        "en": "what you worship.",
        "id": "apa yang kamu sembah,",
        "ms": "apa yang kamu sembah,",
        "ms_jawi": "apa yang kamu sembah,",
        "trans": "maā taʿbuduwna"
      },
      {
        "sura": 109,
        "aya": 3,
        "word": 1,
        "ar": "وَلَآ أَنتُمْ",
        "en": "And not you",
        "id": "dan tidak kamu",
        "ms": "dan tidak kamu",
        "ms_jawi": "dan tidak kamu",
        "trans": "walaā antum"
      },
      {
        "sura": 109,
        "aya": 3,
        "word": 2,
        "ar": "عٰبِدُونَ مَآ أَعْبُدُ",
        "en": "(are) worshippers of what I worship.",
        "id": "menyembah apa yang aku sembah,",
        "ms": "menyembah apa yang aku sembah,",
        "ms_jawi": "menyembah apa yang aku sembah,",
        "trans": "ʿaabiduwna maā aʿbudu"
      },
      {
        "sura": 109,
        "aya": 4,
        "word": 1,
        "ar": "وَلَآ أَنَا۠ عَابِدٌ مَّا عَبَدتُّمْ",
        "en": "And not will I be a worshipper of what you worship.",
        "id": "dan aku tidak pernah menjadi penyembah apa yang kamu sembah,",
        "ms": "dan aku tidak pernah menjadi penyembah apa yang kamu sembah,",
        "ms_jawi": "dan aku tidak pernah menjadi penyembah apa yang kamu sembah,",
        "trans": "walaā anaā ʿaābidun mmaā ʿabadttum"
      },
      {
        "sura": 109,
        "aya": 5,
        "word": 1,
        "ar": "وَلَآ أَنتُمْ عٰبِدُونَ",
        "en": "And not will you be worshippers",
        "id": "dan kamu tidak pernah (pula) menjadi penyembah",
        "ms": "dan kamu tidak pernah (pula) menjadi penyembah",
        "ms_jawi": "dan kamu tidak pernah (pula) menjadi penyembah",
        "trans": "walaā antum ʿaabiduwna"
      },
      {
        "sura": 109,
        "aya": 5,
        "word": 2,
        "ar": "مَآ أَعْبُدُ",
        "en": "of what I worship.",
        "id": "apa yang aku sembah.",
        "ms": "apa yang aku sembah.",
        "ms_jawi": "apa yang aku sembah.",
        "trans": "maā aʿbudu"
      },
      {
        "sura": 109,
        "aya": 6,
        "word": 1,
        "ar": "لَكُمْ دِينُكُمْ",
        "en": "For you is your religion,",
        "id": "Untukmu agamamu,",
        "ms": "Untukmu agamamu,",
        "ms_jawi": "Untukmu agamamu,",
        "trans": "lakum diynukum"
      },
      {
        "sura": 109,
        "aya": 6,
        "word": 2,
        "ar": "وَلِىَ دِينِ",
        "en": "and for me is my religion.\"",
        "id": "dan untukku agamaku.\"",
        "ms": "dan untukku agamaku.\"",
        "ms_jawi": "dan untukku agamaku.\"",
        "trans": "waliāa diyni"
      }
    ],
    "110": [
      {
        "sura": 110,
        "aya": 1,
        "word": 1,
        "ar": "إِذَا جَآ",
        "en": "When comes",
        "id": "Apabila telah datang",
        "ms": "Apabila telah datang",
        "ms_jawi": "Apabila telah datang",
        "trans": "idhaā jaāa"
      },
      {
        "sura": 110,
        "aya": 1,
        "word": 2,
        "ar": "ءَ نَصْرُ اللَّهِ وَالْفَتْحُ",
        "en": "the Help of Allah and the Victory,",
        "id": "pertolongan Allah dan kemenangan,",
        "ms": "pertolongan Allah dan kemenangan,",
        "ms_jawi": "pertolongan Allah dan kemenangan,",
        "trans": "anaṣrul-lahi waalfathu"
      },
      {
        "sura": 110,
        "aya": 2,
        "word": 1,
        "ar": "وَرَأَيْتَ النَّاسَ",
        "en": "And you see the people",
        "id": "dan kamu lihat manusia",
        "ms": "dan kamu lihat manusia",
        "ms_jawi": "dan kamu lihat manusia",
        "trans": "waraaytan-naāsa"
      },
      {
        "sura": 110,
        "aya": 2,
        "word": 2,
        "ar": "يَدْخُلُونَ فِى دِينِ اللَّهِ أَفْوَاجًا",
        "en": "entering into the religion of Allah in multitudes,",
        "id": "berbondong-bondong masuk agama Allah,",
        "ms": "berbondong-bondong masuk agama Allah,",
        "ms_jawi": "berbondong-bondong masuk agama Allah,",
        "trans": "yadkhuluwna fii diynil-lahi afwaāja"
      },
      {
        "sura": 110,
        "aya": 3,
        "word": 1,
        "ar": "فَسَبِّحْ بِحَمْدِ رَبِّكَ",
        "en": "Then exalt [Him] with praise of your Lord",
        "id": "maka bertasbihlah dengan memuji Tuhanmu",
        "ms": "maka bertasbihlah dengan memuji Tuhanmu",
        "ms_jawi": "maka bertasbihlah dengan memuji Tuhanmu",
        "trans": "fasabbih bihamdi rabbika"
      },
      {
        "sura": 110,
        "aya": 3,
        "word": 2,
        "ar": "وَاسْتَغْفِرْهُ",
        "en": "and ask His forgiveness.",
        "id": "dan mohonlah ampun",
        "ms": "dan mohonlah ampun",
        "ms_jawi": "dan mohonlah ampun",
        "trans": "waastaghfirhu"
      },
      {
        "sura": 110,
        "aya": 3,
        "word": 3,
        "ar": "إِنَّهُۥ كَانَ",
        "en": "Indeed, He is",
        "id": "sesungguhnya Dia adalah",
        "ms": "sesungguhnya Dia adalah",
        "ms_jawi": "sesungguhnya Dia adalah",
        "trans": "innahu kaāna"
      },
      {
        "sura": 110,
        "aya": 3,
        "word": 4,
        "ar": "تَوَّابًۢا",
        "en": "Oft-Returning.",
        "id": "Maha Penerima Taubat",
        "ms": "Maha Penerima Taubat",
        "ms_jawi": "Maha Penerima Taubat",
        "trans": "tawwaābanā"
      }
    ],
    "111": [
      {
        "sura": 111,
        "aya": 1,
        "word": 1,
        "ar": "تَبَّتْ يَدَآ",
        "en": "Perish the hands",
        "id": "Binasalah kedua tangan",
        "ms": "Binasalah kedua tangan",
        "ms_jawi": "Binasalah kedua tangan",
        "trans": "tabbat yadaā"
      },
      {
        "sura": 111,
        "aya": 1,
        "word": 2,
        "ar": "أَبِى لَهَبٍ وَتَبَّ",
        "en": "of Abu Lahab and perish he.",
        "id": "Abu Lahab dan benar-benar binasa dia!",
        "ms": "Abu Lahab dan benar-benar binasa dia!",
        "ms_jawi": "Abu Lahab dan benar-benar binasa dia!",
        "trans": "abiā lahabiw watabba"
      },
      {
        "sura": 111,
        "aya": 2,
        "word": 1,
        "ar": "مَآ أَغْنٰى عَنْهُ",
        "en": "Will not avail him",
        "id": "tidak berguna baginya",
        "ms": "tidak berguna baginya",
        "ms_jawi": "tidak berguna baginya",
        "trans": "maā aghnaāa ʿanhu"
      },
      {
        "sura": 111,
        "aya": 2,
        "word": 2,
        "ar": "مَالُهُۥ وَمَا كَسَبَ",
        "en": "his wealth and what he earned.",
        "id": "hartanya dan apa yang dia usahakan.",
        "ms": "hartanya dan apa yang dia usahakan.",
        "ms_jawi": "hartanya dan apa yang dia usahakan.",
        "trans": "maāluhu wamaā kasaba"
      },
      {
        "sura": 111,
        "aya": 3,
        "word": 1,
        "ar": "سَيَصْلٰى نَا",
        "en": "He will be burnt in",
        "id": "kelak ia akan masuk ke dalam",
        "ms": "kelak ia akan masuk ke dalam",
        "ms_jawi": "kelak ia akan masuk ke dalam",
        "trans": "sayaṣlaāa naā"
      },
      {
        "sura": 111,
        "aya": 3,
        "word": 2,
        "ar": "رًا ذَاتَ لَهَبٍ",
        "en": "a Fire of [blazing] flame",
        "id": "api yang bergejolak (neraka).",
        "ms": "api yang bergejolak (neraka).",
        "ms_jawi": "api yang bergejolak (neraka).",
        "trans": "ran dhaāta lahabin"
      },
      {
        "sura": 111,
        "aya": 4,
        "word": 1,
        "ar": "وَامْرَأَتُهُۥ حَمَّالَةَ الْحَطَبِ",
        "en": "And his wife [as well] - the carrier of firewood.",
        "id": "Dan (begitu pula) istrinya, pembawa kayu bakar (penyebar fitnah).",
        "ms": "Dan (begitu pula) istrinya, pembawa kayu bakar (penyebar fitnah).",
        "ms_jawi": "Dan (begitu pula) istrinya, pembawa kayu bakar (penyebar fitnah).",
        "trans": "waamraatuhu hammaālatal haṭabi"
      },
      {
        "sura": 111,
        "aya": 5,
        "word": 1,
        "ar": "فِى جِيدِهَا",
        "en": "Around her neck",
        "id": "Di lehernya",
        "ms": "Di lehernya",
        "ms_jawi": "Di lehernya",
        "trans": "fii jiydihaā"
      },
      {
        "sura": 111,
        "aya": 5,
        "word": 2,
        "ar": "حَبْلٌ مِّن مَّسَدٍۢ",
        "en": "is a rope of [twisted] fiber.",
        "id": "ada tali dari sabut yang dipintal.",
        "ms": "ada tali dari sabut yang dipintal.",
        "ms_jawi": "ada tali dari sabut yang dipintal.",
        "trans": "hablun mmin mmasadin"
      }
    ],
    "112": [
      {
        "sura": 112,
        "aya": 1,
        "word": 1,
        "ar": "قُلْ هُوَ اللَّهُ",
        "en": "Say, \"He is Allah,",
        "id": "Katakanlah (Muhammad), \"Dialah Allah,",
        "ms": "Katakanlah (Muhammad), \"Dialah Allah,",
        "ms_jawi": "Katakanlah (Muhammad), \"Dialah Allah,",
        "trans": "qul huwal-lahu"
      },
      {
        "sura": 112,
        "aya": 1,
        "word": 2,
        "ar": "أَحَدٌ",
        "en": "the One.",
        "id": "Esa",
        "ms": "Esa",
        "ms_jawi": "Esa",
        "trans": "ahadun"
      },
      {
        "sura": 112,
        "aya": 2,
        "word": 1,
        "ar": "اللَّهُ الصَّمَدُ",
        "en": "Allah, the Eternal Refuge.",
        "id": "Allah tempat meminta segala sesuatu.",
        "ms": "Allah tempat meminta segala sesuatu.",
        "ms_jawi": "Allah tempat meminta segala sesuatu.",
        "trans": "al-lahuṣ-ṣamadu"
      },
      {
        "sura": 112,
        "aya": 3,
        "word": 1,
        "ar": "لَمْ يَلِدْ",
        "en": "He not begets",
        "id": "(Allah) tidak beranak",
        "ms": "(Allah) tidak beranak",
        "ms_jawi": "(Allah) tidak beranak",
        "trans": "lam yalid"
      },
      {
        "sura": 112,
        "aya": 3,
        "word": 2,
        "ar": "وَلَمْ يُولَدْ",
        "en": "and He was not begotten.",
        "id": "dan tidak pula diperanakkan.",
        "ms": "dan tidak pula diperanakkan.",
        "ms_jawi": "dan tidak pula diperanakkan.",
        "trans": "walam yuwlad"
      },
      {
        "sura": 112,
        "aya": 4,
        "word": 1,
        "ar": "وَلَمْ يَكُن لَّهُۥ",
        "en": "Nor is there to Him",
        "id": "dan tidak ada baginya",
        "ms": "dan tidak ada baginya",
        "ms_jawi": "dan tidak ada baginya",
        "trans": "walam yakunllahu"
      },
      {
        "sura": 112,
        "aya": 4,
        "word": 2,
        "ar": "كُفُوًا أَحَدٌۢ",
        "en": "any equivalent.\"",
        "id": "yang setara dengan Dia.\"",
        "ms": "yang setara dengan Dia.\"",
        "ms_jawi": "yang setara dengan Dia.\"",
        "trans": "kufuwan ahadun"
      }
    ],
    "113": [
      {
        "sura": 113,
        "aya": 1,
        "word": 1,
        "ar": "قُلْ أَعُوذُ",
        "en": "Say, \"I seek refuge",
        "id": "Katakanlah, \"Aku berlindung",
        "ms": "Katakanlah, \"Aku berlindung",
        "ms_jawi": "Katakanlah, \"Aku berlindung",
        "trans": "qul aʿuwdhu"
      },
      {
        "sura": 113,
        "aya": 1,
        "word": 2,
        "ar": "بِرَبِّ الْفَلَقِ",
        "en": "kepada Tuhan yang menguasai subuh (fajar),",
        "id": "in the Lord of daybreak",
        "ms": "in the Lord of daybreak",
        "ms_jawi": "in the Lord of daybreak",
        "trans": "birabbil-falaqi"
      },
      {
        "sura": 113,
        "aya": 2,
        "word": 1,
        "ar": "مِن شَرِّ مَا",
        "en": "From the evil of that which",
        "id": "dari kejahatan (makhluk yang)",
        "ms": "dari kejahatan (makhluk yang)",
        "ms_jawi": "dari kejahatan (makhluk yang)",
        "trans": "min sharri"
      },
      {
        "sura": 113,
        "aya": 2,
        "word": 2,
        "ar": "خَلَقَ",
        "en": "He created,",
        "id": "Dia ciptakan",
        "ms": "Dia ciptakan",
        "ms_jawi": "Dia ciptakan",
        "trans": "khalaqa"
      },
      {
        "sura": 113,
        "aya": 3,
        "word": 1,
        "ar": "وَمِن شَرِّ غَاسِقٍ",
        "en": "And from the evil of darkness",
        "id": "dan dari kejahatan malam",
        "ms": "dan dari kejahatan malam",
        "ms_jawi": "dan dari kejahatan malam",
        "trans": "wamin sharri ghaāsiqin"
      },
      {
        "sura": 113,
        "aya": 3,
        "word": 2,
        "ar": "إِذَا وَقَبَ",
        "en": "when it settles",
        "id": "apabila telah gelap gulita,",
        "ms": "apabila telah gelap gulita,",
        "ms_jawi": "apabila telah gelap gulita,",
        "trans": "idhaā waqaba"
      },
      {
        "sura": 113,
        "aya": 4,
        "word": 1,
        "ar": "وَمِن شَرِّ النَّفّٰثٰتِ",
        "en": "And from the evil of the blowers",
        "id": "dan dari kejahatan orang yang meniup-niup",
        "ms": "dan dari kejahatan orang yang meniup-niup",
        "ms_jawi": "dan dari kejahatan orang yang meniup-niup",
        "trans": "wamin sharrin-naffaathaati"
      },
      {
        "sura": 113,
        "aya": 4,
        "word": 2,
        "ar": "فِى الْعُقَدِ",
        "en": "in knots",
        "id": "dalam ikatan/simpul",
        "ms": "dalam ikatan/simpul",
        "ms_jawi": "dalam ikatan/simpul",
        "trans": "filʿuqadi"
      },
      {
        "sura": 113,
        "aya": 5,
        "word": 1,
        "ar": "وَمِن شَرِّ حَاسِدٍ",
        "en": "And from the evil of an envier",
        "id": "dan dari kejahatan orang yang dengki",
        "ms": "dan dari kejahatan orang yang dengki",
        "ms_jawi": "dan dari kejahatan orang yang dengki",
        "trans": "wamin sharri haāsidin"
      },
      {
        "sura": 113,
        "aya": 5,
        "word": 2,
        "ar": "إِذَا حَسَدَ",
        "en": "when he envies.\"",
        "id": "apabila dia dengki.\"",
        "ms": "apabila dia dengki.\"",
        "ms_jawi": "apabila dia dengki.\"",
        "trans": "idhaā hasada"
      }
    ],
    "114": [
      {
        "sura": 114,
        "aya": 1,
        "word": 1,
        "ar": "قُلْ أَعُوذُ",
        "en": "Say, \"I seek refuge",
        "id": "Katakanlah, \"Aku berlindung",
        "ms": "Katakanlah, \"Aku berlindung",
        "ms_jawi": "Katakanlah, \"Aku berlindung",
        "trans": "qul aʿuwdhu"
      },
      {
        "sura": 114,
        "aya": 1,
        "word": 2,
        "ar": "بِرَبِّ النَّاسِ",
        "en": "in the Lord of mankind,",
        "id": "kepada Tuhannya manusia,",
        "ms": "kepada Tuhannya manusia,",
        "ms_jawi": "kepada Tuhannya manusia,",
        "trans": "birabbin-naāsi"
      },
      {
        "sura": 114,
        "aya": 2,
        "word": 1,
        "ar": "مَلِكِ النَّاسِ",
        "en": "The Sovereign of mankind.",
        "id": "Raja manusia,",
        "ms": "Raja manusia,",
        "ms_jawi": "Raja manusia,",
        "trans": "malikin-naāsi"
      },
      {
        "sura": 114,
        "aya": 3,
        "word": 1,
        "ar": "إِلٰهِ النَّاسِ",
        "en": "The God of mankind,",
        "id": "Tuhannya manusia,",
        "ms": "Tuhannya manusia,",
        "ms_jawi": "Tuhannya manusia,",
        "trans": "ilaahin-naāsi"
      },
      {
        "sura": 114,
        "aya": 4,
        "word": 1,
        "ar": "مِن شَرِّ الْوَسْوَاسِ الْخَنَّاسِ",
        "en": "From the evil of the retreating whisperer",
        "id": "dari kejahatan (bisikan) setan yang bersembunyi,",
        "ms": "dari kejahatan (bisikan) setan yang bersembunyi,",
        "ms_jawi": "dari kejahatan (bisikan) setan yang bersembunyi,",
        "trans": "min sharril waswaāsil khannaāsi"
      },
      {
        "sura": 114,
        "aya": 5,
        "word": 1,
        "ar": "الَّذِى",
        "en": "The one who",
        "id": "yang",
        "ms": "yang",
        "ms_jawi": "yang",
        "trans": "alladhiā"
      },
      {
        "sura": 114,
        "aya": 5,
        "word": 2,
        "ar": "يُوَسْوِسُ فِى صُدُورِ النَّاسِ",
        "en": "whispers [evil] into the breasts of mankind",
        "id": "membisikkan (kejahatan) ke dalam dada manusia,",
        "ms": "membisikkan (kejahatan) ke dalam dada manusia,",
        "ms_jawi": "membisikkan (kejahatan) ke dalam dada manusia,",
        "trans": "yuwaswisu fii ṣuduwrin-naāsi"
      },
      {
        "sura": 114,
        "aya": 6,
        "word": 1,
        "ar": "مِنَ الْجِنَّةِ",
        "en": "From among the jinn",
        "id": "dari (golongan) jin",
        "ms": "dari (golongan) jin",
        "ms_jawi": "dari (golongan) jin",
        "trans": "minal jinnati"
      },
      {
        "sura": 114,
        "aya": 6,
        "word": 2,
        "ar": "وَالنَّاسِ",
        "en": "and men.",
        "id": "dan manusia",
        "ms": "dan manusia",
        "ms_jawi": "dan manusia",
        "trans": "waan-naāsi"
      }
    ]
  }