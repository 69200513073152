import React from "react";
import { Loadable } from "@kempeb";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";

//components
const Subjects = Loadable(() => import("./Subject"));

const Contents = React.memo(
  ({
    type,
    search,
    handleTopicChange,
    subjects,
    levelId,
    topics,
    topicName
  }) => {
    return (
      <Row>
        <Subjects
          subjects={subjects}
          level={levelId}
          topics={topics}
          topic={topicName}
          handleTopicChange={handleTopicChange}
          type={type}
          search={search}
        />
      </Row>
    );
  }
);

Contents.defaultProps = {
  type: "",
  search: ""
};

Contents.propTypes = {
  type: PropTypes.string.isRequired,
  search: PropTypes.string
};

export default Contents;
