import React from "react";

class modal extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            linkUrl: window.location.pathname.split('/')[1],
            alertLorem:{
                quranRead:'Audio akan berhenti, apakah anda yakin ingin keluar?',
                Bermain : 'Skor anda tidak akan tersimpan, apakah anda yakin ingin keluar?'
            }
            
        }
    }
    render(){
        const {linkUrl,alertLorem} = this.state
        // const {images,state,func} = this.props
        return(
            <div className="popup-hijaiyah">
                <div className="body-alert">
                    <div className="text">
                     <span>Peringatan!</span>
                    <p>{alertLorem[linkUrl]}</p>
                    </div>
                    <div>
                        <div onClick={this.props.back}>IYA</div>
                        <div onClick={this.props.func} >TIDAK</div>
                    </div>
                </div>
              </div>
            )}
}

export default modal;
// export default withReducer('hijaiyah', reducer)(pages);
