// import helpHijaiyah from 'assets'

export default {
    iqra:{
        help:{
            Huruf:'Hijaiyah atau Abjad Arab adalah ejaan arab dikondifikasi untuk menulis dalam bahasa Arab,ia ditulis dari kanan ke kiri dengan gaya tulis menyambung dan mempunyai 28 huruf.',
            Tanda:[
                `Harokat adalah tanda baca yang ditempatkan pada huruf Arab untuk mempermudah membaca huruf Arab.`,
                `Garis diagonal kecil diletakan\ndi atas huruf bisa dibaca "a".`,
                `Garis diagonal kecil diletakan\ndi bawah huruf bisa dibaca "i".`,
                `Seperti Huruf Wawu kecil diletakan\ndi atas huruf bisa dibaca "u".`
            ],
            Tanwin:[
                `Tanwin adalah tanda baca\npada tulisan Arab untuk menyatakan\nbahwa huruf diucapkan diakhiri dengan\nhuruf "nun" mati`,
                `Ini bisa dibaca "-an".`,
                `Ini bisa dibaca "-in".`,
                `Ini bisa dibaca "-un".`
            ]
        }
    },
    games:{
        help:{
            Huruf:'Dengarkan dan teka huruf Hija`iyah yang dibacakan.',
            Tanda:[
                'Dengar dan pilih tanda baris yang betul.',
                'Dengar dan padankan tanda baris yang betul'
            ],
            Tanwin:[
                'Dengar dan pilih tanda tanwin yang betul.',
                'Dengar dan padankan tanda tanwin yang betul.'
            ]
        }
    }
}