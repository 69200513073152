import Loader from "react-loadable"
import Loading from "./loading"

function Loadable(loader = null, loading = Loading, opts = {}) {
    const {
        delay = 300,
        timeout = 10000,
        ...res
    } = opts
    return Loader({
        loader,
        loading,
        delay,
        timeout,
        ...res
    })
}

export default Loadable
