export const CHANGE_LANGUAGE = 'APPLICATION/CHANGE_LANGUAGE'
export const STORE_HISTORY_WATCH = 'APPLICATION/STORE_HISTORY_WATCH'
export const POP_QUIZES = 'APPLICATION/POP_QUIZES'
export const STORE_LANGUAGES = 'APPLICATION/STORE_LANGUAGE'
export const STORE_LEVELS = 'APPLICATION/STORE_LEVEL'
export const STORE_SUBJECTS = 'APPLICATION/STORE_SUBJECT'
export const CHANGE_LEVEL = 'APPLICATION/CHANGE_LEVEL'
export const CHANGE_SUBJECT = 'APPLICATION/CHANGE_SUBJECT'
export const LOADING = 'APPLICATION/LOADING'
export const ERRORS = 'APPLICATION/ERROR'
