import { Api, ResponseSuccess, ResponseError } from "libraries";
import * as types from "./types";

export function logout() {
  return dispatch =>
    dispatch({
      type: types.LOGOUT
    });
}

export function login(username, password) {
  return dispatch => {
    try {
      return Api()
        .post("login", {
          username,
          password
        })
        .then(result => {
          const success = ResponseSuccess(result);
          const {
            data: {
              result: { token }
            }
          } = success;
          dispatch({
            type: types.LOGIN,
            data: token
          });
          return success;
        })
        .catch(errors => ResponseError(errors));
    } catch (errors) {
      return new Promise(resolve => ({
        status: false,
        statusCode: 500,
        data: { message: errors.message }
      }));
    }
  };
}

export function register(credentials = {}) {
  return dispatch => {
    try {
      const { username, email, password, confirmPassword } = credentials;
      return Api()
        .post("signup", {
          username,
          email,
          password,
          confirm_password: confirmPassword
        })
        .then(result => {
          const success = ResponseSuccess(result);
          const {
            data: {
              result: { token }
            }
          } = success;
          dispatch({
            type: types.LOGIN,
            data: token
          });
          return success;
        })
        .catch(result => ResponseError(result));
    } catch (errors) {
      return new Promise(resolve => ({
        status: false,
        statusCode: 500,
        data: { message: errors.message }
      }));
    }
  };
}

export function fetchResetCode(email) {
  return dispatch => {
    try {
      return Api()
        .get(`sendresetcode/${email}`)
        .then(result => ResponseSuccess(result))
        .catch(result => ResponseError(result));
    } catch (errors) {
      return new Promise(resolve => ({
        status: false,
        statusCode: 500,
        data: { message: errors.message }
      }));
    }
  };
}

export function resetPassword(credentials = {}) {
  return dispatch => {
    try {
      const { email, resetCode, password, confirmPassword } = credentials;
      return Api()
        .post("resetpassword", {
          email,
          reset_code: resetCode,
          password,
          confirm_password: confirmPassword
        })
        .then(result => ResponseSuccess(result))
        .catch(result => ResponseError(result));
    } catch (errors) {
      return new Promise(resolve => ({
        status: false,
        statusCode: 500,
        data: { message: errors.message }
      }));
    }
  };
}

export function fetchProfile() {
  return dispatch => {
    try {
      return Api({ withAuth: true })
        .get("details")
        .then(result => {
          const success = ResponseSuccess(result);
          const { data: { result: data } = {} } = success;
          dispatch({
            type: types.FETCH_USER,
            data
          });
          return success;
        })
        .catch(result => ResponseError(result));
    } catch (errors) {
      return new Promise(resolve => ({
        status: false,
        statusCode: 500,
        data: { message: errors.message }
      }));
    }
  };
}

export function getToken() {
  return (dispatch, getState) => getState().Auth;
}
