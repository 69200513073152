//learn
import learn from "assets/images/belajar.png"
import learn2x from "assets/images/belajar@2x.png"
import learnActive from "assets/images/belajar-aktif@2x.png"
import learnActive2x from "assets/images/belajar-aktif@2x.png"
import learnHome from "assets/images/icon-belajar-txt.png"
import learnHome2x from "assets/images/icon-belajar-txt@2x.png"
//quiz
import quiz from "assets/images/kuiz.png"
import quiz2x from "assets/images/kuiz@2x.png"
import quizActive from "assets/images/kuiz-aktif@2x.png"
import quizActive2x from "assets/images/kuiz-aktif@2x.png"
import learnQuiz from "assets/images/icon-kuiz-txt.png"
import learnQuiz2x from "assets/images/icon-kuiz-txt@2x.png"
//hijaiyah
import hijaiyah from "assets/images/hijaiyah.png"
import hijaiyah2x from "assets/images/hijaiyah@2x.png"
import hijaiyahActive from "assets/images/hijaiyah-aktif@2x.png"
import hijaiyahActive2x from "assets/images/hijaiyah-aktif@2x.png"
import learnHijaiyah from "assets/images/icon-hijaiyah@3x@3x.png"
import learnHijaiyah2x from "assets/images/icon-hijaiyah@3x@2x.png"

//quran
import quran from "assets/images/quran.png"
import quran2x from "assets/images/quran@2x.png"
import quranActive from "assets/images/quran-aktif@2x.png"
import quranActive2x from "assets/images/quran-aktif@2x.png"
import learnQuran from "assets/images/icon-quran@3x@2x (1).png"
import learnQuran2 from "assets/images/icon-quran@3x@2x (2).png"


export default [
    {
        "name": "learn",
        "url": "/learn",
        "icon": {
            "active": {
                "src": learnActive2x,
                "srcSet": `${learnActive2x} ${learnActive}`
            },
            "normal": {
                "src": learn2x,
                "srcSet": `${learn2x} ${learn}`
            }
        },
        "home": {
            "normal": {
                "src": learnHome,
                "srcSet": `${learnHome2x} ${learnHome}`
            }
        }
    },
    {
        "name": "quiz",
        "url": "/quiz",
        "icon": {
            "active": {
                "src": quizActive2x,
                "srcSet": `${quizActive2x} ${quizActive}`
            },
            "normal": {
                "src": quiz2x,
                "srcSet": `${quiz2x} ${quiz}`
            }
        },
        "home": {
            "normal": {
                "src": learnQuiz,
                "srcSet": `${learnQuiz2x} ${learnQuiz}`
            }
        }
    },
    {
        "name": "hijaiyah",
        "url": "/hijaiyah",
        "icon": {
            "active": {
                "src": hijaiyahActive2x,
                "srcSet": `${hijaiyahActive2x} ${hijaiyahActive}`
            },
            "normal": {
                "src": hijaiyah2x,
                "srcSet": `${hijaiyah2x} ${hijaiyah}`
            }
        },
        "home": {
            "normal": {
                "src": learnHijaiyah2x,
                "srcSet": `${learnHijaiyah} ${learnHijaiyah2x}`
            }
        }
    },
    {
        "name": "quran",
        "url": "/quran",
        "icon": {
            "active": {
                "src": quranActive2x,
                "srcSet": `${quranActive2x} ${quranActive}`
            },
            "normal": {
                "src": quran2x,
                "srcSet": `${quran2x} ${quran}`
            }
        },
        "home": {
            "normal": {
                "src": learnQuran,
                "srcSet": `${learnQuran2} ${learnQuran}`
            }
        }
    }
]