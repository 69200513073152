// hijaiyah
import h1 from 'assets/audio/hijaiyah/1.mp3'
import h2 from 'assets/audio/hijaiyah/2.mp3'
import h3 from 'assets/audio/hijaiyah/3.mp3'
import h4 from 'assets/audio/hijaiyah/4.mp3'
import h5 from 'assets/audio/hijaiyah/5.mp3'
import h6 from 'assets/audio/hijaiyah/6.mp3'
import h7 from 'assets/audio/hijaiyah/7.mp3'
import h8 from 'assets/audio/hijaiyah/8.mp3'
import h9 from 'assets/audio/hijaiyah/9.mp3'
import h10 from 'assets/audio/hijaiyah/10.mp3'
import h11 from 'assets/audio/hijaiyah/11.mp3'
import h12 from 'assets/audio/hijaiyah/12.mp3'
import h13 from 'assets/audio/hijaiyah/13.mp3'
import h14 from 'assets/audio/hijaiyah/14.mp3'
import h15 from 'assets/audio/hijaiyah/15.mp3'
import h16 from 'assets/audio/hijaiyah/16.mp3'
import h17 from 'assets/audio/hijaiyah/17.mp3'
import h18 from 'assets/audio/hijaiyah/18.mp3'
import h19 from 'assets/audio/hijaiyah/19.mp3'
import h20 from 'assets/audio/hijaiyah/20.mp3'
import h21 from 'assets/audio/hijaiyah/21.mp3'
import h22 from 'assets/audio/hijaiyah/22.mp3'
import h23 from 'assets/audio/hijaiyah/23.mp3'
import h24 from 'assets/audio/hijaiyah/24.mp3'
import h25 from 'assets/audio/hijaiyah/25.mp3'
import h26 from 'assets/audio/hijaiyah/26.mp3'
import h27 from 'assets/audio/hijaiyah/27.mp3'
import h30 from 'assets/audio/hijaiyah/30.mp3'

// fathah
import fk1 from 'assets/audio/harakat/fathah/1.mp3'
import fk2 from 'assets/audio/harakat/fathah/2.mp3'
import fk3 from 'assets/audio/harakat/fathah/3.mp3'
import fk4 from 'assets/audio/harakat/fathah/4.mp3'
import fk5 from 'assets/audio/harakat/fathah/5.mp3'
import fk6 from 'assets/audio/harakat/fathah/6.mp3'
import fk7 from 'assets/audio/harakat/fathah/7.mp3'
import fk8 from 'assets/audio/harakat/fathah/8.mp3'
import fk9 from 'assets/audio/harakat/fathah/9.mp3'
import fk10 from 'assets/audio/harakat/fathah/10.mp3'
import fk11 from 'assets/audio/harakat/fathah/11.mp3'
import fk12 from 'assets/audio/harakat/fathah/12.mp3'
import fk13 from 'assets/audio/harakat/fathah/13.mp3'
import fk14 from 'assets/audio/harakat/fathah/14.mp3'
import fk15 from 'assets/audio/harakat/fathah/15.mp3'
import fk16 from 'assets/audio/harakat/fathah/16.mp3'
import fk17 from 'assets/audio/harakat/fathah/17.mp3'
import fk18 from 'assets/audio/harakat/fathah/18.mp3'
import fk19 from 'assets/audio/harakat/fathah/19.mp3'
import fk20 from 'assets/audio/harakat/fathah/20.mp3'
import fk21 from 'assets/audio/harakat/fathah/21.mp3'
import fk22 from 'assets/audio/harakat/fathah/22.mp3'
import fk23 from 'assets/audio/harakat/fathah/23.mp3'
import fk24 from 'assets/audio/harakat/fathah/24.mp3'
import fk25 from 'assets/audio/harakat/fathah/25.mp3'
import fk26 from 'assets/audio/harakat/fathah/26.mp3'
import fk27 from 'assets/audio/harakat/fathah/27.mp3'
import fk30 from 'assets/audio/harakat/fathah/30.mp3' 

// fathahain
import f1 from 'assets/audio/harakat/fathatain/1.mp3'
import f2 from 'assets/audio/harakat/fathatain/2.mp3'
import f3 from 'assets/audio/harakat/fathatain/3.mp3'
import f4 from 'assets/audio/harakat/fathatain/4.mp3'
import f5 from 'assets/audio/harakat/fathatain/5.mp3'
import f6 from 'assets/audio/harakat/fathatain/6.mp3'
import f7 from 'assets/audio/harakat/fathatain/7.mp3'
import f8 from 'assets/audio/harakat/fathatain/8.mp3'
import f9 from 'assets/audio/harakat/fathatain/9.mp3'
import f10 from 'assets/audio/harakat/fathatain/10.mp3'
import f11 from 'assets/audio/harakat/fathatain/11.mp3'
import f12 from 'assets/audio/harakat/fathatain/12.mp3'
import f13 from 'assets/audio/harakat/fathatain/13.mp3'
import f14 from 'assets/audio/harakat/fathatain/14.mp3'
import f15 from 'assets/audio/harakat/fathatain/15.mp3'
import f16 from 'assets/audio/harakat/fathatain/16.mp3'
import f17 from 'assets/audio/harakat/fathatain/17.mp3'
import f18 from 'assets/audio/harakat/fathatain/18.mp3'
import f19 from 'assets/audio/harakat/fathatain/19.mp3'
import f20 from 'assets/audio/harakat/fathatain/20.mp3'
import f21 from 'assets/audio/harakat/fathatain/21.mp3'
import f22 from 'assets/audio/harakat/fathatain/22.mp3'
import f23 from 'assets/audio/harakat/fathatain/23.mp3'
import f24 from 'assets/audio/harakat/fathatain/24.mp3'
import f25 from 'assets/audio/harakat/fathatain/25.mp3'
import f26 from 'assets/audio/harakat/fathatain/26.mp3'
import f27 from 'assets/audio/harakat/fathatain/27.mp3'
import f30 from 'assets/audio/harakat/fathatain/30.mp3'

// Dhammah
import d1 from 'assets/audio/harakat/dhammah/1.mp3'
import d2 from 'assets/audio/harakat/dhammah/2.mp3'
import d3 from 'assets/audio/harakat/dhammah/3.mp3'
import d4 from 'assets/audio/harakat/dhammah/4.mp3'
import d5 from 'assets/audio/harakat/dhammah/5.mp3'
import d6 from 'assets/audio/harakat/dhammah/6.mp3'
import d7 from 'assets/audio/harakat/dhammah/7.mp3'
import d8 from 'assets/audio/harakat/dhammah/8.mp3'
import d9 from 'assets/audio/harakat/dhammah/9.mp3'
import d10 from 'assets/audio/harakat/dhammah/10.mp3'
import d11 from 'assets/audio/harakat/dhammah/11.mp3'
import d12 from 'assets/audio/harakat/dhammah/12.mp3'
import d13 from 'assets/audio/harakat/dhammah/13.mp3'
import d14 from 'assets/audio/harakat/dhammah/14.mp3'
import d15 from 'assets/audio/harakat/dhammah/15.mp3'
import d16 from 'assets/audio/harakat/dhammah/16.mp3'
import d17 from 'assets/audio/harakat/dhammah/17.mp3'
import d18 from 'assets/audio/harakat/dhammah/18.mp3'
import d19 from 'assets/audio/harakat/dhammah/19.mp3'
import d20 from 'assets/audio/harakat/dhammah/20.mp3'
import d21 from 'assets/audio/harakat/dhammah/21.mp3'
import d22 from 'assets/audio/harakat/dhammah/22.mp3'
import d23 from 'assets/audio/harakat/dhammah/23.mp3'
import d24 from 'assets/audio/harakat/dhammah/24.mp3'
import d25 from 'assets/audio/harakat/dhammah/25.mp3'
import d26 from 'assets/audio/harakat/dhammah/26.mp3'
import d27 from 'assets/audio/harakat/dhammah/27.mp3'
import d30 from 'assets/audio/harakat/dhammah/30.mp3'

// Dhammahtain
import df1 from 'assets/audio/harakat/dhammatain/1.mp3'
import df2 from 'assets/audio/harakat/dhammatain/2.mp3'
import df3 from 'assets/audio/harakat/dhammatain/3.mp3'
import df4 from 'assets/audio/harakat/dhammatain/4.mp3'
import df5 from 'assets/audio/harakat/dhammatain/5.mp3'
import df6 from 'assets/audio/harakat/dhammatain/6.mp3'
import df7 from 'assets/audio/harakat/dhammatain/7.mp3'
import df8 from 'assets/audio/harakat/dhammatain/8.mp3'
import df9 from 'assets/audio/harakat/dhammatain/9.mp3'
import df10 from 'assets/audio/harakat/dhammatain/10.mp3'
import df11 from 'assets/audio/harakat/dhammatain/11.mp3'
import df12 from 'assets/audio/harakat/dhammatain/12.mp3'
import df13 from 'assets/audio/harakat/dhammatain/13.mp3'
import df14 from 'assets/audio/harakat/dhammatain/14.mp3'
import df15 from 'assets/audio/harakat/dhammatain/15.mp3'
import df16 from 'assets/audio/harakat/dhammatain/16.mp3'
import df17 from 'assets/audio/harakat/dhammatain/17.mp3'
import df18 from 'assets/audio/harakat/dhammatain/18.mp3'
import df19 from 'assets/audio/harakat/dhammatain/19.mp3'
import df20 from 'assets/audio/harakat/dhammatain/20.mp3'
import df21 from 'assets/audio/harakat/dhammatain/21.mp3'
import df22 from 'assets/audio/harakat/dhammatain/22.mp3'
import df23 from 'assets/audio/harakat/dhammatain/23.mp3'
import df24 from 'assets/audio/harakat/dhammatain/24.mp3'
import df25 from 'assets/audio/harakat/dhammatain/25.mp3'
import df26 from 'assets/audio/harakat/dhammatain/26.mp3'
import df27 from 'assets/audio/harakat/dhammatain/27.mp3'
import df30 from 'assets/audio/harakat/dhammatain/30.mp3'

// kasrah
import k1 from 'assets/audio/harakat/kasrah/1.mp3'
import k2 from 'assets/audio/harakat/kasrah/2.mp3'
import k3 from 'assets/audio/harakat/kasrah/3.mp3'
import k4 from 'assets/audio/harakat/kasrah/4.mp3'
import k5 from 'assets/audio/harakat/kasrah/5.mp3'
import k6 from 'assets/audio/harakat/kasrah/6.mp3'
import k7 from 'assets/audio/harakat/kasrah/7.mp3'
import k8 from 'assets/audio/harakat/kasrah/8.mp3'
import k9 from 'assets/audio/harakat/kasrah/9.mp3'
import k10 from 'assets/audio/harakat/kasrah/10.mp3'
import k11 from 'assets/audio/harakat/kasrah/11.mp3'
import k12 from 'assets/audio/harakat/kasrah/12.mp3'
import k13 from 'assets/audio/harakat/kasrah/13.mp3'
import k14 from 'assets/audio/harakat/kasrah/14.mp3'
import k15 from 'assets/audio/harakat/kasrah/15.mp3'
import k16 from 'assets/audio/harakat/kasrah/16.mp3'
import k17 from 'assets/audio/harakat/kasrah/17.mp3'
import k18 from 'assets/audio/harakat/kasrah/18.mp3'
import k19 from 'assets/audio/harakat/kasrah/19.mp3'
import k20 from 'assets/audio/harakat/kasrah/20.mp3'
import k21 from 'assets/audio/harakat/kasrah/21.mp3'
import k22 from 'assets/audio/harakat/kasrah/22.mp3'
import k23 from 'assets/audio/harakat/kasrah/23.mp3'
import k24 from 'assets/audio/harakat/kasrah/24.mp3'
import k25 from 'assets/audio/harakat/kasrah/25.mp3'
import k26 from 'assets/audio/harakat/kasrah/26.mp3'
import k27 from 'assets/audio/harakat/kasrah/27.mp3'
import k30 from 'assets/audio/harakat/kasrah/30.mp3'

// Kasrahtain
import kf1 from 'assets/audio/harakat/kasratain/1.mp3'
import kf2 from 'assets/audio/harakat/kasratain/2.mp3'
import kf3 from 'assets/audio/harakat/kasratain/3.mp3'
import kf4 from 'assets/audio/harakat/kasratain/4.mp3'
import kf5 from 'assets/audio/harakat/kasratain/5.mp3'
import kf6 from 'assets/audio/harakat/kasratain/6.mp3'
import kf7 from 'assets/audio/harakat/kasratain/7.mp3'
import kf8 from 'assets/audio/harakat/kasratain/8.mp3'
import kf9 from 'assets/audio/harakat/kasratain/9.mp3'
import kf10 from 'assets/audio/harakat/kasrah/10.mp3'
import kf11 from 'assets/audio/harakat/kasratain/11.mp3'
import kf12 from 'assets/audio/harakat/kasratain/12.mp3'
import kf13 from 'assets/audio/harakat/kasratain/13.mp3'
import kf14 from 'assets/audio/harakat/kasratain/14.mp3'
import kf15 from 'assets/audio/harakat/kasratain/15.mp3'
import kf16 from 'assets/audio/harakat/kasratain/16.mp3'
import kf17 from 'assets/audio/harakat/kasratain/17.mp3'
import kf18 from 'assets/audio/harakat/kasratain/18.mp3'
import kf19 from 'assets/audio/harakat/kasratain/19.mp3'
import kf20 from 'assets/audio/harakat/kasratain/20.mp3'
import kf21 from 'assets/audio/harakat/kasratain/21.mp3'
import kf22 from 'assets/audio/harakat/kasratain/22.mp3'
import kf23 from 'assets/audio/harakat/kasratain/23.mp3'
import kf24 from 'assets/audio/harakat/kasratain/24.mp3'
import kf25 from 'assets/audio/harakat/kasratain/25.mp3'
import kf26 from 'assets/audio/harakat/kasratain/26.mp3'
import kf27 from 'assets/audio/harakat/kasratain/27.mp3'
import kf30 from 'assets/audio/harakat/kasratain/30.mp3'

export default [
    {
        id:1,huruf: 'ا',name:[
            {namefathah: 'a'},{namefathahtain: 'an'},{namedhammah: 'u'},{namedhammahtain: 'un'},{namekasrah: 'i'},{namekasrahtain: 'in'}
        ],hijaiyahName:'alif',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#f5c056',hijaiyah: h1,
        audio:[
            {audiofathah: fk1},{audiofathahtain: f1},{audiodhammah: d1},{audiodhammahtain: df1},{audiokasrah: k1},{audiokasrahtain: kf1}
        ]
    },
    {
        id:2,huruf: 'ب',name:[
            {namefathah: 'ba'},{namefathahtain: 'ban'},{namedhammah: 'bu'},{namedhammahtain: 'bun'},{namekasrah: 'bi'},{namekasrahtain: 'bin'}
        ],hijaiyahName:'ba',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#80d423',hijaiyah: h2,
        audio:[
            {audiofathah: fk2},{audiofathahtain: f2},{audiodhammah: d2},{audiodhammahtain: df2},{audiokasrah: k2},{audiokasrahtain: kf2}
        ]
    },
    {
        id:3,huruf: 'ت',name:[
            {namefathah: 'ta'},{namefathahtain: 'tan'},{namedhammah: 'tu'},{namedhammahtain: 'tun'},{namekasrah: 'ti'},{namekasrahtain: 'tin'}
        ],hijaiyahName:'ta',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#2aaed4',hijaiyah: h3,
        audio:[
            {audiofathah: fk3},{audiofathahtain: f3},{audiodhammah: d3},{audiodhammahtain: df3},{audiokasrah: k3},{audiokasrahtain: kf3}
        ]
    },
    {
        id:4,huruf: 'ث',name:[
            {namefathah: 'tsa'},{namefathahtain: 'tsan'},{namedhammah: 'tsu'},{namedhammahtain: 'tsun'},{namekasrah: 'tsi'},{namekasrahtain: 'tsin'}
        ],hijaiyahName:'tsa',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#bc72a2',hijaiyah: h4,
        audio:[
            {audiofathah: fk4},{audiofathahtain: f4},{audiodhammah: d4},{audiodhammahtain: df4},{audiokasrah: k4},{audiokasrahtain: kf4}
        ]
    },
    {
        id:5,huruf: 'ج',name:[
            {namefathah: 'ja'},{namefathahtain: 'jan'},{namedhammah: 'ju'},{namedhammahtain: 'jun'},{namekasrah: 'ji'},{namekasrahtain: 'jin'}
        ],hijaiyahName:'jim',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#ff5f7b',hijaiyah: h5,
        audio:[
            {audiofathah: fk5},{audiofathahtain: f5},{audiodhammah: d5},{audiodhammahtain: df5},{audiokasrah: k5},{audiokasrahtain: kf5}
        ]
    },
    {
        id:6,huruf: 'ح',name:[
            {namefathah: 'ḥa'},{namefathahtain: 'ḥan'},{namedhammah: 'ḥu'},{namedhammahtain: 'ḥun'},{namekasrah: 'ḥi'},{namekasrahtain: 'ḥin'}
        ],hijaiyahName:'ḥa',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#e77d66',hijaiyah: h6,
        audio:[
            {audiofathah: fk6},{audiofathahtain: f6},{audiodhammah: d6},{audiodhammahtain: df6},{audiokasrah: k6},{audiokasrahtain: kf6}
        ]
    },
    {
        id:7,huruf: 'خ',name:[
            {namefathah: 'kha'},{namefathahtain: 'khan'},{namedhammah: 'khu'},{namedhammahtain: 'khun'},{namekasrah: 'khi'},{namekasrahtain: 'khin'}
        ],hijaiyahName:'kha',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#f5c056',hijaiyah: h7,
        audio:[
            {audiofathah: fk7},{audiofathahtain: f7},{audiodhammah: d7},{audiodhammahtain: df7},{audiokasrah: k7},{audiokasrahtain: kf7}
        ]
    },
    {
        id:8,huruf: 'د',name:[
            {namefathah: 'da'},{namefathahtain: 'dan'},{namedhammah: 'du'},{namedhammahtain: 'dun'},{namekasrah: 'di'},{namekasrahtain: 'din'}
        ],hijaiyahName:'dal',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#80d423',hijaiyah: h8,
        audio:[
            {audiofathah: fk8},{audiofathahtain: f8},{audiodhammah: d8},{audiodhammahtain: df8},{audiokasrah: k8},{audiokasrahtain: kf8}
        ]
    },
    {
        id:9,huruf: 'ذ',name:[
            {namefathah: 'dza'},{namefathahtain: 'dzan'},{namedhammah: 'dzu'},{namedhammahtain: 'dzun'},{namekasrah: 'dzi'},{namekasrahtain: 'dzin'}
        ],hijaiyahName:'dzal',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#2aaed4',hijaiyah: h9,
        audio:[
            {audiofathah: fk9},{audiofathahtain: f9},{audiodhammah: d9},{audiodhammahtain: df9},{audiokasrah: k9},{audiokasrahtain: kf9}
        ]
    },
    {
        id:10,huruf: 'ر',name:[
            {namefathah: 'ra'},{namefathahtain: 'ran'},{namedhammah: 'ru'},{namedhammahtain: 'run'},{namekasrah: 'ri'},{namekasrahtain: 'rin'}
        ],hijaiyahName:'ra',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#bc72a2',hijaiyah: h10,
        audio:[
            {audiofathah: fk10},{audiofathahtain: f10},{audiodhammah: d10},{audiodhammahtain: df10},{audiokasrah: k10},{audiokasrahtain: kf10}
        ]
    },
    {
        id:11,huruf: 'ز',name:[
            {namefathah: 'za'},{namefathahtain: 'zan'},{namedhammah: 'zu'},{namedhammahtain: 'zun'},{namekasrah: 'zi'},{namekasrahtain: 'zin'}
        ],hijaiyahName:'zai',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#ff5f7b',hijaiyah: h11,
        audio:[
            {audiofathah: fk11},{audiofathahtain: f11},{audiodhammah: d11},{audiodhammahtain: df11},{audiokasrah: k11},{audiokasrahtain: kf11}
        ]
    },
    {
        id:12,huruf: 'س',name:[
            {namefathah: 'sa'},{namefathahtain: 'san'},{namedhammah: 'su'},{namedhammahtain: 'sun'},{namekasrah: 'si'},{namekasrahtain: 'sin'}
        ],hijaiyahName:'sin',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#e77d66',hijaiyah: h12,
        audio:[
            {audiofathah: fk12},{audiofathahtain: f12},{audiodhammah: d12},{audiodhammahtain: df12},{audiokasrah: k12},{audiokasrahtain: kf12}
        ]
    },
    {
        id:13,huruf: 'ش',name:[
            {namefathah: 'sya'},{namefathahtain: 'syan'},{namedhammah: 'syu'},{namedhammahtain: 'syun'},{namekasrah: 'syi'},{namekasrahtain: 'syin'}
        ],hijaiyahName:'syin',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#f5c056',hijaiyah: h13,
        audio:[
            {audiofathah: fk13},{audiofathahtain: f13},{audiodhammah: d13},{audiodhammahtain: df13},{audiokasrah: k13},{audiokasrahtain: kf13}
        ]
    },
    {
        id:14,huruf: 'ص',name:[
            {namefathah: 'ṣha'},{namefathahtain: 'ṣhan'},{namedhammah: 'ṣhu'},{namedhammahtain: 'ṣhun'},{namekasrah: 'ṣhi'},{namekasrahtain: 'ṣhin'}
        ],hijaiyahName:'ṣhad',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#80d423',hijaiyah: h14,
        audio:[
            {audiofathah: fk14},{audiofathahtain: f14},{audiodhammah: d14},{audiodhammahtain: df14},{audiokasrah: k14},{audiokasrahtain: kf14}
        ]
    },
    {
        id:15,huruf: 'ض',name:[
            {namefathah: 'ḍha'},{namefathahtain: 'ḍhan'},{namedhammah: 'ḍhu'},{namedhammahtain: 'ḍhun'},{namekasrah: 'ḍhi'},{namekasrahtain: 'ḍhin'}
        ],hijaiyahName:'ḍhad',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#2aaed4',hijaiyah: h15,
        audio:[
            {audiofathah: fk15},{audiofathahtain: f15},{audiodhammah: d15},{audiodhammahtain: df15},{audiokasrah: k15},{audiokasrahtain: kf15}
        ]
    },
    {
        id:16,huruf: 'ط',name:[
            {namefathah: 'ṭha'},{namefathahtain: 'ṭhan'},{namedhammah: 'ṭhu'},{namedhammahtain: 'ṭhun'},{namekasrah: 'ṭhi'},{namekasrahtain: 'ṭhin'}
        ],hijaiyahName:'ṭha',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#bc72a2',hijaiyah: h16,
        audio:[
            {audiofathah: fk16},{audiofathahtain: f16},{audiodhammah: d16},{audiodhammahtain: df16},{audiokasrah: k16},{audiokasrahtain: kf16}
        ]
    },
    {
        id:17,huruf: 'ظ',name:[
            {namefathah: 'ẓha'},{namefathahtain: 'ẓhan'},{namedhammah: 'ẓhu'},{namedhammahtain: 'ẓhun'},{namekasrah: 'ẓhi'},{namekasrahtain: 'ẓhin'}
        ],hijaiyahName:'ẓha',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#ff5f7b',hijaiyah: h17,
        audio:[
            {audiofathah: fk17},{audiofathahtain: f17},{audiodhammah: d17},{audiodhammahtain: df17},{audiokasrah: k17},{audiokasrahtain: kf17}
        ]
    },
    {
        id:18,huruf: 'ع',name:[
            {namefathah: '‘a'},{namefathahtain: '‘an'},{namedhammah: '‘u'},{namedhammahtain: '‘un'},{namekasrah: '‘i'},{namekasrahtain: '‘in'}
        ],hijaiyahName:'‘ain',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#e77d66',hijaiyah: h18,
        audio:[
            {audiofathah: fk18},{audiofathahtain: f18},{audiodhammah: d18},{audiodhammahtain: df18},{audiokasrah: k18},{audiokasrahtain: kf18}
        ]
    },
    {
        id:19,huruf: 'غ',name:[
            {namefathah: 'ga'},{namefathahtain: 'gan'},{namedhammah: 'gu'},{namedhammahtain: 'gun'},{namekasrah: 'gi'},{namekasrahtain: 'gin'}
        ],hijaiyahName:'gain',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#f5c056',hijaiyah: h19,
        audio:[
            {audiofathah: fk19},{audiofathahtain: f19},{audiodhammah: d19},{audiodhammahtain: df19},{audiokasrah: k19},{audiokasrahtain: kf19}
        ]
    },
    {
        id:20,huruf: 'ف',name:[
            {namefathah: 'fa'},{namefathahtain: 'fan'},{namedhammah: 'fu'},{namedhammahtain: 'fun'},{namekasrah: 'fi'},{namekasrahtain: 'fin'}
        ],hijaiyahName:'fa',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#80d423',hijaiyah: h20,
        audio:[
            {audiofathah: fk20},{audiofathahtain: f20},{audiodhammah: d20},{audiodhammahtain: df20},{audiokasrah: k20},{audiokasrahtain: kf20}
        ]
    },
    {
        id:21,huruf: 'ق',name:[
            {namefathah: 'qa'},{namefathahtain: 'qan'},{namedhammah: 'qu'},{namedhammahtain: 'qun'},{namekasrah: 'qi'},{namekasrahtain: 'qin'}
        ],hijaiyahName:'qaf',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#2aaed4',hijaiyah: h21,
        audio:[
            {audiofathah: fk21},{audiofathahtain: f21},{audiodhammah: d21},{audiodhammahtain: df21},{audiokasrah: k21},{audiokasrahtain: kf21}
        ]
    },
    {
        id:22,huruf: 'ك',name:[
            {namefathah: 'ka'},{namefathahtain: 'kan'},{namedhammah: 'ku'},{namedhammahtain: 'kun'},{namekasrah: 'ki'},{namekasrahtain: 'kin'}
        ],hijaiyahName:'kaf',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#bc72a2',hijaiyah: h22,
        audio:[
            {audiofathah: fk22},{audiofathahtain: f22},{audiodhammah: d22},{audiodhammahtain: df22},{audiokasrah: k22},{audiokasrahtain: kf22}
        ]
    },
    {
        id:23,huruf: 'ل',name:[
            {namefathah: 'la'},{namefathahtain: 'lan'},{namedhammah: 'lu'},{namedhammahtain: 'lun'},{namekasrah: 'li'},{namekasrahtain: 'lin'}
        ],hijaiyahName:'lam',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#ff5f7b',hijaiyah: h23,
        audio:[
            {audiofathah: fk23},{audiofathahtain: f23},{audiodhammah: d23},{audiodhammahtain: df23},{audiokasrah: k23},{audiokasrahtain: kf23}
        ]
    },
    {
        id:24,huruf: 'م',name:[
            {namefathah: 'ma'},{namefathahtain: 'man'},{namedhammah: 'mu'},{namedhammahtain: 'mun'},{namekasrah: 'mi'},{namekasrahtain: 'min'}
        ],hijaiyahName:'mim',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#e77d66',hijaiyah: h24,
        audio:[
            {audiofathah: fk24},{audiofathahtain: f24},{audiodhammah: d24},{audiodhammahtain: df24},{audiokasrah: k24},{audiokasrahtain: kf24}
        ]
    },
    {
        id:25,huruf: 'ن',name:[
            {namefathah: 'na'},{namefathahtain: 'nan'},{namedhammah: 'nu'},{namedhammahtain: 'nun'},{namekasrah: 'ni'},{namekasrahtain: 'nin'}
        ],hijaiyahName:'nun',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#f5c056',hijaiyah: h25,
        audio:[
            {audiofathah: fk25},{audiofathahtain: f25},{audiodhammah: d25},{audiodhammahtain: df25},{audiokasrah: k25},{audiokasrahtain: kf25}
        ]
    },
    {
        id:26,huruf: 'و',name:[
            {namefathah: 'wa'},{namefathahtain: 'wan'},{namedhammah: 'wu'},{namedhammahtain: 'wun'},{namekasrah: 'wi'},{namekasrahtain: 'win'}
        ],hijaiyahName:'wau',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#80d423',hijaiyah: h26,
        audio:[
            {audiofathah: fk26},{audiofathahtain: f26},{audiodhammah: d26},{audiodhammahtain: df26},{audiokasrah: k26},{audiokasrahtain: kf26}
        ]
    },
    {
        id:27,huruf: 'ه',name:[
            {namefathah: 'ha'},{namefathahtain: 'han'},{namedhammah: 'hu'},{namedhammahtain: 'hun'},{namekasrah: 'hi'},{namekasrahtain: 'hin'}
        ],hijaiyahName:'ha',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#2aaed4',hijaiyah: h27,
        audio:[
            {audiofathah: fk27},{audiofathahtain: f27},{audiodhammah: d27},{audiodhammahtain: df27},{audiokasrah: k27},{audiokasrahtain: kf27}
        ]
    },
    {
        id:28,huruf: 'ى',name:[
            {namefathah: 'ya'},{namefathahtain: 'yan'},{namedhammah: 'yu'},{namedhammahtain: 'yun'},{namekasrah: 'yi'},{namekasrahtain: 'yin'}
        ],hijaiyahName:'ya',fathah: 'َ',fathahtain: 'ً',kasrah: 'ِ',kasrahtain: 'ٍ',dhammah: 'ُ',dhammahtain: 'ٌ',color:'#f5c056',hijaiyah: h30,
        audio:[
            {audiofathah: fk30},{audiofathahtain: f30},{audiodhammah: d30},{audiodhammahtain: df30},{audiokasrah: k30},{audiokasrahtain: kf30}
        ]
    }
]