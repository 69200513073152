import React from "react";
import { withRouter } from "react-router";
import _navs from "views/components/Header/navs";
import numberAya from 'assets/images/quran/Group 5993@3x.png'
import playSound from 'assets/images/quran/icon-sound@3x@3x.png'
import prev from 'assets/images/quran/buttonSound/icon-prev@3x@3x.png'
import next from 'assets/images/quran/buttonSound/icon-next@3x@3x.png'

class ayahWord extends React.Component{
            render(){
              const { state,func } = this.props
              let {sura, word, ar,trans,id,ms,ms_jawi,en, aya} = state.data[state.selectAyaId - 1][state.selectIndxWord]
              let lengthWord = (state.data[state.selectAyaId - 1]).length
              let length = state.data.length
              return(
                       <div className="quranReadContent gray overflow-none">
                        <div className="ayahWord">
                          <div className="wordArab">
                            <div className="noAyaWord">
                              <img src={numberAya} />
                              <p>{aya}</p>
                            </div>
                            <div className="contentWord">
                            {state.data[state.selectAyaId - 1].map((item, idxWord) => {
                               let { sura, aya, word, ar } = item
                                 let wordSelected = idxWord === state.selectIndxWord
                                 return(
                                   (ar.split(" ")).map((e,indx)=>{
                                     return(
                                       <span key={indx} onClick={_=>func.klikSound(idxWord)} style={{ color:wordSelected && "#fe665a" }}>
                                         {e}{" "}
                                       </span>
                                     )
                                    })
                                    )
                                  })}
                                  </div>
                          </div>
                          <div className="wordDetail">
                                  <div className="detailArab">
                                    <div className={`buttonDetailArab ${state.selectAyaId === length && state.selectIndxWord + 1 === lengthWord ? `event-none`: ''}`}>
                                      <img src={prev} onClick={_=>func.actionButton("nextWord",lengthWord,lengthWord - 1)} />
                                    </div>
                                    <div className="inArab">
                                      {ar}
                                      <div><img src={playSound} onClick={_=>func.klikSound(word - 1)} /></div>
                                    </div>
                                    <div className={`buttonDetailArab ${state.selectAyaId === 1 && state.selectIndxWord === 0 ? `event-none` : ''} `}>
                                      <img src={next} onClick={_=>func.actionButton("prevWord",lengthWord,lengthWord - 1)} />
                                    </div> 
                                  </div>
                                  <div className="transWord">
                                <div className="latin" >{trans}</div>
                                <div className="trans">{id}</div>
                                  </div>
                          </div>
                        </div>
                      </div>
        )
    }
}

export default withRouter(ayahWord);
