import React from "react";
import Modal from './modal'
import ScoreModal from './modal/modal_score'
import audio from '../audio'
import images from '../images'
import Loading from './loading'
import PageBelajar from './belajar'
import PageBermain from './bermain'
import { withRouter } from "react-router";
import audioClick from 'assets/audio/click.mp3'
import BGbelajar from '../../../../assets/images/hijaiyah/Background2.png'
class pages extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            huruf: {},
            popup: false,
            loading:false,
            audioSelect: 1,
            score: 0,
            color:'#e65727',
            popScore: false,
            hurufHijaiyah: audio,
            popupType:'hijaiyah',
            judulImage: images.judul,
            optButton: images.harakat.button,
            typeUrl2:["Tanda-1","Tanwin-1","Huruf-1"],
            url: window.location.pathname.split('/')[2],
            typeUrl:["Tanda-1","Tanda-2","Tanwin-1","Tanwin-2"],
            otpType: (window.location.pathname.split('/')[2].split('-')[0] === 'Tanda' ? "fathah" : 'fathahtain') ,
            ContentPages:[
                {
                    id:1,
                    name:'Belajar',
                    component: PageBelajar
                },
                {
                    id:2,
                    name:'Bermain',
                    component : PageBermain
                }
            ],
            openquiz:''
        }
        this.popup = this.popup.bind(this)
        this.change = this.change.bind(this)
        this.backMenu = this.backMenu.bind(this)
        this.popupInfo = this.popupInfo.bind(this)
        this.filterUrl = this.filterUrl.bind(this)
        this.findImage = this.findImage.bind(this)
        this.optAction = this.optAction.bind(this)
        this.eventClick = this.eventClick.bind(this)
        this.setScore = this.setScore.bind(this)
    }

    eventClick(){
      this.refs.clickaudio.play()
    }

    filterUrl(name1,name2){
        const {typeUrl , url} = this.state
        return `${typeUrl.includes(url) ? name1 : name2} `
    }

    fliterCSS(check,checkId,answer1,answer2){
        const hasil = check === checkId ? answer1 : answer2 
        return hasil
    }

    findImage(url){
        const { judulImage } = this.state
        return judulImage.find(e=> e.nama === url )
    }
    
    change(status,id){
        const { audioSelect ,typeUrl2,url} = this.state
        let jumlah = null
        if(['click','otp'].includes(status) === false){
            jumlah = audioSelect === 28 ? status === "prev" ? audioSelect - 1 :  1 : status === "next" ? audioSelect + 1 : audioSelect === 1 ? 28 :  audioSelect -1
            this.setState({audioSelect: jumlah})       
        }
       this.eventClick()
        if (typeUrl2.includes(url) || status === 'click') {
            let audioSet = document.getElementById(`audio-${jumlah ? jumlah : id}`)
            audioSet.play()
        }
    }

    popup(huruf,harakat,color,name,name2,id){
        const { otpType } = this.state
        this.setState({
            huruf: {
                huruf,harakat,color,
                name:this.filterUrl(name.find(e=>e[`name${otpType}`])[`name${otpType}`],name2)},
            popupType : 'hijaiyah',
            popup : true
        })
        setTimeout(() => {
            this.setState({popup: false,popupType:'hijaiyah'})
        }, 2000,this.change('click',id));
    }

    popupInfo(type,check){
        this.eventClick()
        this.setState({
            popup: !this.state.popup,
            popupType: type,
            openquiz: check
        })
    }
    
    backMenu(){
        // this.setState({loading:true})
        setTimeout(() => {window.location.href="/hijaiyah"}, 1000);
    }

    async optAction(optName,color){
       const {audioSelect} = this.state
       await this.setState({otpType:optName,color: color})
       this.change('otp',audioSelect)
    }

    componentDidMount(){
        if (window.location.pathname.split('/')[1] === "Bermain") {
            setTimeout(() => {
                this.setState({popup: true,popupType:'info'})
            }, 2000);
        }
    }

    setScore(nilai){
        this.setState({
            score: nilai 
        })
    }
    
    render(){
        const {ContentPages,popScore,loading,popup} = this.state
        return(
                <div className="pages-container" style={{ backgroundImage:`url(${BGbelajar})`}}>
                    <audio ref="clickaudio" src={audioClick} />
                    {loading && <Loading /> }

                     {popScore && 
                        <ScoreModal />
                    }   

                    {/* Popup hijaiyah */}
                    {popup && 
                        <Modal 
                            state={this.state} 
                            images={images}
                            func={{
                                filterUrl:this.filterUrl,
                                popupInfo:this.popupInfo,
                                backMenu:this.backMenu,
                                eventClick:this.eventClick
                            }}
                        />
                    }

                    {/* Content Page */}
                    {ContentPages.map((data,indx)=>{
                        const {component: Component} = data
                        return(
                            window.location.pathname.split('/')[1] === data.name &&
                            <Component
                                key={indx}
                                state={this.state}
                                func={{
                                    change:this.change,
                                    popup:this.popup,
                                    backMenu:this.backMenu,
                                    fliterCSS:this.fliterCSS,
                                    filterUrl:this.filterUrl,
                                    popupInfo:this.popupInfo,
                                    findImage:this.findImage,
                                    optAction:this.optAction,
                                    setScore:this.setScore

                                }}
                            />
                        )
                    })}
                </div>
        )
    }
}

export default withRouter(pages);
// export default withReducer('hijaiyah', reducer)(pages);
