import _bootstrap from "./_bootstrap4-breakpoints"

function breakCreator(value, breakpoint = {}, opts = _bootstrap) {
    if (!opts) return ""
    const breakpoints = Object.keys(opts)
    if (!breakpoints.length) return ""
    let activeBreak
    for (let index = 0; index < breakpoints.length; index++) {
        const point = opts[breakpoints[index]];
        if (value >= point) {
            activeBreak = breakpoints[index]
        }
    }
    if (!activeBreak) return ""
    return breakpoint[activeBreak]
}

export default breakCreator