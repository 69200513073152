import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from "react-router-dom"
import './assets/styles/base.scss'
import App from './views/App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { PersistGate } from "redux-persist/integration/react"
import store, {persistor} from "./modules";

function Main() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <App/>
                </Router>
            </PersistGate>
        </Provider>
    )
}

ReactDOM.render(<Main />, document.getElementById('root'))

serviceWorker.unregister()
