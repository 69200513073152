import React from "react";
import { withRouter } from "react-router";
// import Pages from './pages'
import Mainpage from './main'
import Page from './page'

class quran extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            location: window.location.pathname,       
        }
    }
    
    render(){
        const { location } = this.state
        return(
            location === "/quran" ?  <Mainpage /> : <Page />
        )
    }
}

export default withRouter(quran);
