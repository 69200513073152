import React from "react";
class body extends React.Component{
    render(){
        const {state,arrow,board,func} = this.props
        return(
                    state.typeUrl2.includes(state.url)  ?
                        <div className="body-page">
                                <div className="arrow arrow-left" onClick={_=>func.change("prev")}> 
                                    <img src={`${arrow.left2x}`}/> 
                                </div>
                            <div className="board"> 
                                <img src={board.board} /> 
                                    {state.hurufHijaiyah.map(e=>{
                                        return (
                                        <div key={e.id} style={{display: func.fliterCSS(state.audioSelect,e.id,'','none'), height:0}}>
                                            <div className="huruf-hijaiyah" style={{color: e.color}}>
                                                <div onClick={_=>func.change("click",e.id)} >
                                                    {e.huruf}
                                                </div> 
                                            <span style={{color:state.color,display:func.filterUrl('','none')}}>
                                                {e.huruf}{e[state.otpType]}
                                            </span>
                                            </div>
                                            <p className="nama-hijaiyah" style={{display:func.fliterCSS(state.audioSelect,e.id,'','none'), top:`${func.filterUrl('-95px','-42px')}` }}>
                                                {func.filterUrl(e.name.find(e=>e[`name${state.otpType}`])[`name${state.otpType}`],e.hijaiyahName)}
                                            </p>
                                            <audio id={`audio-${e.id}`} src={func.filterUrl(e.audio.find(e=>e[`audio${state.otpType}`])[`audio${state.otpType}`],e.hijaiyah)}></audio>
                                        </div>
                                    )})}
                             </div>
                                <div className="arrow arrow-right" onClick={_=>func.change("next")}> 
                                    <img src={`${arrow.right2x}`} />  
                                </div>
                            </div> 
                        :
                        <div className="body-page2">
                                <div>
                                    {state.hurufHijaiyah.map(e=>{
                                        return(
                                            <div key={e.id}>
                                                <img src={board.board2} />
                                                <div style={{color:e.color}} onClick={_=>{func.popup(e.huruf,e[state.otpType],e.color,e.name,e.hijaiyahName,e.id)}}>
                                                   <div>{e.huruf}</div>
                                                </div>
                                            <audio id={`audio-${e.id}`} src={func.filterUrl(e.audio.find(e=>e[`audio${state.otpType}`])[`audio${state.otpType}`],e.hijaiyah)}></audio>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
        )
    }
}

export default body;
