import * as types from "./types"
import {createReducer} from "@kempeb";
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"

const persistConfig = {
    key: "islamicMind:Auth",
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ["user"]
}

const initialState = {
    isAuthenticated: false,
    token: null,
    user: null
}

const reducer = createReducer(initialState, {
    [types.LOGIN](state, payload) {
        return {
            ...state,
            isAuthenticated: true,
            token: payload.data
        }
    },
    [types.LOGOUT](state, payload) {
        return initialState
    },
    [types.FETCH_USER](state, payload) {
        return {
            ...state,
            user: payload.data
        }
    }
})

export const Auth = persistReducer(persistConfig, reducer)