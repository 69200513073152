import React from "react";
import { withRouter } from "react-router";
import Pages from './pages'
import Mainpage from './main'

class hijaiyah extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            location: window.location.pathname
        }
    }

    componentDidMount(){
        let bodyTag = document.getElementsByTagName('body')
                bodyTag[0].className = "background-hijaiyah"
    }
    
    render(){
        const { location } = this.state
        return(
            location === "/hijaiyah" ?  <Mainpage /> : <Pages />
        )
    }
}

export default withRouter(hijaiyah);
