import React from "react";
import { withRouter } from "react-router";
import _navs from "views/components/Header/navs";
import numberAya from 'assets/images/quran/Group 5993@3x.png'
class ayahTab extends React.Component{
            render(){
              const { state, translations,func } = this.props
              let Translation = translations[parseFloat(state.nomorAyah)] ? translations[parseFloat(state.nomorAyah)] : ''
              return(
                       <div className="quranReadContent gray">
                        {state.data.map((item,indx)=>{
                           let wordSelected = indx === state.selectWord
                            return(
                              <div id={`au-${indx}`} className={`ayahPenuh ${wordSelected && 'background-select'}`} key={indx} onClick={_=>func.changeSelect(indx)}>
                              <div className="ayaNumber">
                                <img src={numberAya} />
                                <div>{indx + 1}</div>
                              </div>
                              {item.map((e,indxs)=>{
                                let { ar } = e
                                return ar + ' '
                                })}
                                <div className="translate">
                                {Translation[indx]["id_indonesian"]}
                                </div>
                                </div>
                            )
                        })}
                      </div>
        )
    }
}

export default withRouter(ayahTab);
