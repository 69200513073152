import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "./routes";
import { RouteAuthenticated, RouteAnonymous } from "../libraries";
import { fetchLanguages, setLoading } from "modules/application/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

function App({ Application, setLoading, fetchLanguages }) {
  const { isLoading, isError } = Application;
  React.useEffect(() => {
    if (isLoading && !isError) {
      fetchLanguages().then(result => {
        if (!result.status) {
          return;
        }
        setLoading(false);
      });
    }
  }, [isLoading, isError]);

  /**
   * Loading fetch languages API
   * */
  if (isLoading) {
    return <div>1</div>;
  }

  if (isError) {
    return <div>error caught</div>
  }

  return (
    <Switch>
      {routes.map((route, index) => {
        const Component = route.component;
        const RouteComponent = route.isAuthenticated
          ? RouteAuthenticated
          : RouteAnonymous;
        return (
          <RouteComponent
            key={index}
            exact={route.exact}
            path={route.path}
            render={props => <Component {...props} />}
          />
        );
      })}
      <Route exact name="404" render={props => <Redirect to="/home" />} />
    </Switch>
  );
}

const mapStateToProps = state => ({
  Application: state.Application
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setLoading, fetchLanguages }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(App));
