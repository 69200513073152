import React, {Fragment} from "react";
import { withRouter } from "react-router";
import Alert from '../modal/modal_back'
import Back2x from 'assets/images/icon-back@2x.png'
import board from 'assets/images/hijaiyah/hijaiyah-iqra-opt-paper-slide@3x.png'
import board2 from 'assets/images/hijaiyah/hijaiyah-play-opt-paper@-27@3x.png'
import info from 'assets/images/hijaiyah/hijaiyah-opt-btn-help@3x.png'
import timerIcon from 'assets/images/hijaiyah/bermain/nav/hijaiyah-play-timerorange@3x@3x.png'
import scoreIcon from 'assets/images/hijaiyah/bermain/nav/hijaiyah-play-trueicon@3x@3x.png'
import audioFalse from 'assets/audio/wrong_result.mp3'
import audioTrue from 'assets/audio/right_result.mp3'
import tada from 'assets/audio/tada.mp3'
import huruf from './content/huruf'
import TanWin from './content/tanda&tanwin'
class pages extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            id: null,
            score: 0,
            minutes: 0,
            pause: true,
            seconds: 60,
            corect: false,
            display: false,
            hijaiyahRandom:[],
            hijaiyahSelect:{},
            popup: false,
            content:[
                {
                    id:1,
                    type : ['Huruf'],
                    component : huruf 
                },
                {
                    id: 2,
                    type : ['Tanda','Tanwin'],
                    component: TanWin
                }
            ],
            color:'',
            url1:["Tanda-1",'Tanwin-1'],
            url2:["Huruf-2","Tanda-2",'Tanwin-2'],
            tanda:["fathah","dhammah","kasrah"],
            tanwin:["fathahtain",'dhammahtain',"kasrahtain"],
            selecTanda:'',
            activeButton: true,
            sameHijaiyah:{}

        }
        this.pause = this.pause.bind(this)
        this.checkHijaiyah = this.checkHijaiyah.bind(this)
        this.backAlert = this.backAlert.bind(this)
        this.timer = this.timer.bind(this)
        this.same = this.same.bind(this)
    }

    pause(){
        clearInterval(this.myInterval)
        this.setState({pause: false})
    }

    random(){
        const {state} = this.props
        const {url1,url2,tanda,tanwin} = this.state
        let number = url2.includes(state.url) ? 3 : url1.includes(state.url) ? 1 : 5
        let hasil = (state.hurufHijaiyah.sort(()=>Math.random() - 0.5)).filter((e,indx) => indx < number)
        let select = hasil[Math.floor(Math.random() * hasil.length)]
        let tandaRandom = (tanda.sort(()=>Math.random() - 0.5)).filter((e,indx) => indx < 1) 
        let tanwinRandom = (tanwin.sort(()=>Math.random() - 0.5)).filter((e,indx) => indx < 1) 
        let selecTanda = state.url.split('-')[0] === "Tanda" ? tandaRandom[tandaRandom.length - 1] : tanwinRandom[tanwinRandom.length - 1]
        this.setState({
            hijaiyahRandom: hasil,
            hijaiyahSelect: select,
            display: false,
            selecTanda 
        })
    }

    componentDidUpdate(prev){
        if (prev.state.openquiz !== this.props.state.openquiz) {
            if (this.props.state.openquiz === 'quiz') {
                if (this.state.pause === true) {
                    this.random()
                }
                this.pause()
                this.timer()
            }
            
        }
    }

    timer(){
        this.myInterval = setInterval(() => {
            const { seconds, minutes , score } = this.state
            const { state } = this.props
            if (seconds > 0) {
              this.setState(({ seconds }) => ({seconds: seconds - 1}))
            }
            if (seconds === 0) {
              if (minutes === 0) {
                this.refs.clear.play()
                this.props.func.popupInfo('resultScore')
                let inScore = state.url.replace(/\-/m,"")
                if (score > localStorage[inScore] || localStorage[inScore] === undefined) {
                    localStorage.setItem(inScore,score)
                }
                clearInterval(this.myInterval)
              } else {
                this.setState(({ minutes }) => ({
                  minutes: minutes - 1,
                  seconds: 59
                }))
              }
            }
          }, 1000)
    }

    same(sameHijaiyah){
        this.setState({
            sameHijaiyah,
            activeButton: false
        })
        
    }

   async checkHijaiyah(id,siap,color){
        const { hijaiyahSelect, score, selecTanda} = this.state
        let check =siap ? siap === selecTanda : hijaiyahSelect.id === id
        this.setState({
                color,
                display: true,
                id: id,
                corect: check ? true : false,
                score: check ? score + 10 : score === 0 ? 0 : score - 5
            })
            this.props.func.setScore(score + 10)
            setTimeout(() => {
                check && this.random()
                this.setState({
                    activeButton:true,
                    sameHijaiyah:{},
                    display:false,
                    id: null,
                    corect: false
                })
            }, 1000);
          this.props.popupType === undefined &&  check ? this.refs.audioPlayer.play() : this.refs.audioPlayer2.play()
    }

    backAlert(){
        this.setState({popup : !this.state.popup})
        if (this.state.popup === true) {
            this.timer()
        }
    }

    render(){
        const {func,state} = this.props
        const {seconds,score,popup,content} = this.state
        return(
            <Fragment>
                    <audio ref="audioPlayer" src={audioTrue} />
                    <audio ref="audioPlayer2" src={audioFalse} />
                    <audio ref="clear" src={tada} />
                    {popup && <Alert func={this.backAlert} back={func.backMenu}/>}
                    {/* Header */}
                    <div className="header-page">
                        <div className="icon-back">
                            <img src={`${Back2x}`} onClick={_=>{
                                this.pause(true)
                                this.backAlert()
                                }} />
                        </div>
                        <div className="judul">
                            <img src={(func.findImage(state.url)).teka} style={{ width:"55%" }}/>
                        </div>
                        <div className="detail-score">
                            <img src={info} onClick={_=>func.popupInfo('info')} />
                        </div>
                    </div>

                    {/* timer and Score */}
                    <div className="timer">
                        <div>
                            <img src={timerIcon} />
                            <p>{seconds}</p>
                        </div>
                        <div>
                            <img src={scoreIcon} />
                            <p>{score}</p>
                        </div>
                    </div> 

                    {/* Body pelajaran 1/2 */}
                     {content.map((data,indx)=>{
                        const {component: Component} = data
                        return(
                            data.type.includes(state.url.split('-')[0]) &&
                            <Component
                                key={indx}
                                func={func}
                                url={state.url}
                                checkHijaiyah={this.checkHijaiyah}
                                same={this.same}
                                state={this.state}
                                firstState={state}
                                board={{board:board, board2:board2}} 
                            />
                        )
                    })} 

                    {/* Info */}
                    <div className="info">
                        <div>
                            <img src={info} onClick={_=>{
                                this.pause(true)
                                func.popupInfo('info')
                                }} 
                            />
                        </div>
                    </div>
                    </Fragment>

        )
    }
}

export default withRouter(pages);
// export default withReducer('hijaiyah', reducer)(pages);
