import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Container } from "reactstrap";

const Section = React.memo(
  ({
    type,
    section,
    children,
    className,
    tag,
    tagClassName,
    tagType,
    tagFluid
  }) => {
    const Tag = tag === "div" ? "div" : Container;
    const sectionClass = `section${!type ? "" : `-${type}`}`;
    return (
      <div
        className={classnames(className, {
          [sectionClass]: section
        })}
      >
        <Tag className={classnames(tagClassName, tagType)} fluid={tagFluid}>
          {children}
        </Tag>
      </div>
    );
  }
);

Section.defaultProps = {
  type: "",
  section: true,
  className: "",
  tag: "container",
  tagClassName: "",
  tagType: "",
  tagFluid: false
};

Section.propTypes = {
  type: PropTypes.string,
  section: PropTypes.bool,
  className: PropTypes.string,
  tag: PropTypes.oneOf(["div", "container"]),
  tagClassName: PropTypes.string,
  tagFluid: PropTypes.bool,
  tagType: PropTypes.string
};

export default Section;
