import React from "react";
import breakCreator from "./breakpoint-creator";

const withWindowSize = Component => {
  class innerWindowSize extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        width: window.innerWidth,
        height: window.innerHeight
      };
      this.resize = this.resize.bind(this);
    }

    resize = async event => {
      await this.setState({
        width: event.target.innerWidth,
        height: event.target.innerHeight
      });
    };

    componentDidMount() {
      window.addEventListener("resize", this.resize, false);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.resize, false);
    }

    render() {
      return (
        <Component
          width={this.state.width}
          height={this.state.height}
          {...this.props}
        />
      );
    }
  }
  innerWindowSize.displayName = `withWindowSize(${Component.displayName ||
    Component.name ||
    "Component"})`;
  return innerWindowSize;
};

const useWindowSize = (options = {}) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  function resize(event) {
    setHeight(event.target.innerHeight);
    setWidth(event.target.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener("resize", resize, false);
    return () => false;
  }, []);

  React.useEffect(() => {
    return function cleanup() {
      window.removeEventListener("resize", resize, false);
    };
  }, []);

  return { size: { width, height }, breakCreator };
};

export default withWindowSize;
export { useWindowSize }
